(function() {
  'use strict';

  function Cards(Sidebar, Features) {
    var service = {};

    /**
     * Register the cards for autosave.
     * @return {[type]} [description]
     */
    service.register = function() {
      var cards = [
        // {
        //   id: 'event-drafts',
        //   title: 'Your drafts',
        //   directive: 'drafts-list-card',
        //   options: {
        //     routes: [
        //       'epf.events.new'
        //     ]
        //   },
        //   diroptions: {
        //     type: 'eventSection',
        //     matchUserProp: 'eventOwner'
        //   }
        // },
        // {
        //   id: 'event-of-type-drafts',
        //   title: 'Your drafts',
        //   directive: 'event-edit-drafts-list-card',
        //   options: {
        //     routes: [
        //       'epf.events.section-new'
        //     ]
        //   },
        //   diroptions: {}
        // }
      ];

      Features.isOn('autosave')
        .then(function(isActive) {
          if (isActive) {
            Sidebar.registerCards(cards);
          }
        });
    };

    return service;
  }

  Cards.$inject = ['SidebarService', 'FeaturesService'];

  angular.module('component.drafts')
    .service('DraftsCards', Cards);
})();
