(function() {
  'use strict';

  function PreferredModeDirective($log, Auth) {
    return {
      restrict: 'A',
      template: 'Preferred mode: {{ preferredMode }}',
      link: function($scope) {
        Auth.processSettings();
        $scope.$watch(function() {
          return Auth.preferredMode;
        }, function(status) {
          $log.debug('PreferredMode: ' + status);
          $scope.preferredMode = status;
        });
      }
    };
  }

  function LogoDirective(Auth, Organisations, CUSTOM_THEMES, DEFAULT_THEME) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        type: '@',
        organisation: '@'
      },
      templateUrl: 'app/blocks/auth/logo.html',
      link: function(scope) {
        /* TODO: Remove after we make sure all orgs have this setting */
        var getDefaultLogoPath = function getDefaultLogoPath(currOrg, device) {
          var org = CUSTOM_THEMES.indexOf(currOrg) > -1 ? currOrg : DEFAULT_THEME;
          var basePath = 'assets/images/org_logo/';
          var suffix = '-logo.png';
          return device && device === 'mobile'
            ? basePath + org + '-mobile' + suffix
            : basePath + org + suffix;
        };

        var logins = Organisations.findAllPublic();

        var setLogoPath = function(currOrg, device) {
          logins.then(function(availableLogins) {
            var logoPath;
            var org = _.find(availableLogins.logins, { id: currOrg });
            if (org) {
              logoPath = device && device === 'mobile'
                ? (org.themeSetup || {}).logoMobilePath
                : (org.themeSetup || {}).logoDesktopPath;

              /* TODO: Remove after we make sure all orgs have this setting */
              if (typeof logoPath === 'undefined' || logoPath.length < 1) {
                logoPath = getDefaultLogoPath(currOrg, device);
              }
            }

            scope.imgSrc = logoPath;
          });
        };

        scope.$on('OrganisationSettingsChanged', function() {
          logins = Organisations.findAllPublic();
          setLogoPath(Auth.currentOrganisation());
        });

        scope.$watch(function() {
          return scope.organisation || Auth.currentOrganisation();
        }, function(currOrg) {
          setLogoPath(currOrg, scope.type);
        });
      }
    };
  }

  LogoDirective.$inject = ['AuthService', 'OrganisationsService', 'CUSTOM_THEMES', 'DEFAULT_THEME'];

  PreferredModeDirective.$inject = ['$log', 'AuthService'];

  angular.module('blocks.auth')
    .directive('kzPreferredMode', PreferredModeDirective)
    .directive('kzLogo', LogoDirective);
})();
