(function() {
  'use strict';

  function RestEasApi(EasApi) {
    var service = function(endpoint) {
      this.rest_endpoint = endpoint;
    };

    service.prototype.listUrl = function() {
      return this.rest_endpoint + '/';
    };

    service.prototype.specificUrl = function(oid) {
      return this.rest_endpoint + '/' + oid;
    };

    service.prototype.findAll = function(data, urlOptions, options) {
      var url = this.listUrl();
      return EasApi.get(url, data, urlOptions, options);
    };

    service.prototype.save = function(data) {
      var url = this.specificUrl(data._id);
      return EasApi.put(url, data);
    };

    service.prototype.create = function(data) {
      var url = this.listUrl();
      return EasApi.post(url, data);
    };

    service.prototype.find = function(oid, urlParams, options) {
      var url = this.specificUrl(oid);
      return EasApi.get(url, undefined, urlParams, options);
    };

    service.prototype.remove = function(oid) {
      var url = this.specificUrl(oid);
      return EasApi.delete(url);
    };

    service.prototype.searchIds = function(data, options) {
      var url = this.listUrl() + 'search';
      return EasApi.post(url, data, undefined, options)
        .then(function(data) {
          // Lets make it compatible
          return {
            total: data.total,
            size: data.size,
            start: data.start,
            hits: _.map(data.ids, function(itm) {
              return { id: itm };
            })
          };
        });
    };

    service.prototype.fetchIds = function(ids, options) {
      var url = this.listUrl() + 'fetch';
      return EasApi.post(url, { ids: ids }, undefined, options)
        .then(function(data) {
          return _.map(data.docs, function(itm) {
            return {
              doc: itm
            };
          });
        });
    };


    return service;
  }

  RestEasApi.$inject = ['EasApiService'];

  angular.module('blocks.easapi')
    .factory('RestEasApi', RestEasApi);
})();
