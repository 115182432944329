(function() {
  'use strict';

  function kzAdvancedSearch($q, $log, $timeout, Lunr, LocalizationService) {
    return {
      restrict: 'AE',
      scope: {
        model: '=',
        search: '=',
        facets: '=?'
      },
      templateUrl: 'app/blocks/widgets/search/kz-advanced-search.html',
      link: function(scope) {
        scope.fields = [];

        var buildForm = function(facets) {
          if (!facets) {
            return;
          }

          facets.forEach(function(facet) {
            var to = {
              label: facet.label,
              required: false,
              placeholder: facet.placeholder
            };

            var field = {
              id: facet.id,
              key: facet.id,
              templateOptions: to
            };

            switch (facet.type) {
              case 'text':
                _.assign(field,
                  {
                    type: 'string'
                  });
                break;
              case 'select':
                _.assign(field,
                  {
                    type: facet.multiselect ? 'discrete_multiple' : 'discrete',
                    controller: ['$scope', function($scope) {
                      function setupTypeahead() {
                        $log.debug('Setting up typeahead');
                        $scope.reload = function(search) {
                          $log.debug('Searching for', search);
                          if (search.length < 2) {
                            $scope.to.options = [];
                            return;
                          }

                          $scope.to.loading = true;
                          // $scope.to.options = [{id: 1, title: 'Loading...'}];
                          $timeout(function() {
                            var term = search.toLowerCase();
                            var idx = $scope.to.idx;

                            // Setup index if required
                            if (facet.lunrIndex !== undefined && idx === undefined) {
                              $log.debug('Getting index for', search);
                              Lunr.getIndex(facet.lunrIndex)
                                .then(function(index) {
                                  if (index !== undefined) {
                                    return index;
                                  }

                                  return Lunr.createIndex(facet.lunrIndex, function() {
                                    this.field('title');
                                    this.ref('id');
                                  })
                                  .then(function(index) {
                                    index.index($scope.to.alloptions, {
                                      ident: function(item) {
                                        return item.id;
                                      }
                                    });
                                    return index;
                                  });
                                })

                                .then(function(index) {
                                  $scope.to.idx = index;
                                });
                            }

                            if (idx !== undefined && idx.state === 'ready') {
                              $log.debug('Lunr search');
                              var terms = idx.search(term).slice(0, 10);
                              $log.debug('Setting options for', search);
                              $scope.to.options = terms.map(function(item) {
                                return _.find($scope.to.alloptions, { id: item.ref });
                              });
                              $log.debug('Done for', search);
                            } else {
                              $log.debug('Naive search');
                              $scope.to.options = $scope.to.alloptions.filter(function(item) {
                                if (item.title === undefined) {
                                  return false;
                                }

                                return item.title.toLowerCase().indexOf(term) !== -1;
                              }).slice(0, 20);
                            }

                            // $scope.to.options = [{id: 1, title: 'Done'}];
                            $scope.to.loading = false;
                          });
                        };
                      }

                      $scope.$watch(function() {
                        return $scope.to.typeahead;
                      }, function(value) {
                        if (!value) {
                          $scope.reload = undefined;
                        } else {
                          setupTypeahead();
                        }
                      });
                    }]
                  }
                );

                _.assign(to,
                  {
                    options: [],
                    itemLabel: 'title',
                    itemValue: 'id',
                    loading: true
                  }
                );

                var options;
                if (_.isFunction(facet.options)) {
                  console.log('Getting options');
                  options = facet.options();
                } else {
                  options = facet.options;
                }

                $q.when(options, function(data) {
                  to.alloptions = data;
                  if (data.length > 20) {
                    to.typeahead = true;
                    to.placeholder = 'Start typing to select...';
                  } else {
                    to.options = to.alloptions;
                  }

                  to.loading = false;
                });
                break;
              case 'date':
                _.assign(field,
                  {
                    type: 'date'
                  }
                );

                _.assign(to,
                  {
                    options: {
                      showDropdowns: true,
                      singleDatePicker: true,
                      autoApply: true,
                      locale: {
                        format: LocalizationService.getDateTimeFormat('dateonly'),
                        firstDay: 1
                      }
                    }
                  }
                );
                break;

              default:
                console.log('Unknown field type');
            }

            if (!_.isEmpty(field)) {
              scope.fields.push(field);
            }
          });
        };

        scope.$watch(function() {
          return scope.search ? scope.search.ready : false;
        }, function(value) {
          if (value !== false) {
            var facets;
            if (scope.facets) {
              facets = scope.facets;
            } else {
              facets = _.filter(scope.search.facetly.facets, function(item) {
                return item.advanced;
              });
            }
            buildForm(facets);
          }
        });
      }
    };
  }

  kzAdvancedSearch.$inject = ['$q', '$log', '$timeout', 'KZLunr', 'LocalizationService'];

  angular.module('blocks.widgets')
    .directive('kzAdvancedSearch', kzAdvancedSearch);
})();
