(function() {
  'use strict';

  function EventSectionsService($q, $rootScope, $log, EventExtras, UserService, Auth, Api, Queue,
                                Utils, Network) {
    var service = new UserService('eventSection');

    service.es_endpoint = 'es_eventsections';

    /**
     * Get initial data for a new event section.
     */
    service.getInitial = function() {
      return angular.copy({
        _id: Utils.guid(),
        event: Utils.guid(),
        type: 'eventSection',
        section_type: 'user',
        visibility: 'private',
        organisation: Auth.currentOrganisation(),
        user: Auth.currentUser(),
        eventOwner: Auth.currentUser(),
        state: 'draft',
        meta: {
          startDate: Utils.now({ dateOnly: true }),
          endDate: Utils.now({ dateOnly: true }),
          visibility: 'public',
          linkedTargets: []
        },
        createdDate: Utils.now(),
        data: {
          fields: {}
        },
        nextSection: {},
        invitation: { invites: [] }
      });
    };

    service.findAllByEvent = function(eventIds, state) {
      return this.findAll()
        .then(function(result) {
          return _.filter(result, function(item) {
            if (!_.isArray(eventIds)) {
              eventIds = [eventIds];
            }

            if (_.indexOf(eventIds, item.doc.event) === -1) {
              return false;
            }

            if (state !== undefined) {
              return item.doc.state === state;
            }

            return true;
          });
        });
    };

    service.findAllByEventsFor = function(username, goalId, targetId) {
      return Api.get(
        'usertargetlinkedsections',
        { goal_id: goalId, target_id: targetId },
        { username: username },
        { noCache: true }
      );
    };

    service.findAllByUser = function(user, state) {
      return this.findAll()
        .then(function(result) {
          return _.filter(result, function(item) {
            if (item.doc.eventOwner !== user) {
              return false;
            }

            if (state !== undefined) {
              return item.doc.state === state;
            }

            return true;
          });
        });
    };

    service.findAllTodos = function() {
      return this.findAll()
        .then(function(result) {
          return _.filter(result, function(item) {
            if (item.doc.state !== 'draft') {
              return false;
            }

            return item.doc.originalEvent !== undefined;
          });
        });
    };

    service.getTodoInfo = function() {
      if (Network.isOffline()) {
        return this.findAll()
        .then(function(result) {
          var count = _.countBy(result, function(item) {
            if (item.doc.state !== 'draft') {
              return false;
            }

            if (_.find(item.doc.dates || [], { action: 'section_publish' })) {
              return 'todo';
            }

            if (item.doc.originalEvent !== undefined) {
              return 'todo';
            } else if (item.doc.meta !== undefined) {
              return 'draft';
            }

            return 'unknown';
          });

          return count;
        });
      }

      return $q.all([
        Api.post('es_events_own_ids', { extendedState: ['todo'], size: 0 }),
        Api.post('es_events_own_ids', { extendedState: ['draft'], size: 0 })
      ]).then(function(result) {
        return {
          todo: result[0].total,
          draft: result[1].total
        };
      });
    };

    service.getTodoFor = function(event) {
      return Api.post('es_events_own_ids', { event: event, extendedState: ['todo'], size: 1 });
    };

    service.doAction = function(action, actionData, relatedId, options) {
      options = options || {};
      if (Network.isOffline() || options.queue) {
        return Queue.postRequest(action, actionData, relatedId);
      }

      // For all event section actions we want to try and use async
      actionData.async_target_linking = true;
      return Queue.submitRequest(action, actionData, relatedId);
    };

    /* Remote actions */
    service.findFor = function(id, username) {
      return Api.get('eventsections', { _id: id }, {}, { noCache: true })
        .then(function(doc) {
          if (username !== undefined && doc.user !== username) {
            $q.reject({ status: 403, message: 'Wrong user' });
          }

          return { doc: doc, isShared: true };
        });
    };

    service.findWithActions = function(id) {
      return Api.get('sections', { _id: id }, {}, { noCache: true });
    };

    /*
      helpers
    */
    service.addCommentLog = function(eventSection, extra) {
      var msg = {
        event_id: eventSection.doc.event,
        eventSection_id: eventSection.doc._id,
        extra_doc: extra
      };

      return Queue.postRequest('extra_post_add', msg, eventSection.doc.event);
    };

    service.addExtra = function(eventsection, extra, kzOpts) {
      kzOpts = kzOpts || {};
      if (!eventsection.isMineEvent()) {
        return EventExtras.saveApi(eventsection.doc.event, extra);
      }

      return EventExtras.save(extra)
        .then(function(data) {
          if (!kzOpts.noLog) {
            // Omiting - do not fail if a problem with this
            service.addCommentLog(eventsection, extra)
              .catch(function(err) {
                console.log(err);
              });
          }

          return data;
        });
    };

    service.deleteExtra = function(eventsection, extra) {
      if (!eventsection.isMineEvent()) {
        return EventExtras.removeApi(eventsection.doc.event, extra);
      }

      return EventExtras.remove(extra._id);
    };

    service.getExtra = function(eventsection) {
      // Get extra only for local ones
      return EventExtras.findSectionApi(eventsection.doc._id)
                .catch(function() {
                  return [];
                });

      // return EventExtras.findForEvent(eventsection.doc.event);
    };

    service.decrypt = function(id, enc, options) {
      options = options || {};
      options._id = id;
      options.enc = enc;
      return Api.post('eventsectiondecrypt', options);
    };

    service.isNextResponsiblePersonValid = function(eventSectionId) {
      return Api.get('isnextresponsiblepersonvalid', {}, { pk: eventSectionId });
    };

    service.nextResponsiblePersonUpdate = function(eventSectionId, newInvitation, nextIdx) {
      return Api.post(
        'nextresponsiblepersonupdate',
        { new_invitation: newInvitation, next_idx: nextIdx },
        { pk: eventSectionId }
      );
    };

    $rootScope.$on('KZCurrentEventChange', function(_evt, args) {
      $log.debug('Event changed to:', args.event ? args.event : 'none');
      service.currentEvent = args.event;
    });

    return service;
  }

  EventSectionsService.$inject = [
    '$q',
    '$rootScope',
    '$log',
    'EventExtrasService',
    'UserService',
    'AuthService',
    'ApiService',
    'QueueService',
    'UtilsService',
    'NetworkService'
  ];

  angular.module('component.events')
    .factory('EventSectionsService', EventSectionsService);
})();
