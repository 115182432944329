(function() {
  'use strict';

  function DraftsService(UserService, Utils) {
    var docType = 'autosave';
    var service = new UserService(docType);

    /**
     * Set some sensible defaults for the autosave document type.
     * @type {Object}
     */
    var defaultDoc = {
      type: docType,
      lastSaved: null,
      isModified: false
    };

    /**
     * Initialise the autosave document with the sensible defaults,
     * and overwrite them with what the doc contains.
     * @param  {Object} doc The autosave document as it comes from the directive.
     * @return {Object}     The new autosave document, enhanced with the defaults.
     */
    service.init = function(doc) {
      return _.assign(
        {},
        defaultDoc,
        {
          user: doc.user,
          owner: doc.user,
          organisation: doc.organisation,
          isFor: doc.type,
          docId: doc._id
        }
      );
    };

    /**
     * Find an autosave for a document with a specific ID.
     * @param  {String} id The ID of the document to look for its autosave.
     * @return {Promise}   A Promise Object containing the Autosave Object.
     */
    service.findForId = function(id) {
      return this.findAll()
        .then(function(data) {
          return _.find(data, function(autosave) {
            return autosave.doc.docId === id;
          });
        })
        .catch(function(_err) {
          // We still want to return null even when there is no autosave for this ID
          return null;
        });
    };

    /**
     * Find all autosaves for a document with a specific ID.
     * @param  {String} id The ID of the document to look for its autosave.
     * @return {Promise}   A Promise Object containing list of Autosave Objects.
     */
    service.findAllForId = function(id) {
      var matchFunc = function(doc) {
        return doc.docId === id;
      };

      return this.findAllBy(matchFunc)
        .catch(function(_err) {
          // We still want to return null even when there is no autosave for this ID
          return [];
        });
    };

    /**
     * Find all the autosaves for a specific document type.
     * @param  {Function} matchFunc A filter function which will return the correct autosaves.
     * @return {Promise}          A Promise Object, containing an Array of Autosave Objects.
     */
    service.findAllBy = function(matchFunc) {
      return this.findAll()
        .then(function(data) {
          return _.reduce(data, function(drafts, draft) {
            return drafts.concat(matchFunc(draft.doc) ? [draft.doc] : []);
          }, []);
        })
        .catch(function(_err) {
          // We still want to return null even when there is no autosave for this type
          return null;
        });
    };

    /**
     * Fetch the lastSaved property on the Autosave for a document.
     * @param  {String} id The CouchDB document ID to retrieve the Autosave for.
     * @return {Promise}   A Promise Object containing the lastSaved prop if the Autosave exists.
     */
    service.getLastSavedForId = function(id) {
      return this.findAll()
        .then(function(data) {
          var autoSave = _.find(data, { docId: id });
          return autoSave ? autoSave.lastSaved : null;
        })
        .catch(function(_err) {
          // We still want to return null even when there is no autosave for this ID
          return null;
        });
    };

    /**
     * Figure out if the Autosave should be saved or not.
     * @param  {Object} newDoc The new Autosave.
     * @param  {Object} oldDoc The old Autosave.
     * @return {Boolean}      True if the objects are different, false otherwise.
     */
    service.shouldSave = function(newDoc, oldDoc) {
      // If this is a new autosave, we don't save it
      // until there is something to compare against
      if (!newDoc || !oldDoc) {
        return false;
      }

      return !Utils.isEqual(newDoc, oldDoc);
    };

    return service;
  }

  DraftsService.$inject = ['UserService', 'UtilsService'];

  angular.module('component.drafts')
    .factory('DraftsService', DraftsService);
})();
