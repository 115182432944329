(function() {
  'use strict';

  function EventSectionNextController($scope, $timeout, Form, Utils, EventSectionEdit) {
    var ctrl = this;

    function setup() {
      $scope.show = false;
      ctrl.form = new Form();
      // $scope.eventSection.doc.invitation = $scope.eventSection.doc.invitation || {};
      $scope.model = $scope.invitationModel;
      $scope.def = $scope.defSection;
      $scope.filledOnSameDevice = EventSectionEdit.getFillOnSameDevice($scope.def);
      $scope.showFOSDToggle = $scope.allowFillOnSameDevice &&
                              $scope.filledOnSameDevice.enabled &&
                              $scope.filledOnSameDevice.changable;
      $scope.invitationModel.filledOnSameDevice =
        $scope.allowFillOnSameDevice &&
        $scope.filledOnSameDevice.enabled &&
        $scope.filledOnSameDevice.defaultVisibility === 'expanded';
      $scope.source = $scope.eventSection.getResponsibles.bind($scope.eventSection);

      if (!$scope.def) {
        return;
      }

      ctrl.returning = ($scope.def.filledBy.length === 1 &&
        $scope.def.filledBy[0] === 'system:timeline-owner');

      // $timeout(function() {
      $scope.show = true;
      // });
    }

    ctrl.$onInit = function() {
      setup();
    };
    // setup();
    $scope.$on('KZNextSectionChanged', function(_evt, args) {
      if (args.idx !== $scope.nextIdx) {
        return;
      }
      $timeout(function() {
        $scope.eventSection.doc.nextSection = {};
        setup();
      });
    });

    ctrl.options = {
      formData: {
        label: 'Who would you like to fill in the next section of this form?'
      }
    };

    $scope.$on('kzFilledOnChanged', function(_evt, args) {
      var value = args.value;
      if (value) {
        // $scope.event.setupSection($scope.event.nextSection);
        return;
      }

      Utils.swal(
        {
          title: 'Turning this off will clear all the data in the next section. Continue?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes'
        },
        function(isConfirm) {
          if (isConfirm) {
            $scope.$emit('KZNextSectionCleared');
            // $scope.eventSection.doc.nextSection = {};
          } else {
            $scope.invitationModel.filledOnSameDevice = !value;
          }
        });
    });
  }

  EventSectionNextController.$inject = [
    '$scope', '$timeout', 'FormsService', 'UtilsService',
    'EventSectionEditService'
  ];

  function EventSectionNextDirective() {
    return {
      scope: {
        eventSection: '=',
        defSection: '=',
        invitationModel: '=',
        allowFillOnSameDevice: '=?',
        form: '=?',
        nextIdx: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-next.html',
      replace: true,
      controller: EventSectionNextController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventSectionNext', EventSectionNextDirective)
    .controller('EventSectionNextController', EventSectionNextController);
})();
