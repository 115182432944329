(function() {
  'use strict';

  var STATE_MAPPING = {
    success: 'progress-border-complete',
    progress: 'progress-border-pending',
    failure: 'progress-border-danger'
  };

  function TasksController(Profile, Tasks, TaskSearch, List, Utils) {
    var ctrl = this;

    Utils.setPageTitle('Tasks');

    ctrl.options = {
      trackBy: 'doc._id',
      borderClass: function(item) {
        try {
          if (item.doc.meta.errors.length > 0) {
            return STATE_MAPPING.failure;
          }
          return STATE_MAPPING[item.doc.state.toLowerCase()];
        } catch (err) {
          return STATE_MAPPING.progress;
        }
      }
    };

    ctrl.searchModel = Profile.getListPreferences('tasks').current;

    ctrl.list = new List({
      search: TaskSearch.search,
      model: ctrl.searchModel,
      idField: 'doc._id'
    }
    );

    var loadList = function() {
      return Tasks.findAll({ include_docs: true })
        .then(function(data) {
          return ctrl.list.set(data);
        })
        .then(function() {
          ctrl.list.doSearch();
        });
    };

    ctrl.options.loadList = loadList;
    loadList();
  }

  TasksController.$inject = [
    'ProfileService',
    'TasksService',
    'TaskSearch',
    'ListFactory',
    'UtilsService'
  ];

  angular.module('component.tasks')
    .controller('TasksController', TasksController);
})();
