(function() {
  'use strict';

  function AutoSaveExitDirective($q, $transitions, loadingBar, AutoSave, Utils) {
    return {
      scope: {
        info: '=',
        isDirty: '&'
      },
      restrict: 'AE',
      link: function(scope) {
        var $locationChangeStartUnbind;
        var $trhook;

        function cancel() {
          return $q.reject();
        }

        function removeObject() {
          return AutoSave.removeObject(scope.info);
        }

        function autosave() {
          return AutoSave.save(scope.info);
        }

        /* These might still be used at some points
        function removeAutoSave() {
          return AutoSave.removeAutoSave(scope.info);
        }

        function manualSave() {
          return AutoSave.manualSave(scope.info);
        }
        */

        function confirm(swopts, okfunc, cancelfunc, event) {
          if (event) {
            event.preventDefault();
          }
          loadingBar.complete();
          return Utils.confirm(
            _.assignIn({}, {
              title: 'Do you want to discard your changes?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'OK'
            }, swopts))
            .then(function() {
              return okfunc();
            })
            .catch(function(err) {
              // User discarded
              if (err && err.status === 510) {
                return cancelfunc();
              }
              Utils.showError(err);
              return $q.reject(err);
            })
            .then(function() {
              $locationChangeStartUnbind();
              window.onbeforeunload = null;
            });
        }

        function handle(event, _next, _current) {
          if (scope.info.ignoreExit) {
            return;
          }

          var promise;
          if (scope.info.isNew && _.isEmpty(scope.info.id)) {
            // Skip if we are before first save
            if (scope.info.state === 'none') {
              return;
            }

            promise = confirm({
              title: 'Do you want to discard your changes?'
            }, removeObject, cancel, event);
          } else {
            promise = autosave();
          }

          return promise;
        }

        window.onbeforeunload = function(event) {
          if (scope.info.shouldSave) {
            var msg = 'Do you want to leave this page?';
            event.returnValue = msg;
            return msg;
          }
        };

        $locationChangeStartUnbind = scope.$on(
          '$stateChangeStart',
          function(event, next, current) {
            handle(event, next, current);
          }
        );

        $trhook = $transitions.onStart({}, function(trans) {
          return handle(undefined, trans.to(), trans.from())
            .catch(function() {
              return false;
            });
        });

        scope.$on('$destroy', function() {
          window.onbeforeunload = null;
          $locationChangeStartUnbind();
          $trhook();
        });
      }
    };
  }

  AutoSaveExitDirective.$inject = [
    '$q',
    '$transitions',
    'cfpLoadingBar',
    'AutoSaveService',
    'UtilsService'
  ];

  angular.module('component.drafts')
    .directive('kzAutoSaveExit', AutoSaveExitDirective);
})();
