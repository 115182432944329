<div data-ui-view>
    <div class="row mt-6">
        <div class="col-xs-12 text-center">
          <p><img id="logo-init" class="animate-spin" src="assets/images/kaizen-logo-big.png"></p>
          <div id="inner-content">
            <h2>Kaizen is loading ...</h2>
          </div>
        </div>
      </div>
</div>
