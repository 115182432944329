(function() {
  'use strict';

  var requestResetPasswordURL = 'accounts/pw_reset_request';

  var bulkActions = [
    {
      id: 'AddUserRoles',
      title: 'Add roles',
      directive: 'kz-bulk-add-roles',
      btnClass: 'btn-success'
    },
    {
      id: 'RemoveUserRoles',
      title: 'Remove roles',
      directive: 'kz-bulk-remove-roles',
      btnClass: 'btn-warning'
    },
    {
      id: 'AddEvent',
      title: 'Add event',
      directive: 'kz-bulk-add-event',
      btnClass: 'btn-success'
    }
    // {
    //   id: 'CloseEvent',
    //   title: 'Close event',
    //   directive: 'kz-bulk-close-event',
    //   btnClass: 'btn-success'
    // }
  ];

  var states = {
    active: {
      id: 'active',
      name: 'Active',
      labelStyle: 'success',
      borderStyle: 'complete'
    },
    disabled: {
      id: 'disabled',
      name: 'Disabled',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    waiting_for_approval: {
      id: 'waiting_for_approval',
      name: 'External response',
      labelStyle: 'warning',
      borderStyle: 'pending'
    }
  };

  angular.module('component.users')
      .constant('REQUEST_RESET_PASSWORD_URL', requestResetPasswordURL)
      .constant('USER_BULK_ACTIONS', bulkActions)
      .constant('USER_STATES', states);
})();
