(function() {
  'use strict';

  function GoalsService($q, UserService, Api, Cache) {
    var service = new UserService('goal');
    service.es_endpoint = 'es_goals';

    service.cachedEvents = {};

    service.invalidateCachedEvent = function(eventId) {
      if (service.cachedEvents[eventId]) {
        delete service.cachedEvents[eventId];
      }
    };

    service.searchIds = function(data) {
      if (_.isUndefined(data.size)) {
        data.size = 50;
      }
      return Api.post('es_goals_ids', data);
    };

    service.searchOwnIds = function(data) {
      if (_.isUndefined(data.size)) {
        data.size = 50;
      }
      return Api.post('es_goals_own_ids', data);
      // return service.findAll()
      //   .then(function(data) {
      //     return {
      //       hits: _.map(data, function(item) {
      //         return { id: item.doc._id };
      //       }),
      //       total: data.length
      //     };
      //   });
      // return Api.post('es_goal_own_ids', data);
    };

    service.fetchIds = function(ids) {
      // return service.findKeys(ids);
      if (ids.length === 0) {
        return $q.when([]);
      }

      return Api.post('goals_ids', { ids: ids })
        .then(function(data) {
          return data.hits;
        });
    };

    service.findFor = function(goalId, username) {
      return Api.get('goals', { _id: goalId })
        .then(function(doc) {
          if (doc.user !== username) {
            $q.reject({ status: 403, message: 'Wrong user' });
          }

          return doc;
        });
    };

    service.findAllFor = function(username) {
      var endpoint = 'usergoals';
      return Api.get(endpoint, {}, { username: username }, { noCache: true });
    };

    service.findTargetProgressionCache = function(userId, cacheId) {
      var endpoint = 'target_progression_cache';
      return Api.get(endpoint, {}, { user_id: userId, cache_id: cacheId }, { noCache: true });
    };

    service.findUniqueEventTypes = function(params) {
      return Api.post('goals_unique_eventtypes', params);
    };

    service.findAllByGoalsIds = function(ids) {
      return this.findKeys(ids);
    };

    service.findAllByGoalsIdsFor = function(username, ids) {
      var data = { username: username, goals_ids: ids.join(',') };
      return Api.get('usergoalsbyids', {}, data, { noCache: true });
    };

    service.findAllByEventAndSection = function(eventId, sectionId) {
      return this.findAll()
        .then(function(goals) {
          return _.filter(goals, function(goal) {
            return goal.doc.eventId === eventId && goal.doc.eventSectionId === sectionId;
          });
        });
    };

    service.findAllByEventAndSectionFor = function(username, eventId, sectionId) {
      var ext = _.assignIn({
        key: 'find-all-by-event-and-seciton-for-' + username + eventId + sectionId,
        maxAge: 5000,
        cached: true
      });

      var func = service._findAllByEventAndSectionFor.bind(this, username, eventId, sectionId);
      return Cache.cachedPromise(func, ext);
    };

    service._findAllByEventAndSectionFor = function(username, eventId, sectionId) {
      var data = { username: username, event_id: eventId, section_id: sectionId };
      return Api.get('usergoalsinsection', {}, data, { noCache: true });
    };

    service.getSummary = function(eventId, sectionId) {
      var data = { event_id: eventId, section_id: sectionId };
      return Api.get('goalsetsummary', {}, data, { noCache: true });
    };

    // FIXME - this has to be changed and optimised
    // suggestion would be to do it the other way round? Each event slot would check
    // whether it is lilnked or not
    service.getLinkedEventsToOtherTargets = function(goalId, targetId, couchOptions) {
      // Isn't it better to just use the local all the time?
      return this.getLinkedEventsToOtherTargetsLocal(goalId, targetId, couchOptions);

      // var _this = this;
      // return this.store.query('targets_by_linkedEvent', couchOptions)
      //   .then(function(data) {
      //     var r = {};
      //     _.forEach(data.rows, function(target) {
      //       if (target.value.goalId !== goalId && target.value.targetId !== targetId) {
      //         var eventId = target.key[2];
      //         if (_.isUndefined(r[eventId])) {
      //           r[eventId] = [];
      //         }

      //         r[eventId].push(target.value);
      //       }
      //     });

      //     return r;
      //   })
      //   .catch(function(err) {
      //     if (err && err.status === 509) {
      //       return _this.getLinkedEventsToOtherTargetsLocal(goalId, targetId, couchOptions);
      //     }

      //     return $q.reject(err);
      //   });
    };

    /** Get events linked to other targets than the one passed by params
     *
     * This completely ignores keys passed in couchOptions as I have not find
     * any place it is needed.
     *
     * Either way we need to iterate all goals and filtering out events agains
     * a list having potentially thousands of values is deadly uneffective
     *
     */
    service.getLinkedEventsToOtherTargetsLocal = function(goalId, targetId) {
      return this.getLinkedEvents()
        .then(function(events) {
          var res = {};
          _.forOwn(events, function(targets, event) {
            res[event] = _.reject(targets, { goalId: goalId, targetId: targetId });
          });
          return res;
        });
    };

    /**
     *  This works the way that it iterates through all the goals and targets
     *  creating a event: target view
     */
    service.getLinkedEvents = function() {
      return this.findAll()
        .then(function(goals) {
          var events = {};
          _.forEach(goals, function(goal) {
            _.forEach(goal.doc.targets || [], function(target) {
              _.forEach(target.linkedEvents || [], function(event) {
                if (_.isUndefined(events[event])) {
                  events[event] = [];
                }

                events[event].push({ goalId: goal.doc._id, targetId: target._id });
              });
            });
          });
          return events;
        });
    };

    service.getLinkedEventsToOtherTargetsFor = function(username, goalId, targetId) {
      return Api.get(
        'userothertargetslinked',
        {},
        { username: username, goal_id: goalId, target_id: targetId }
      );
    };

    service.addGoalsToGoalSet = function(definitions, eventId, sectionId, fieldId) {
      var data = {
        definitions: definitions,
        eventId: eventId,
        sectionId: sectionId,
        fieldId: fieldId
      };
      return Api.post('addgoalstogoalset', data);
    };

    service.targetLinkEvent = function(data) {
      var urlParams = {
        goal: data.goal,
        target: data.target
      };
      return Api.post('target_link_event', data.data, urlParams);
    };

    service.saveViaApi = function(data) {
      return Api.put('goals', data);
    };

    service.remove = function(goalId) {
      return Api.delete('goals', { _id: goalId });
    };

    return service;
  }

  GoalsService.$inject = ['$q', 'UserService', 'ApiService', 'CacheService'];

  angular.module('component.goals')
    .factory('GoalsService', GoalsService);
})();
