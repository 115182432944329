(function() {
  'use strict';

  function SlotController($scope, $state, Security) {
    var ctrl = this;

    // this may be also used for the details view, move it if necessary
    ctrl.actionButtons = [
      {
        label: 'Edit',
        ariaLabel: 'Edit ' + $scope.item.doc.title,
        icon: 'icon-edit',
        href: (function() {
          return $state.href('epf.reportTemplates.edit', { id: $scope.item.headVersion._id });
        })(),
        showCondition: Security.hasPermission.bind(this, 'reports.edit'),
        klass: 'text-info'
      },
      {
        label: 'Run',
        ariaLabel: 'Run ' + $scope.item.doc.title,
        icon: 'icon-reload',
        href: (function() {
          return $state.href('epf.reportTemplates.run', { id: $scope.item.doc._id });
        })(),
        showCondition: Security.hasPermission.bind(this, 'reports.view'),
        klass: 'text-info'
      }
    ];

    Security.hasPermission('reports.edit')
      .then(function() {
        ctrl.showDescription = true;
      })
      .catch(function() {
        ctrl.showDescription = false;
      });

    ctrl.secondaryActionButtons = [
    ];

    var published = _.find($scope.item.linkedVersions, { state: 'published' });
    if (published !== ctrl.headVersion) {
      ctrl.published = published;
    }
  }

  SlotController.$inject = ['$scope', '$state', 'SecurityService'];

  function SlotDirective() {
    return {
      scope: {
        item: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/reportTemplate.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('reportTypeSlot', SlotDirective)
    .controller('reportSlotController', SlotController);
})();
