(function() {
  'use strict';

  function ImportDetailsController($q, $scope, Event, Form, Importer, User, Utils, TASK_NAMES) {
    var ctrl = this;
    ctrl.TASKS_NAMES = TASK_NAMES;

    this.$onChanges = function(changes) {
      var importDoc = changes.importDoc.currentValue;
      if (importDoc) {
        if (!_.isUndefined(importDoc.dryrun)) {
          ctrl.dryrun = importDoc.dryrun;
        } else {
          // retro-compatibility
          ctrl.dryrun = importDoc.data.options.dryrun;
        }
      }
    };

    ctrl.getReadableState = function(state) {
      var _map = {
        created: 'Created',
        not_created: 'Not created',
        updated: 'Updated',
        exists: 'Exists',
        no_changes: 'Skipped',
        errors: 'Failed',
        deleted: 'Deleted'
      };
      return _map[state];
    };

    ctrl.getReadableResult = function(state) {
      if (state === 'errors') {
        return;
      }

      var _map = {
        created: 'The record has been created',
        not_created: 'The record does not exists but has not been created because you decided ' +
          'not to',
        updated: 'A record has been found already and has been updated',
        exists: 'The record is already present',
        no_changes: 'The system tried to update that record but there were no changes',
        deleted: 'The record has been deleted'
      };

      return _map[state];
    };

    function getFutureStateFilterForm() {
      var states = {
        __all__: 'all',
        created: 'Created Records',
        not_created: 'Not Created Records',
        updated: 'Updated Records',
        exists: 'Existing Records',
        // no_changes: 'Not changed Records', // decided to merge it with exists
        errors: 'Failed Records',
        deleted: 'Deleted Records'
      };
      var fields = [
        {
          id: 'filterState',
          type: 'discrete',
          label: 'Show only',
          options: _.map(states, function(name, key) {
            return { _id: key, key: key, name: name };
          }),
          defaultValue: '__all__',
          required: true
        }
      ];

      return new Form(fields);
    }

    ctrl.goToPage = function(page) {
      ctrl.onGoToPage({ page: page + 1 });
    };

    $scope.filterStateModel = {};
    ctrl.futureStateFilterForm = getFutureStateFilterForm();
    $scope.$watchCollection('filterStateModel', function(newFilterFutureState) {
      ctrl.outputFilterState = newFilterFutureState.filterState;
    });

    function previewUser(userDoc) {
      var user = new User(userDoc);

      // we need the import meta data to be able to preview files because the object does
      // not exists yet and we need to check against the import object instead
      var meta = { dryrun: ctrl.dryrun, importerId: ctrl.importDoc._id };
      return user.openPreview(meta);
    }

    function previewEvent(eventDoc) {
      var event = new Event(eventDoc, { isLocal: false });
      return event.init()
        .then(function() {
          var options;
          if (!eventDoc._rev) {
            options = { parent: { doc: ctrl.importDoc }, dryrun: ctrl.dryrun };
          }
          event.openEventPreview(options);
        });
    }

    ctrl.preview = function(lineNumber) {
      // get the result for the line on the backend
      Importer.previewItem(ctrl.importDoc._id, lineNumber)
        .then(function(result) {
          if (result.errors) {
            return $q.reject(result.errors[0]);
          }

          var mapFunctions = { user: previewUser, event: previewEvent };
          return mapFunctions[result.doc.type](result.doc, result.files);
        })
        .catch(Utils.ShowError);
    };
  }

  ImportDetailsController.$inject = [
    '$q',
    '$scope',
    'EventFactory',
    'FormsService',
    'ImporterService',
    'UserFactory',
    'UtilsService',
    'TASK_NAMES'
  ];

  angular.module('component.importer')
    .component('importDetails', {
      templateUrl: 'app/components/importer/components/importDetails.html',
      controller: ImportDetailsController,
      bindings: {
        importDoc: '<',
        onDownloadOutput: '&',
        onDelete: '&',
        onAbort: '&',
        onRetry: '&',
        onImportForReal: '&',
        outputFilterState: '=',
        outputTotal: '<',
        outputPage: '<',
        outputLimit: '<',
        outputStartKey: '<',
        onGoToPage: '&'
      }
    });
})();
