/* eslint max-len: ["error", { "ignoreRegExpLiterals": true }] */
(function() {
  'use strict';

  var providers = [{
        name: 'youtube',
        regexp: /<div>(?:(https?:\/\/|\/\/)?(?:www\.)?(?:youtu(\.?be)?(\.com\/)?)(watch|\/|embed|v)?(\?v=|\/))([^"&?\/ ]{11})<\/div>/g,
        aregexp: /<a\s+(?:[^>]*)href=\"((?:https:\/\/|http:\/\/|http:\/\/\w{0,3}.|https:\/\/\w{0,3}.)youtube+\.\w{2,3}\/watch\?v=([\w-]{11}))".*?>(?:.*?)<\/a>/g,
        amatchexp: /<a\s+(?:[^>]*)href=\"((?:https:\/\/|http:\/\/|http:\/\/\w{0,3}.|https:\/\/\w{0,3}.)youtube+\.\w{2,3}\/watch\?v=([\w-]{11}))".*?>(?:.*?)<\/a>/,
        matchexp: /<div>(?:(https?:\/\/|\/\/)?(?:www\.)?(?:youtu(\.?be)?(\.com\/)?)(watch|\/|embed|v)?(\?v=|\/))([^"&?\/ ]{11})<\/div>/,
        embed: '//www.youtube.com/embed/'
      }, {
        name: 'vimeo',
        regexp: /<div>(?:(https?:)?\/\/vimeo.com\/(?:channels\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)?)(\d{6,11})<\/div>/g,
        aregexp: /<a\s+(?:[^>]*)href=\"(http|https?:\/\/?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/[^\/]*\/videos\/|)(\d+(?:|\/\?)))".*?>(?:.*?)<\/a>/g,
        amatchexp: /<a\s+(?:[^>]*)href=\"(http|https?:\/\/?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/[^\/]*\/videos\/|)(\d+(?:|\/\?)))".*?>(?:.*?)<\/a>/,
        matchexp: /<div>(?:(https?:)?\/\/vimeo.com\/(?:channels\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)?)(\d{6,11})<\/div>/,
        embed: '//player.vimeo.com/video/'
      }],
      wrapWithIframe = function(url, provider) {
        var match = url.match(provider.matchexp),
            vid = match[match.length - 1];

        return (
          '<div class="embed-responsive embed-responsive-16by9">' +
          '<iframe class="embed-responsive-item" src="' +
            provider.embed + vid +
          '"></iframe></div>'
        );
      },
      wrapIDWithIframe = function(vid, provider) {
        return (
          '<div class="embed-responsive embed-responsive-16by9">' +
          '<iframe class="embed-responsive-item" src="' +
            provider.embed + vid +
          '"></iframe></div>'
        );
      },
      replaceUrl = function(content, provider) {
        if (content.search(provider.aregexp) !== -1) {
          content = content.replace(provider.aregexp, function(link) {
            var match = link.match(provider.amatchexp);
            return wrapIDWithIframe(match[2], provider);
          });
        }

        if (content.search(provider.regexp) !== -1) {
          content = content.replace(
            provider.regexp,
            function(url) {
              return wrapWithIframe(url, provider);
            }
          );
        }

        return content;
      };

  function filter($sce) {
    return function(content) {
      var parse = function(provider) {
        content = replaceUrl(content, provider);
      };

      if (content) {
        _.forEach(providers, parse);
      }

      return $sce.trustAsHtml(content);
    };
  }

  filter.$inject = ['$sce'];

  angular.module('blocks.utils')
    .filter('videoUrlToIframe', filter);
})();
