(function() {
  'use strict';

  function AccountFormController($q, $scope, Accounts, Auth, Form, Organisations, Users, Utils) {
    var ctrl = this;
    ctrl.formName = $scope.formName;
    ctrl.isOwner = Users.remoteUser === '';

    function init(allowedAccountTypes, canManageSSOWithoutIntegration) {
      var hasIntegration = false,
          autoGenerateUsername = false,
          autoGeneratePassword = false;

      var integrations = Accounts.getIntegrations();
      var orgId = Auth.currentOrganisation();
      if (!_.isUndefined(integrations[orgId])) {
        hasIntegration = true;
        autoGenerateUsername = integrations[orgId].autoGenerateUsername;
        autoGeneratePassword = integrations[orgId].autoGeneratePassword;
      }

      function updateDependencies() {
        // if (_.isUndefined($scope.model._id)) {
        //   if ($scope.model.type === 'local') {
        //     $scope.model._id = 'Username';
        //   } else if ($scope.model.type === 'proxy') {
        //     $scope.model._id = 'SSO ID';
        //   }
        // }

        // force to set passwordOptions for backend
        if ($scope.model.type === 'local') {
          if (ctrl.isOwner) {
            $scope.model.passwordOptions = 'customPassword';
          }
        } else {
          delete $scope.model.passwordOptions;
        }

        if ($scope.model.type === 'proxy' && !canManageSSOWithoutIntegration) {
          // force integration if not canManageSSOWithoutIntegration
          $scope.model.useIntegration = true;
        }

        // set/unset password
        if (($scope.model.type === 'local' && $scope.model.passwordOptions === 'customPassword') ||
          (hasIntegration && $scope.model.useIntegration && !autoGeneratePassword)) {
          $scope.model.password = Utils.generatePassword(3, 2, 2, 1);
        } else {
          delete $scope.model.password;
        }
      }

      function typesOptions() {
        var accountsThatCanBeCreated = [];
        if (_.indexOf(allowedAccountTypes, '__all__') > -1) {
          accountsThatCanBeCreated = ['local', 'proxy'];
        } else {
          accountsThatCanBeCreated = allowedAccountTypes;
        }

        accountsThatCanBeCreated = _.filter(accountsThatCanBeCreated, function(account) {
          if (canManageSSOWithoutIntegration || hasIntegration) {
            return true;
          }

          return account !== 'proxy';
        });

        if (accountsThatCanBeCreated.length === 1) {
          $scope.model.type = accountsThatCanBeCreated[0];
        }

        return _.map(accountsThatCanBeCreated, function(type) {
          var name = type === 'proxy' ? 'SSO' : 'Local';
          return { _id: type, key: type, name: name };
        });
      }

      ctrl.form = new Form([
        {
          id: 'type',
          type: 'discrete',
          label: 'Type of credential',
          required: true,
          onChange: updateDependencies,
          options: typesOptions()
        },
        // {
        //   id: '_id', // because name is too similar with Username
        //   type: 'string',
        //   label: 'Short description',
        //   required: false,
        //   hideExpression: function(_$viewValue, _$modelValue, $scope) {
        //     if (_.isUndefined($scope.model.type)) {
        //       return true;
        //     }
        //   }
        // },
        {
          id: 'useIntegration',
          type: 'boolean',
          label: 'Would you like to also create this account in your CRM?',
          required: true,
          disabled: !canManageSSOWithoutIntegration,
          onChange: updateDependencies,
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            if (_.isUndefined($scope.model.type)) {
              return true;
            }

            return !($scope.model.type === 'proxy' && hasIntegration);
          }
        },
        {
          id: 'username',
          type: 'username',
          label: 'Username',
          required: true,
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            if (_.isUndefined($scope.model.type)) {
              return true;
            }

            return $scope.model.type !== 'local';
          }
        },
        {
          id: 'username',
          type: 'username',
          label: 'SSO ID',
          required: true,
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            if (_.isUndefined($scope.model.type)) {
              return true;
            }

            if ($scope.model.type !== 'proxy') {
              return true;
            }

            return hasIntegration && $scope.model.useIntegration && autoGenerateUsername;
          }
        },
        {
          id: 'autoGenerateUsername',
          type: 'text_text',
          label: 'The CRM will generate a username',
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            if (_.isUndefined($scope.model.type)) {
              return true;
            }

            if ($scope.model.type === 'local') {
              return true;
            }

            return !(hasIntegration && $scope.model.useIntegration && autoGenerateUsername);
          }
        },
        {
          id: 'passwordOptions',
          type: 'discrete',
          label: 'Set a password or let the user choose one?',
          required: true,
          onChange: updateDependencies,
          helpText: 'By setting a custom password you will need to contact the user yourselves ' +
          'in order to communicate their login credentials. If you select to allow them to ' +
          'choose their own this will generate a welcome email guiding them through the process ' +
          'when the user is created.',
          options: [
            { _id: 'customPassword', key: 'customPassword', name: 'Set a custom Password' },
            { _id: 'letTheUserChooseOne', key: 'custom  Password', name: 'Let the user choose one' }
          ],
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            return $scope.model.type !== 'local' || ctrl.isOwner;
          }
        },
        {
          id: 'password',
          type: 'string',
          label: 'Password',
          helpText: 'The password should be at least 12 characters long and contain at least 1 ' +
          'uppercase letter, 1 lowercase letter, 1 number and 1 following special character: ' +
          '!?=#*$@+-.',
          required: true,
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            if (_.isUndefined($scope.model.type)) {
              return true;
            }

            if (
              $scope.model.type === 'local' &&
              ($scope.model.passwordOptions === 'customPassword' || ctrl.isOwner)
            ) {
              return false;
            }

            return !(hasIntegration && $scope.model.useIntegration && !autoGeneratePassword);
          },
          validators: {
            isStrong: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue;
                if (value === undefined) { return false; }
                if (value.length < 12) { return false; }
                if (value.match(/[0-9]/) === null) { return false; }
                if (value.match(/[a-z]/) === null) { return false; }
                if (value.match(/[A-Z]/) === null) { return false; }
                return value.match(/[!?=#*$@+-]/) !== null;
              },
              message: '"Please choose a stronger password"'
            }
          }
        },
        {
          id: 'autoGeneratePassword',
          type: 'text_text',
          label: 'The CRM will generate a password',
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            if (_.isUndefined($scope.model.type)) {
              return true;
            }

            if ($scope.model.type === 'local') {
              return true;
            }

            return !(hasIntegration && $scope.model.useIntegration && autoGeneratePassword);
          }
        }
      ]);
    }

    $q.all(
      [
        Organisations.find(Auth.currentOrganisation()),
        Users.find(Auth.currentUser())
      ]
    )
      .then(function(result) {
        var allowedAccountTypes = result[0].allowedAccountTypes;
        var canManageSSOWithoutIntegration = result[1].isOrgSuperUser || result[1].isSuperUser;
        init(allowedAccountTypes, canManageSSOWithoutIntegration);
      });
  }

  AccountFormController.$inject = [
    '$q',
    '$scope',
    'AccountsService',
    'AuthService',
    'FormsService',
    'OrganisationsService',
    'UsersService',
    'UtilsService'
  ];

  function AccountFormDirective() {
    return {
      scope: {
        model: '=',
        formName: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/credentialForm.html',
      replace: true,
      controller: AccountFormController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('credentialForm', AccountFormDirective)
    .controller('AccountFormController', AccountFormController);
})();
