(function() {
  'use strict';

  var bulkActions = [
    {
      id: 'AddUserRoles',
      title: 'Add roles'
    },
    {
      id: 'RemoveUserRoles',
      title: 'Remove roles'
    },
    {
      id: 'AddEvent',
      title: 'Add event'
    },
    {
      id: 'ImportSection',
      title: 'Import responses'
    }
  ];

  angular.module('component.tasks')
      .constant('CELERY_TASKS', bulkActions);
})();
