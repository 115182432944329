(function() {
  'use strict';

  function UserFieldsService($q, Auth, Cache, EasApiStore, Security) {
    var cacheKeys = ['userfields-featured'];
    var service = new EasApiStore('user-fields',
      { cacheKeys: cacheKeys, usePersistentCache: true });

    service.preCache = function() { return $q.all([service.findAll()]); };

    // should be probably in a Factory
    // return all user fields that should be visible to the user. this is mimicing
    // "get_visible_user_fields" from the backend
    function setVisibility(userFields, owner) {
      var isOwner = Auth.currentUser() === owner;
      return $q.all(_.map(userFields, function(userField) {
        var visibleBy = userField.doc.visibleBy;

        function setIsVisible(userField, isVisible) {
          userField.doc.isVisible = isVisible;
          return userField;
        }

        if (
          (!visibleBy || _.indexOf(visibleBy, '__all__') > -1) ||
          (isOwner && '__owner__' && _.indexOf(visibleBy, '__owner__') > -1)
        ) {
          return setIsVisible(userField, true);
        }

        // avoid doing the next extra API call for nothing if it's not the owner
        if (isOwner) {
          return setIsVisible(userField, false);
        }

        var proms = [
          Security.hasRole('system:admin', { promise: true })
            .then(function() {
              return true;
            })
            .catch(function() {
              return false;
            })
        ];

        if (owner) {
          proms.push(Security.hasRoleFor(owner, visibleBy));
        } else {
          proms.push(Security.hasRole(visibleBy));
        }

        return $q.all(proms)
          .then(function(result) {
            var isAdmin = result[0];
            var hasVisibilityRole = result[1];
            return isAdmin || (!isOwner && hasVisibilityRole);
          })
          .then(function(isVisible) {
            return setIsVisible(userField, isVisible);
          });
      }));
    }

    service.findAll = function(options) {
      options = options || {};

      var opts = {};
      if (options) {
        _.assign(opts, options);
      }

      if (opts.cached === undefined) {
        opts.cached = true;
      }

      var systemUserFields = [
        ['firstName', 'First Name'],
        ['lastName', 'Last Name'],
        ['email', 'Email'],
        ['secondaryEmails', 'Secondary Email']
      ];
      systemUserFields = _.map(systemUserFields, function(item) {
        var id = item[0],
            name = item[1];
        return { doc: { _id: id, name: name, isSystem: true } };
      });

      return EasApiStore.prototype.findAll.call(this, opts)
        .then(function(data) {
          return _.map(data, function(itm) { return { doc: itm }; });
        })
        .then(function(data) {
          if (options.includeSystemUserFields) {
            return data.concat(systemUserFields);
          }

          return data;
        })
        .then(function(userFields) {
          // set isVisible attr
          if (options.withVisibility) {
            return setVisibility(userFields, options.owner);
          }

          return userFields;
        });
    };

    service.findFeatured = function() {
      var _this = this;
      return Cache.cachedPromise(function() {
        return _this.findAll()
          .then(function(data) {
            var featuredUserFields = _.filter(data, function(userField) {
              return userField.doc.isFeatured;
            });

            return featuredUserFields;
          });
      }, {
        key: 'userfields-featured',
        maxAge: 5 * 60 * 1000,
        cached: true
      });
    };

    return service;
  }

  UserFieldsService.$inject = [
    '$q',
    'AuthService',
    'CacheService',
    'EasApiStore',
    'SecurityService'
  ];

  angular.module('component.userFields')
    .factory('UserFieldsService', UserFieldsService);
})();
