export const TasksTypesTemplates = {
  'app/components/tasks/partials/AddEvent.html': require('./partials/AddEvent.html').default,
  'app/components/tasks/partials/AddUserRoles.html': require(
    './partials/AddUserRoles.html'
  ).default,
  'app/components/tasks/partials/RemoveUserRoles.html': require(
    './partials/RemoveUserRoles.html'
  ).default,
  'app/components/tasks/partials/ImportSection.html': require(
    './partials/ImportSection.html'
  ).default
};

