(function() {
  'use strict';

  function EventSearch(
    $q,
    $filter,
    $log,
    moment,
    UsersStub,
    Events,
    EventsStore,
    EventTypes,
    EventTypesStore,
    Blueprints,
    BlueprintUtils,
    Auth,
    Users,
    Cache,
    EVENT_STATES,
    EVENT_STATES_ORDERED
  ) {
    var PLACEHOLDER = 'Start typing to search...';

    function getExistingEventTypes() {
      var prom;
      var username = Users.remoteUser || Auth.currentUser();
      prom = Events.getUniqueEventTypes(username)
        .then(function(eventTypeIds) {
          return EventTypes.getGroupsByItemIds(eventTypeIds);
        })
        .then(function(data) {
          return _.map(data, function(item) {
            return { id: item._id, key: item._id, title: item.name };
          });
        });

      return prom;
    }

    /**
     * This should return all event types available to search.
     * It should be possible to search for:
     *   1. Any that I have on my timeline
     *   2. Published event types so that I can check that I have none
     *
     * It shouldn't list archived ones (unless I have them) so that it is not covered
     * in mess I'm not interested to
     * @return {[eventType]}         [versionId, name]
     */
    function getEventTypes() {
      // All event types which already exist on user's timeline
      var existingEventTypesForUser = getExistingEventTypes();
      return existingEventTypesForUser;
    }

    function getEventTypesForAllEvents() {
      return EventTypesStore.fetch({
        purpose: 'latestPublished',
        size: 10000
      }, { transformType: 'stub' }).then(function(eventTypes) {
        return _.map(eventTypes, function(eventType) {
          return {
            id: eventType.versionGroupId,
            title: eventType.name
          };
        });
      });
    }

    function getBlueprints(blueprints) {
      var promise;
      if (_.isUndefined(blueprints)) {
        promise = Blueprints.findDiscreteOnly()
          .then(function(data) {
            return _.map(data, 'doc');
          });
      } else {
        promise = $q.when(blueprints);
      }

      return promise
        .then(function(filtered) {
          var result = [];

          var flatten = function(obj) {
            var result = [];
            result.push({
              id: obj._id,
              title: obj.name
            });

            if (obj.categories && obj.categories.length) {
              obj.categories.forEach(function(subobj) {
                result = result.concat(flatten(subobj));
              });
            }

            return result;
          };

          filtered.forEach(function(obj) {
            result = result.concat(flatten(obj));
          });

          return result;
        });
    }

    function getBlueprintFacets() {
      // return Utils.slowDown(3000)()
      return $q.when()
        .then(function() {
          return BlueprintUtils.getTaggableBlueprints(
            {
              period: 'until now',
              user: Users.remoteUser
            });
        })
        .then(function(data) {
          var filtered = _.chain(data)
            .sortBy('name')
            .map(function(obj) {
              return {
                id: obj._id,
                filterId: 'blueprint',
                type: 'select',
                label: obj.name,
                multiselect: true,
                options: getBlueprints([obj]),
                lunrIndex: 'events.blueprints-' + obj._id,
                facetly: false,
                advanced: true
              };
            })
            .value();

          return filtered;
        });
    }

    var getStateFilter = function(value) {
      return {
        draft: function(event) {
          if (event.doc.type !== 'eventSection') {
            return false;
          }

          if (event.doc.state !== 'draft') {
            return false;
          }

          if (_.find(event.doc.dates || [], { action: 'section_publish' })) {
            return false;
          }

          return event.init()
            .then(function() {
              return event.getCurrentIndex() <= 0;
            });
        },
        todo: function(event) {
          if (event.doc.type !== 'eventSection') {
            return false;
          }

          if (event.doc.state !== 'draft') {
            return false;
          }

          if (_.find(event.doc.dates || [], { action: 'section_publish' })) {
            return true;
          }

          return event.init()
            .then(function() {
              return event.getCurrentIndex() > 0;
            });
        },
        alldrafts: function(event) {
          if (event.doc.type !== 'eventSection') {
            return false;
          }

          if (event.doc.state !== 'draft') {
            return false;
          }
          return true;
        },
        open: function(event) {
          return ['draft', 'merged', 'complete', 'obsolete']
            .indexOf(event.doc.state) === -1;
        },
        complete: function(event) {
          return ['merged', 'complete'].indexOf(event.doc.state) !== -1;
        }
      }[value];
    };

    var getDBSearch = function(options) {
      // merge all defaultFilters
      var defaultFilter = { hideBySystemEventType: ['empty_uploaded_document'] };
      options = _.isObject(options) ? options : {};
      defaultFilter = _.assignIn({}, defaultFilter || {}, (options.defaultFilter || {}).filter);

      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              placeholder: 'Start typing to search'
            },
            {
              id: 'eventType_versionGroupId',
              type: 'select',
              label: 'Event type',
              multiselect: true,
              options: getEventTypes({ defaultFilter: defaultFilter }),
              lunrIndex: 'events.eventtypes',
              facetly: false,
              advanced: true
            },
            {
              id: 'state',
              type: 'select',
              label: 'State',
              multiselect: true,
              options: [
                { id: 'draft', title: 'Draft' },
                { id: 'todo', title: 'To Do' },
                { id: 'open', title: 'Open' },
                { id: 'complete', title: 'Complete' }
              ],
              facetly: false,
              advanced: true
            },
            {
              id: 'after',
              type: 'date',
              label: 'Date after',
              facetly: false,
              advanced: true
            },
            {
              id: 'before',
              type: 'date',
              label: 'Date before',
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: PLACEHOLDER,
            listMaxItems: 200
          }
        },
        filters: [
          {
            id: 'text',
            preMatchFunc: function(value) {
              var start = new Date().getTime();
              if (_.isUndefined(window.indexedDB)) {
                return;
              }

              return EventsStore.getIndex({ uptodate: true })
                .then(function(index) {
                  var result = index.search(value);
                  $log.debug('Done setting up index in', new Date().getTime() - start);
                  return [
                    _.map(result, 'ref'),
                    index.tokenize(value),
                    index
                  ];
                });
            },
            matchFunc: function(item, _key, value, prematch) {
              var event = item.main || item;
              if (prematch !== undefined) {
                var match = prematch[0].indexOf(event.doc._id) !== -1;
                if (!match) {
                  return false;
                }

                event.highlight = prematch[1];
                event.source = prematch[2]._source[event.doc._id];
                return prematch[0].indexOf(event.doc._id) !== -1;
              }

              if (!value) { return true; }

              return event.init()
                .then(function() {
                  if (event.doc.user !== Auth.currentUser()) {
                    return UsersStub.find(event.doc.user);
                  }
                })
                .then(function(user) {
                  var val = value.toLowerCase(),
                      name = event.doc.title ? event.doc.title.toLowerCase() : '',
                      desc = event.doc.description ? event.doc.description.toLowerCase() : '',
                      title = event.eventType.name ? event.eventType.name.toLowerCase() : '';

                  var fullname = user && (user.firstName || '') + ' ' + (user.lastName || '') +
                    (user.email || '');

                  if ((name + desc + title + fullname).indexOf(val) > -1) {
                    return true;
                  }

                  // I'm not sure how to handle these
                  //  - note we cannot use any form of forEach
                  /* eslint-disable */
                  for (var sectionId in (event.doc.sections || {})) {
                    var items = event.doc.sections[sectionId].items || {};
                    for (var item in items) {
                      var fields = items[item].fields || {};
                      for (var fldId in fields) {
                        if (
                          fields[fldId] &&
                          fields[fldId].toLowerCase &&
                          fields[fldId].toLowerCase().indexOf(val) > -1
                        ) {
                          return true;
                        }
                      }
                    }
                  }
                  /* eslint-enable */
                })
                .catch(function() {
                  return false;
                });
            }
          },
          {
            id: 'eventType_versionGroupId',
            preMatchFunc: function(versionGroupIds) {
              return EventTypes.getGroupsByIds(versionGroupIds)
                .then(function(allVersions) {
                  var ids = _.map(allVersions, '_id');
                  return ids;
                  // _.forEach(allVersions, function(item) {
                  //   _.forEach(item.linkedVersions || [], function(etid) {
                  //     ids.push(etid._id);
                  //   });
                  // });
                  // return ids;
                });
            },
            matchFunc: function(item, _key, _eventTypeIds, preMatch) {
              if (preMatch.length === 0) {
                return false;
              }

              var event = item.main || item;
              return preMatch.indexOf(event.doc.eventType) > -1;
            }
          },
          {
            id: 'blueprint',
            preMatchFunc: function(value) {
              return Blueprints.findChildCategories(value);
            },
            matchFunc: function(item, _key, value, preMatch) {
              var event = item.main || item;
              if (!value || value.length === 0) {
                return true;
              }

              return event.init()
                .then(function() {
                  var blueprintContainedInCategories = _.intersection(event.categories, preMatch);
                  var blueprintContainedInFields = _.filter(value, function(v) {
                    return _.has(event.blueprintsInFields, v);
                  });
                  return blueprintContainedInCategories.length || blueprintContainedInFields.length;
                })
                .catch(function() {
                  return false;
                });
            }
          },
          {
            id: 'state',
            preMatchFunc: getStateFilter,
            matchFunc: function(item, _key, _value, preMatch) {
              var event = item.main || item;
              if (!preMatch) {
                return true;
              }

              return preMatch(event);
            }
          },
          {
            id: 'eventState',
            preMatchFunc: getStateFilter,
            matchFunc: function(item, _key, _value, preMatch) {
              var event = item.main || item;
              if (!preMatch) {
                return true;
              }

              return preMatch(event);
            }
          },
          {
            id: 'after',
            matchFunc: function(item, _key, value) {
              var event = item.main || item;
              if (!value) { return true; }

              var date = event.meta.startDate;
              if (!date) { return false; }
              return date >= value;
            }
          },
          {
            id: 'before',
            matchFunc: function(item, _key, value) {
              if (!value) { return true; }

              var event = item.main || item;

              var date = event.meta.endDate;
              if (!date) { return false; }

              return date <= value;
            }
          },
          {
            id: 'hideBySystemEventType',
            preMatchFunc: function(systemEventTypeIds) {
              return EventTypes.findSystemIds(systemEventTypeIds)
                .catch(function() {
                  return undefined;
                });
            },
            matchFunc: function(item, _key, _value, prematch) {
              if (prematch === undefined) {
                return true;
              }
              var event = item.main || item;
              return prematch.indexOf(event.doc.eventType) === -1;
            }
          },
          {
            id: 'hideOwnMerged',
            matchFunc: function(item, _key, _value) {
              var event = item.main || item;
              if (event.doc.type !== 'eventSection') {
                return true;
              }

              if (event.isEventOwners() && event.doc.state === 'merged') {
                return false;
              }

              return true;
            }
          },
          {
            id: 'hideNotOwnEvent',
            matchFunc: function(item, _key, _value) {
              var event = item.main || item;
              if (event.doc.type !== 'eventSection') {
                return true;
              }

              if (!event.isEventOwners()) {
                return false;
              }

              return true;
            }
          }
        ],
        orders: {
          startDate: function(item) {
            var event = item.main || item;
            if (event.meta.startDate) {
              return -(moment(event.meta.startDate).toDate().getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          },
          endDate: function(item) {
            var event = item.main || item;
            if (event.meta.endDate) {
              return -(moment(event.meta.endDate).toDate().getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          },
          addedDate: function(item) {
            var event = item.main || item;
            var date = event.doc.createdDate || (event.doc.date && event.doc.date.added);
            if (date) {
              return -(moment(date).toDate().getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          },
          state: function(item) {
            var event = item.main || item;
            var orderedStates = EVENT_STATES_ORDERED;
            var order = orderedStates.indexOf(event.doc.state);

            // If not found, put at the end
            return order === -1 ? 1000 : order;
          }
        },
        orderGroups: {
          startDate: {
            title: 'date occurred',
            orders: ['startDate', 'addedDate'],
            groupBy: {
              title: 'Events occurred in',
              notitle: 'Events without a date',
              getter: function(item) {
                var event = item.main || item;
                return $filter('date')(event.doc.startDate, 'MMMM y');
              }
            }
          },
          addedDate: {
            title: 'date created',
            orders: ['addedDate', 'startDate'],
            groupBy: {
              title: 'Events created in',
              notitle: 'Events without a date',
              getter: function(item) {
                var event = item.main || item;
                var date = event.doc.createdDate || (event.doc.date && event.doc.date.added);
                if (date) {
                  return $filter('date')(date, 'MMMM y');
                }

                return;
              }
            }
          },
          endDate: {
            title: 'date ended',
            orders: ['endDate', 'addedDate'],
            groupBy: {
              title: 'Events ending in',
              notitle: 'Events without a date',
              getter: function(item) {
                var event = item.main || item;
                if (event.doc.date && event.doc.endDate) {
                  return $filter('date')(event.doc.endDate, 'MMMM y');
                }

                return;
              }
            }
          },
          state: {
            title: 'state',
            orders: ['state', 'addedDate'],
            groupBy: {
              title: 'Event state is',
              notitle: 'Events without a state',
              getter: function(item) {
                var event = item.main || item;
                return event.doc.state;
              }
            }
          }
        },
        defaultFilter: defaultFilter,
        defaultOrder: 'addedDate'
      };

      search.ready = false;
      getBlueprintFacets()
        .then(function(facets) {
          var arr = [search.facetly.facets.length, 0].concat(facets);
          search.facetly.facets.splice.apply(search.facetly.facets, arr);
          search.ready = true;
          return search;
        });

      return $q.when(search);
    };

    var getAllSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              placeholder: 'Start typing to search'
            },
            {
              id: 'eventType_versionGroupId',
              type: 'select',
              label: 'Event type',
              multiselect: true,
              options: getEventTypesForAllEvents(),
              facetly: false,
              advanced: true
            },
            {
              id: 'state',
              type: 'select',
              label: 'State',
              multiselect: true,
              options: [
                { id: 'open', title: 'Open' },
                { id: 'complete', title: 'Complete' }
              ],
              facetly: false,
              advanced: true
            },
            {
              id: 'after',
              type: 'date',
              label: 'Date after',
              facetly: false,
              advanced: true
            },
            {
              id: 'before',
              type: 'date',
              label: 'Date before',
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'text',
            placeholder: PLACEHOLDER,
            listMaxItems: 200
          }
        },
        filters: [
          {
            id: 'state',
            preFilter: function(value) {
              var states = {
                open: ['open'],
                complete: ['complete']
              };

              return {
                state: states[value]
              };
            }
          }
        ],
        orderGroups: {
          startDate: {
            title: 'date occurred',
            orders: ['startDate', 'addedDate'],
            groupBy: {
              title: 'Events occurred in',
              notitle: 'Events without a date',
              getter: function(item) {
                return $filter('date')(item.doc.startDate, 'MMMM y');
              }
            }
          },
          addedDate: {
            title: 'date created',
            orders: ['addedDate', 'startDate'],
            groupBy: {
              title: 'Events created in',
              notitle: 'Events without a date',
              getter: function(item) {
                var date = item.doc.createdDate || (item.doc.date && item.doc.date.added);
                if (date) {
                  return $filter('date')(date, 'MMMM y');
                }

                return;
              }
            }
          },
          endDate: {
            title: 'date ended',
            orders: ['endDate', 'addedDate'],
            groupBy: {
              title: 'Events ending in',
              notitle: 'Events without a date',
              getter: function(item) {
                if (item.doc.date && item.doc.endDate) {
                  return $filter('date')(item.doc.endDate, 'MMMM y');
                }

                return;
              }
            }
          },
          state: {
            title: 'state',
            orders: ['state', 'addedDate'],
            groupBy: {
              title: 'Event state is',
              notitle: 'Events without a state',
              getter: function(item) {
                return item.doc.state;
              }
            }
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'addedDate'
      };

      search.ready = false;
      getBlueprintFacets()
        .then(function(facets) {
          var arr = [search.facetly.facets.length, 0].concat(facets);
          search.facetly.facets.splice.apply(search.facetly.facets, arr);
          search.ready = true;
          return search;
        })
        .catch(function(error) {
          $log.warn('Could not get blueprint faces', error);
        });

      return $q.when(search);
    };

    var getUserSearch = function(options) {
      // merge all defaultFilters
      options = _.isObject(options) ? options : {};
      var defaultFilter = _.assignIn({}, (options.defaultFilter || {}).filter);
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              placeholder: 'Start typing to search'
            },
            {
              id: 'eventType_versionGroupId',
              type: 'select',
              label: 'Event type',
              facetly: false,
              multiselect: true,
              advanced: true,
              options: getEventTypes({ defaultFilter: defaultFilter })
            },
            {
              id: 'state',
              type: 'select',
              label: 'State',
              multiselect: true,
              options: [
                { id: 'open', title: 'Open' },
                { id: 'complete', title: 'Complete' }
              ],
              facetly: false,
              advanced: true
            },
            {
              id: 'after',
              type: 'date',
              label: 'Date after',
              facetly: false,
              advanced: true
            },
            {
              id: 'before',
              type: 'date',
              label: 'Date before',
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'text',
            placeholder: PLACEHOLDER,
            listMaxItems: 200
          }
        },
        filters: [
          {
            id: 'state',
            preFilter: function(value) {
              var states = {
                open: ['open'],
                complete: ['complete']
              };

              return {
                state: states[value]
              };
            }
          }
        ],
        orderGroups: {
          startDate: {
            title: 'date occurred',
            orders: ['startDate', 'addedDate'],
            groupBy: {
              title: 'Events occurred in',
              notitle: 'Events without a date',
              getter: function(item) {
                return $filter('date')(item.doc.startDate, 'MMMM y');
              }
            }
          },
          addedDate: {
            title: 'date created',
            orders: ['addedDate', 'startDate'],
            groupBy: {
              title: 'Events created in',
              notitle: 'Events without a date',
              getter: function(item) {
                if (!item.doc) {
                  return;
                }
                var date = item.doc.createdDate || (item.doc.date && item.doc.date.added);
                if (date) {
                  return $filter('date')(date, 'MMMM y');
                }

                return;
              }
            }
          },
          endDate: {
            title: 'date ended',
            orders: ['endDate', 'addedDate'],
            groupBy: {
              title: 'Events ending in',
              notitle: 'Events without a date',
              getter: function(item) {
                if (item.doc.date && item.doc.endDate) {
                  return $filter('date')(item.doc.endDate, 'MMMM y');
                }

                return;
              }
            }
          },
          state: {
            title: 'state',
            orders: ['state', 'addedDate'],
            groupBy: {
              title: 'Event state is',
              notitle: 'Events without a state',
              getter: function(item) {
                return item.doc.state;
              }
            }
          }
        },
        defaultFilter: defaultFilter,
        defaultOrder: 'addedDate'
      };

      search.ready = false;
      getBlueprintFacets()
        .then(function(facets) {
          var arr = [search.facetly.facets.length, 0].concat(facets);
          search.facetly.facets.splice.apply(search.facetly.facets, arr);
          search.ready = true;
          return search;
        })
        .catch(function(error) {
          $log.warn('Could not get blueprint faces', error);
        });

      return $q.when(search);
    };

    var getPreFilteredSearch = function(preFilterBy) {
      return getDBSearch()
        .then(function(search) {
          search.filters.push(
            {
              id: 'preFilteredBy',
              preMatchFunc: function(preFilterBy) {
                // eventType: [[{},{}], [{},{}]]

                return $q.all(
                  _.map(preFilterBy, function(ORs, dataType) {
                    var filterFound = _.find(search.filters, function(f) {
                      return f.id === dataType;
                    });

                    if (filterFound) {
                      return $q.all(
                        _.map(ORs, function(ands) {
                          return $q.all(
                            _.map(ands, function(filter) {
                              return $q.when(filterFound.preMatchFunc(filter.value))
                                .then(function(data) {
                                  filter.preMatch = data;
                                  return filter;
                                });
                            })
                          );
                        })
                      );
                    }

                    return ORs;
                  })
                );
              },
              matchFunc: function(event, key, _dataTypes, preFilterWithPreMatches) {
                if (_.isEmpty(preFilterWithPreMatches)) {
                  return true;
                }

                // check if all condition match
                var proms = _.map(preFilterWithPreMatches, function(ORs) {
                  return $q.all(
                    _.map(ORs, function(ANDs) {
                      return $q.all(
                        _.map(ANDs, function(filter) {
                          var filterFound = _.find(search.filters, function(f) {
                            return f.id === filter.dataType;
                          });

                          if (filterFound) {
                            return filterFound.matchFunc(event, key, filter.value, filter.preMatch);
                          }

                          var filterValue = filter.value;

                          var e = event.main || event;

                          // get the right value
                          var eventDoc = e.doc;
                          var map = {
                            startDate: e.meta.startDate,
                            endDate: e.meta.endDate
                          };

                          if (eventDoc.date) {
                            map.modifiedDate = eventDoc.date.modified;
                            map.addedDate = eventDoc.date.added;
                          }

                          if (eventDoc.dates && eventDoc.dates.length) {
                            map.completedDate = function() {
                              return _.last(eventDoc.dates).date;
                            };
                          }

                          var currentValue = map[filter.dataType];

                          // convert to dates
                          var dates = [
                            'modifiedDate',
                            'addedDate',
                            'completedDate',
                            'startDate',
                            'endDate'
                          ];

                          if (dates.indexOf(filter.dataType) > -1) {
                            filterValue = new Date(filterValue);
                            currentValue = new Date(currentValue);

                            if (
                              eventDoc.state !== EVENT_STATES.COMPLETE.id &&
                              filter.dataType === 'completedDate'
                            ) {
                              filterValue = undefined;
                            }
                          }

                          if (filter.condition === '=') {
                            return currentValue.getTime() === filterValue.getTime();
                          } else if (filter.condition === '>') {
                            return currentValue.getTime() > filterValue.getTime();
                          } else if (filter.condition === '>=') {
                            return currentValue.getTime() >= filterValue.getTime();
                          } else if (filter.condition === '<') {
                            return currentValue.getTime() < filterValue.getTime();
                          } else if (filter.condition === '<=') {
                            return currentValue.getTime() <= filterValue.getTime();
                          }

                          return $q.reject({ message: 'filter not valid' });
                        })
                      );
                    })
                  );
                });

                return $q.all(proms)
                  .then(function(result) {
                    return _.every(result, function(ORs) {
                      return _.some(ORs, function(ANDs) {
                        return _.every(ANDs);
                      });
                    });
                  });
              }
            }
          );

          search.defaultFilter.preFilteredBy = preFilterBy;

          return search;
        });
    };

    var getTodoSearch = function() {
      var search = {
        facetly: {
          facets: []
        },
        filters: [
          {
            id: 'extendedState',
            preMatchFunc: getStateFilter,
            matchFunc: function(item, _key, _value, preMatch) {
              var event = item.main || item;
              if (!preMatch) {
                return true;
              }

              return preMatch(event);
            }
          }
        ],
        orders: {
          addedDate: function(item) {
            var event = item.main || item;
            var date = event.doc.createdDate || (event.doc.date && event.doc.date.added);
            if (date) {
              return -(moment(date).toDate().getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          },
          recent: function(item) {
            var event = item.main || item;
            var date = (event.doc.saveInfo || {}).lastSaved;
            if (!date) {
              date = event.getDateFor('any');
            }

            if (date) {
              return -(moment(date).toDate().getTime());
            }
            return Number.MAX_SAFE_INTEGER;
          },
          notrecent: function(item) {
            var event = item.main || item;
            var date = (event.doc.saveInfo || {}).lastSaved;
            if (!date) {
              date = event.getDateFor('any');
            }

            if (date) {
              return (moment(date).toDate().getTime());
            }
            return Number.MIN_SAFE_INTEGER;
          }
        },
        orderGroups: {
          addedDate: {
            title: 'date created',
            orders: ['addedDate']
          },
          recent: {
            title: 'most recent',
            orders: ['recent', 'addedDate']
          },
          notrecent: {
            title: 'least recent',
            orders: ['notrecent', 'addedDate']
          }
        },
        defaultOrder: 'addedDate'
      };

      return $q.when(search);
    };

    var searches = {
      dbsearch: getDBSearch,
      allsearch: getAllSearch,
      usersearch: getUserSearch,
      todosearch: getTodoSearch
    };

    var getSearch = function(searchType) {
      var func = searches[searchType];
      if (func === undefined) {
        return $q.reject({ status: 500, message: 'An unknown search type: ' + searchType });
      }

      var ext = _.assignIn({
        key: 'search-' + searchType,
        maxAge: 60 * 60 * 1000,
        cached: true
      });
      return Cache.cachedPromise(func, ext);
    };

    var service = {
      getSearch: getSearch,
      preFilteredSearch: function(preFilterBy) {
        return getPreFilteredSearch(preFilterBy);
      }
    };

    return service;
  }

  EventSearch.$inject = [
    '$q',
    '$filter',
    '$log',
    'moment',
    'UsersStubService',
    'EventsService',
    'EventsStore',
    'EventTypesService',
    'EventTypesStore',
    'BlueprintsService',
    'BlueprintUtils',
    'AuthService',
    'UsersService',
    'CacheService',
    'EVENT_STATES',
    'EVENT_STATES_ORDERED'
  ];

  angular.module('component.events')
    .service('EventSearch', EventSearch);
})();
