(function() {
  'use strict';

  function EventTypeFieldController(
    $q,
    $scope,
    $state,
    $stateParams,
    EventType,
    EventTypes,
    Form,
    Blueprints,
    // Features,
    PredefinedFields,
    Relations,
    Reports,
    Report,
    FORM_TEXT_TYPES,
    Notify,
    Utils,
    Roles,
    Auth,
    TREE_OPTIONS
  ) {
    var ctrl = this;

    ctrl.field = {};
    ctrl.section = {};
    ctrl.sectionFields = [];
    ctrl.eventType = {};
    ctrl.blueprints = [];
    ctrl.relations = [];
    ctrl.roles = [];
    ctrl.reports = [];
    ctrl.textTypes = FORM_TEXT_TYPES;
    ctrl.showForm = true;
    ctrl.blueprintPlaceholder = '';

    ctrl.eventTypeId = $stateParams.eventTypeId;

    var isArrayTreeLike = function(array, template) {
      var el = _.find(array, function(item) {
        if (_.isUndefined(item[template.nodes])) {
          return false;
        }

        return item[template.nodes].length > 0;
      });

      return !_.isUndefined(el);
    };

    var addAnyAsAnOption = function(options) {
      var any = _.find(options, { _id: 'any' });
      if (_.isUndefined(any)) {
        options.push({ _id: 'any', name: 'Any option', categories: [] });
      }

      return options;
    };

    ctrl.treeRestrictions = {
      template: { id: '_id', title: 'name', nodes: 'categories' },
      options: _.assign({}, TREE_OPTIONS, { canEdit: false, inline: true, limit: 0 })
    };

    ctrl.changeBlueprintFieldType = function() {
      if (['discrete', 'discrete_multiple', 'tree'].indexOf(ctrl.field.blueprintType) > -1) {
        ctrl.field.categories = ctrl.field.categories || [];
      }
    };

    ctrl.blueprintChanged = function() {
      var placeholder = '';
      var blueprintType;
      if (ctrl.field.blueprint) {
        var blp = _.find(ctrl.objs, function(itm) {
          return itm.doc._id === ctrl.field.blueprint;
        });
        if (blp) {
          placeholder = blp.doc.name;
          blueprintType = blp.doc.blueprintType;
        }
      }
      ctrl.blueprintPlaceholder = placeholder;
      ctrl.currentBlueprintType = blueprintType;
    };

    var changeFieldRestriction = function() {
      if (!_.isObject(ctrl.field.restricted)) {
        return;
      }

      if (_.isObject(ctrl.field.restricted) && _.isEmpty(ctrl.field.restricted.by)) {
        ctrl.field.restricted.to = [];
        ctrl.fieldRestrictions = [];
        return;
      }

      if (ctrl.field.restricted.to && !_.isArray(ctrl.field.restricted.to)) {
        ctrl.field.restricted.to = [ctrl.field.restricted.to];
      }

      var restrictedField = _.find(ctrl.section.fields, function(f) {
        var fid = f.blueprint || f.relation || f._id;
        return _.isObject(ctrl.field.restricted) &&
          fid === ctrl.field.restricted.by;
      });

      if (restrictedField) {
        var prom;
        if (restrictedField.type === 'blueprint') {
          prom = Blueprints.find(restrictedField.blueprint)
            .then(function(blueprint) {
              return blueprint.categories;
            });
        } else if (restrictedField.type === 'relation') {
          prom = Relations.find(restrictedField.relation)
            .then(function(relation) {
              return relation.categories;
            });
        } else {
          prom = $q.when(restrictedField.categories);
        }

        prom
          .then(function(categories) {
            if (!_.isUndefined(categories) && !_.isEmpty(categories)) {
              var options = angular.copy(categories);
              options = addAnyAsAnOption(options);

              // figure out if it's better to show a tree or a simple select
              ctrl.showTree = isArrayTreeLike(options, ctrl.treeRestrictions.template);

              ctrl.fieldRestrictions = options;
            } else {
              ctrl.fieldRestrictions = [];
              if (!_.isUndefined(ctrl.field.restricted)) {
                delete ctrl.field.restricted.to;
              }
            }

            // Just get rid of old ids
            delete ctrl.field.restricted.blueprintId;
          });
      }
    };

    ctrl.changeFieldRestriction = changeFieldRestriction;

    // Goals
    function loadGoalField() {
      Roles.findAll()
        .then(function(results) {
          var global = [{ _id: '__all__', name: 'All roles' }, { _id: 'owner', name: 'Owner' }];
          var roles = _.map(results, function(role) {
            return { _id: role.doc._id, name: role.doc.title };
          });
          roles = global.concat(roles);

          var onChangePerm = function() {
            if (_.isUndefined(ctrl.field.customsSettings)) {
              return;
            }

            if (!ctrl.field.customsSettings.canAddCustomsInProgress &&
              !ctrl.field.customsSettings.canEditCustomsInProgress &&
              !ctrl.field.customsSettings.canDeleteCustomsInProgress) {
              ctrl.field.customsSettings.customsInProgressFilledBy = [];
            }
          };

          ctrl.customGoalsForm = new Form([
            {
              id: 'canAddCustoms',
              type: 'boolean',
              label: 'Allow custom goals',
              required: false,
              disabled: !ctrl.canEdit,
              onChange: function() {
                if (_.isUndefined(ctrl.field.customsSettings)) {
                  return;
                }

                _.forEach(['Add', 'Edit', 'Delete'], function(perm) {
                  var attr = 'can' + perm + 'CustomsInProgress';
                  ctrl.field.customsSettings[attr] = false;
                });

                ctrl.field.customsSettings.customsInProgressFilledBy = [];
              }
            }
          ]);

          ctrl.customGoalsPermissionsForm = new Form([
            {
              id: 'canAddCustomsInProgress',
              type: 'boolean',
              label: 'Allow adding of new custom goals while in progress',
              required: false,
              onChange: onChangePerm
            },
            {
              id: 'canEditCustomsInProgress',
              type: 'boolean',
              label: 'Allow editing of custom goals while in progress',
              required: false,
              onChange: onChangePerm
            },
            {
              id: 'canDeleteCustomsInProgress',
              type: 'boolean',
              label: 'Allow deleting of custom goals while in progress',
              required: false,
              onChange: onChangePerm
            }
          ]);

          ctrl.customGoalsPermissionsOwnersForm = new Form([
            {
              id: 'customsInProgressFilledBy',
              type: 'discrete_multiple',
              label: 'Who can add/edit/delete the custom goals?',
              required: true,
              options: roles
            }
          ]);

          ctrl.arePredefinedRedefinableForm = new Form([
            {
              id: 'arePredefinedGoalsRedefinable',
              type: 'boolean',
              label: 'Allow these predefined goals to be changed during creation.',
              required: false
            }
          ]);

          ctrl.closeSettingsForm = new Form([
            {
              id: 'canAutoClose',
              type: 'boolean',
              label: 'Would you like the goal set to be auto closed?'
            },
            {
              id: 'markStep',
              type: 'discrete',
              label: 'Should the goals within the goal set be mandatory to be marked?',
              required: true,
              defaultValue: 'mandatory',
              helpText: 'Goals can be marked with a specific outcome. Choose below which ' +
                'user(s)\n  are able to mark all of the goals created as part of this event.',
              options: [
                {
                  _id: 'mandatory',
                  name: 'Users should always mark the goals within this goal set.'
                },
                {
                  _id: 'never',
                  name: 'Users should not be able to mark the goals within this goal set.'
                }
              ],
              hideExpression: function(_$viewValue, _$modelValue, $scope) {
                return $scope.model.canAutoClose;
              }
            },
            {
              id: 'markedBy',
              type: 'discrete_multiple',
              label: 'Who can mark the goals?',
              required: true,
              options: roles,
              hideExpression: function(_$viewValue, _$modelValue, $scope) {
                return $scope.model.canAutoClose === true ||
                       _.isUndefined($scope.model.markStep) ||
                       $scope.model.markStep === 'never';
              }
            },
            {
              id: 'autoCloseConditions',
              type: 'discrete_multiple',
              label: 'When would you like the goal set to be auto closed?',
              helpText: 'Note: You can combine several options, if one of those condition is ' +
                'met,  the goal set will be auto closed.',
              required: true,
              options: [
                { _id: 'overdue', name: 'When the goal set is overdue' },
                { _id: 'allAchievementsMet', name: 'When all achievements have been met' }
              ],
              hideExpression: function(_$viewValue, _$modelValue, $scope) {
                return !$scope.model.canAutoClose;
              }
            }
          ]);
        });

      $scope.field = ctrl.field;
      $scope.$watchCollection('field.definition', function() { ctrl.validateField(); });
      $scope.$watchCollection('field.customsSettings', function() { ctrl.validateField(); });
    }

    EventTypes.getForEdit($stateParams.eventTypeId)
      .then(function(data) {
        ctrl.eventTypeObject = new EventType(data);
        ctrl.origDoc = angular.copy(ctrl.eventTypeObject.doc);
        ctrl.section = _.find(data.sections, { _id: $stateParams.sectionId });
        ctrl.field = _.find(ctrl.section.fields, { _id: $stateParams.fieldId });
        // Note: Because neither event type sections nor fields have a name,
        // the page title will not be unique: (
        Utils.setPageTitle('Edit event type section field for ' + ctrl.origDoc.name);
        return Blueprints.blueprintTypes();
      })
      .then(function(blueprintTypes) {
        ctrl.blueprintTypes = blueprintTypes;
      })
      .then(function() {
        var proms = _.chain(ctrl.section.fields)
          .filter(function(field) {
            return (['customField', 'blueprint', 'relation'].indexOf(field.type) !== -1) &&
              field._id !== $stateParams.fieldId;
          })
          .map(function(field) {
            var prom;
            if (field.type === 'blueprint') {
              prom = Blueprints.find(field.blueprint)
                .then(function(blueprint) {
                  return {
                    _id: blueprint._id,
                    fieldId: field._id,
                    name: blueprint.name
                  };
                });
            } else if (field.type === 'relation') {
              prom = Relations.find(field.relation)
                .then(function(relation) {
                  return {
                    _id: relation._id,
                    fieldId: field._id,
                    name: relation.name
                  };
                });
            } else {
              prom = $q.when(field);
            }

            return prom
              .catch(function(err) {
                // We should catch this error here. Better to not show than spoil the whole page
                // This is not optimal but better I guess
                console.log(err);
              });
          })
          .value();

        return $q.all(proms);
      })
      .then(function(fields) {
        ctrl.showDelete = true;
        ctrl.canEdit = ctrl.eventTypeObject.doc.state === 'draft';
        ctrl.sectionFields = _.chain(fields)
          .filter(function(field) {
            return !_.isUndefined(field);
          })
          .map(function(item) {
            return _.assignIn({}, item);
          })
          .value();
        ctrl.tree = {
          template: { id: '_id', title: 'name', nodes: 'categories' },
          options: _.assign({}, TREE_OPTIONS, { canEdit: ctrl.canEdit, inline: true, limit: 0 })
        };
        changeFieldRestriction();
      })
      .then(function() {
        if (_.indexOf(['blueprint', 'relation', 'role'], ctrl.field.type) > -1) {
          // get usedObj from current field type
          ctrl.usedObjs = _.chain(ctrl.section.fields)
            .filter(function(field) {
              return field.type === ctrl.field.type &&
                field[ctrl.field.type] !== '' &&
                field._id !== ctrl.field._id;
            })
            .map(function(field) {
              return field[ctrl.field.type];
            }).value();

          var prom;
          if (ctrl.field.type === 'blueprint') {
            prom = Blueprints.findAll();
          } else if (ctrl.field.type === 'relation') {
            prom = Relations.findAll();
          } else if (ctrl.field.type === 'role') {
            prom = Roles.findAllDependent();
          }

          prom
            .then(function(objs) {
              ctrl.objs = _.filter(objs, function(obj) {
                var objId = obj.doc._id;
                return ctrl.usedObjs.indexOf(objId) === -1;
              });
              ctrl.blueprintChanged();
            });
        } else if (ctrl.field.type === 'report') {
          ctrl.field.reportData = ctrl.field.reportData || {};
          ctrl.isReportDataEmpty = _.isEmpty(ctrl.field.reportData);

          return Reports.findLatestPublished('stub')
            .then(function(data) {
              ctrl.reports = data;
              if (ctrl.field.report) {
                var found = _.some(data, function(item) {
                  return item.id === ctrl.field.report;
                });

                if (!found) {
                  return Reports.fetch(ctrl.field.report, 'stub')
                    .then(function(report) {
                      ctrl.reports.push(report);
                    })
                    .catch(function(err) {
                      console.log('Could not found report', err);
                      // But ignore as we don't want to cry too much here
                    });
                }
              }
            })
            .then(function() {
              $scope.field = ctrl.field;
              $scope.$watch('field.report', function(reportId) {
                if (_.isUndefined(reportId)) {
                  ctrl.showReportFormSection = false;
                  ctrl.reportDataOverridableForm = undefined;
                  $scope.field.variable = undefined;
                  return;
                }

                if ($scope.field.variable === undefined) {
                  $scope.field.variable = [];
                }

                Reports.fetch(reportId, 'full')
                  .then(function(reportDoc) {
                    var report = new Report({
                      doc: reportDoc.doc,
                      linkedVersions: [reportDoc.doc]
                    });

                    report.getFields()
                      .then(function(fields) {
                        ctrl.showReportFormSection = fields.length > 0;
                        if (fields.length === 0) {
                          $scope.field.variable = [];
                        }
                        var ids = _.map(fields, 'id');
                        var positionsToRemove = [];
                        _.forEach($scope.field.variable, function(variable, index) {
                          if (_.indexOf(ids, variable) === -1) {
                            positionsToRemove.push(index);
                          }
                        });

                        _.forEach(positionsToRemove, function(pos) {
                          $scope.field.variable.splice(pos, 1);
                        });
                        // get the options
                        var fieldOptions = _.map(fields, function(fld) {
                          return { _id: fld.id, name: fld.label };
                        });

                        ctrl.reportDataOverridableForm = new Form([{
                          id: 'variable',
                          type: 'discrete_multiple',
                          label: 'Select the fields that can be changed by the user',
                          disabled: !ctrl.canEdit,
                          options: fieldOptions
                        }]);
                      });
                  });
              });
            })
            .catch(Utils.showError);
        } else if (ctrl.field.type === 'goal') {
          loadGoalField();
        }
      })
      .then(function() {
        ctrl.loaded = true;
      })
      .catch(Utils.showError);

    ctrl.changeBlueprintName = function() {
      Blueprints.find(ctrl.field.blueprint)
        .then(function(blueprint) {
          ctrl.field.name = blueprint.name;
        });
    };

    ctrl.changeReport = function() {
      ctrl.field.reportData = {};
    };

    var remove = function() {
      _.remove(ctrl.section.fields, function(field) { return field._id === $stateParams.fieldId; });
      EventTypes.save(ctrl.eventTypeObject.doc)
        .then(function() {
          Notify.success('Event type has been saved');
          $state.go('epf.event-types.section', {
            eventTypeId: $stateParams.eventTypeId,
            sectionId: $stateParams.sectionId
          });
        });
    };

    ctrl.cancel = function() {
      if (ctrl.field.saved === false) {
        remove();
      } else {
        $state.go('epf.event-types.section', {
          eventTypeId: $stateParams.eventTypeId,
          sectionId: $stateParams.sectionId
        });
      }
    };

    ctrl.cleanField = function() {
      var field = ctrl.field;

      if (!field.enableAntiPlagiarism) {
        delete ctrl.field.enableAntiPlagiarismAttempts;
        delete ctrl.field.enableAntiPlagiarismRequired;
        delete ctrl.field.enableAntiPlagiarismTitle;
      }

      // Clean only customFields
      if (field.type !== 'customField') {
        return;
      }

      var allowed = [
        '_id',
        'blueprintType',
        'type',
        'helpText',
        'name',
        'restricted',
        'showOnTimeline',
        'fileLimit',
        'enableAntiPlagiarism',
        'enableAntiPlagiarismAttempts',
        'enableAntiPlagiarismRequired',
        'enableAntiPlagiarismTitle'
      ];

      var discrete = ['tree', 'discrete', 'discrete_multiple', 'likert'];

      if (field.blueprintType !== 'boolean') {
        allowed.push('isRequired');
      }

      if (discrete.indexOf(field.blueprintType) !== -1) {
        allowed.push('categories');
      }

      var keys = _.keys(ctrl.field);
      _.forEach(keys, function(key) {
        if (allowed.indexOf(key) === -1) {
          delete ctrl.field[key];
        }
      });
    };

    ctrl.save = function(isValid) {
      ctrl.formIsSubmitted = true;
      ctrl.validateField();

      if (isValid) {
        ctrl.eventType.type = 'eventType'; // Make sure this is eventType and not something else
        delete ctrl.field.saved;

        ctrl.cleanField();

        EventTypes.save(ctrl.eventTypeObject.doc)
          .then(function(data) {
            return data;
          })
          .then(function(data) {
            if (ctrl.saveAsPredefinedField) {
              var predefinedField = angular.copy(ctrl.field);
              predefinedField.fieldType = ctrl.field.type;
              predefinedField.type = 'predefinedField';
              predefinedField._id = Utils.guid();
              predefinedField.organisation = Auth.currentOrganisation();
              return PredefinedFields.save(predefinedField)
                .then(function(data) {
                  return data;
                });
            }

            return data;
          })
          .then(function() {
            $state.go('epf.event-types.section', {
              eventTypeId: $stateParams.eventTypeId,
              sectionId: $stateParams.sectionId
            });
          })
          .catch(Utils.showError);
      }
    };

    ctrl.remove = function() {
      Utils.swal({
        title: 'Are you sure you want to remove this field?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          remove();
        }
      });
    };

    ctrl.validateField = function() {
      return ctrl.eventTypeObject.validateField(ctrl.section._id, ctrl.field._id)
        .then(function(errors) {
          ctrl.errors = errors;
        });
    };

    /* Handle event type section form fields */
    ctrl.removeEventTypeSectionField = function(fieldId) {
      Utils.swal({
        title: 'Are you sure you want to remove this field?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          _.remove(ctrl.section.fields, function(field) { return field._id === fieldId; });
        }
      });
    };

    ctrl.addEventTypeSectionCustomFieldCategory = function(customFieldId) {
      var customField = _.find(ctrl.section.fields, { _id: customFieldId });
      customField.categories = customField.categories ? customField.categories : [];
      customField.categories.push({ _id: Utils.guid(), name: '', categories: [] });
    };

    ctrl.addEventTypeSectionBlueprintCustomCategory = function(blueprintId) {
      var blueprint = _.find(ctrl.section.fields, { _id: blueprintId });

      blueprint.customCategories = blueprint.customCategories ? blueprint.customCategories : [];
      blueprint.customCategories.push({ _id: Utils.guid(), name: '', categories: [] });
    };

    ctrl.customiseEventTypeSectionBlueprint = function(blueprintId) {
      Blueprints.find(blueprintId)
        .then(function(data) {
          var blueprint = _.find(ctrl.section.fields, { blueprint: blueprintId });

          _.forEach(data.categories, function(category) {
            category.readonly = true;
          });

          blueprint.categories = data.categories;
          blueprint.hasCustomCategories = true;
        });
    };

    ctrl.currentField = '';

    /* Handle hierarchies */
    ctrl.eventTypeSectionFieldCategoryRemoveNode = function(scope) {
      scope.remove();
    };

    ctrl.eventTypeSectionFieldCategoryToggle = function(scope) {
      scope.toggle();
    };

    ctrl.eventTypeSectionFieldCategoryNewSubNode = function(scope) {
      var nodeData = scope.$modelValue;
      nodeData.categories.push({
        _id: Utils.guid(),
        name: '',
        categories: []
      });
    };

    ctrl.showTurnitinSection = function() {
      var currentType;
      if (ctrl.field.type === 'customField') {
        currentType = ctrl.field.blueprintType;
      }

      if (ctrl.field.type === 'blueprint' && ctrl.field.blueprint) {
        if (ctrl.currentBlueprintType === undefined) {
          var blp = _.find(ctrl.objs, function(itm) {
            return itm.doc._id === ctrl.field.blueprint;
          });
          if (blp) {
            ctrl.currentBlueprintType = blp.doc.blueprintType;
          }
        }
        currentType = ctrl.currentBlueprintType;
      }
      var show = ['text', 'file'].indexOf(currentType) !== -1;
      if (show && ctrl.field.enableAntiPlagiarismAttempts === undefined) {
        ctrl.field.enableAntiPlagiarismAttempts = 0;
      }
      return show;
    };

    ctrl.currentType = function() {
      var currentType;
      if (ctrl.field.type === 'customField') {
        currentType = ctrl.field.blueprintType;
      }

      if (ctrl.field.type === 'blueprint' && ctrl.field.blueprint) {
        if (ctrl.currentBlueprintType === undefined) {
          var blp = _.find(ctrl.objs, function(itm) {
            return itm.doc._id === ctrl.field.blueprint;
          });
          if (blp) {
            ctrl.currentBlueprintType = blp.doc.blueprintType;
          }
        }
        currentType = ctrl.currentBlueprintType;
      }
      return currentType;
    };
  }

  EventTypeFieldController.$inject = [
    '$q',
    '$scope',
    '$state',
    '$stateParams',
    'EventTypeFactory',
    'EventTypesService',
    'FormsService',
    'BlueprintsService',
    // 'FeaturesService',
    'EventTypePredefinedFieldsService',
    'RelationsService',
    'ReportTemplatesService',
    'ReportFactory',
    'FORM_TEXT_TYPES',
    'NotifyService',
    'UtilsService',
    'RolesService',
    'AuthService',
    'TREE_OPTIONS'
  ];

  angular.module('component.eventTypes')
    .controller('EventTypeFieldController', EventTypeFieldController);
})();
