(function() {
  'use strict';

  function EventTypeController(
    $q,
    $state,
    $stateParams,
    Auth,
    EventType,
    EventTypes,
    EventTypesStore,
    Roles,
    Blueprints,
    BlueprintMaps,
    BLUEPRINT_TYPES,
    UserFields,
    Form,
    Notify,
    Security,
    Utils,
    TREE_OPTIONS
  ) {
    var ctrl = this;
    var action = $state.current.data.action;

    ctrl.tags = [];
    ctrl.blueprints = [];
    ctrl.blueprintTypes = BLUEPRINT_TYPES;

    ctrl.prefilledFields = [];
    UserFields.findAll()
      .then(function(data) {
        ctrl.prefilledFields = data;
      })
      .catch(Utils.showError);

    ctrl.tagsTree = {
      tree: [],
      template: { id: '_id', title: 'name', nodes: 'categories' },
      options: _.assign(
        angular.copy(TREE_OPTIONS),
        {
          canEdit: false,
          limit: 0,
          allowSelect: true,
          inline: false
        }
      )
    };

    Blueprints.findAll()
      .then(function(data) {
        ctrl.tagsTree.tree = _.filter(data, function(obj) {
          return (_.indexOf(
            ['discrete', 'discrete_multiple', 'tree'],
            obj.doc.blueprintType
          ) !== -1);
        });

        ctrl.tagsTree.tree = _.map(ctrl.tagsTree.tree, function(obj) {
          return { _id: obj.doc._id, name: obj.doc.name, categories: obj.doc.categories };
        });
      });

    BlueprintMaps.getByUsage('events')
      .then(function(data) {
        ctrl.blueprintMaps = data;
      });

    ctrl.basicForm = new Form();
    ctrl.eventPropertiesLeftForm = new Form();
    ctrl.eventPropertiesRightForm = new Form();

    function buildForms() {
      var basicFormFields = [
        {
          id: 'name',
          type: 'string',
          label: 'Event type name',
          required: true,
          disabled: !ctrl.hasEditPerm || !ctrl.isDraft
        },
        {
          id: 'description',
          type: 'text',
          label: 'Event type description',
          required: false,
          disabled: !ctrl.hasEditPerm || !ctrl.isDraft
        }
      ];

      var form = ctrl.basicForm;
      basicFormFields.forEach(function(field) {
        form.addField(field);
      });

      var eventPropertiesLeftForm = ctrl.eventPropertiesLeftForm;
      eventPropertiesLeftForm.addField(Roles.findAll({ includeSystemRoles: true })
        .then(function(data) {
          return {
            id: 'ownerRole',
            type: 'discrete_multiple',
            label: 'This event can be owned by',
            required: true,
            disabled: !ctrl.hasEditPerm || !ctrl.isDraft,
            options: data
              .map(function(role) {
                return { _id: role.doc._id, key: role.doc._id, name: role.doc.title };
              })
              .filter(function(role) {
                return [
                  'system:timeline-owner',
                  'system:anonymous-external',
                  'system:pending-user',
                  'system:system',
                  'system:admin'
                ].indexOf(role._id) === -1;
              })
          };
        })
      );

      var eventPropertiesLeftFormExtraFields = [
        {
          id: 'defaultVisibility',
          type: 'discrete',
          label: 'Specify default visibility',
          required: true,
          disabled: !ctrl.hasEditPerm || !ctrl.isDraft,
          options: [
            { _id: 'private', name: 'Private' },
            { _id: 'public', name: 'Shared' }
          ]
        },
        {
          id: 'changableVisibility',
          type: 'boolean',
          label: 'Owner can change visibility on individual events',
          required: false,
          disabled: !ctrl.hasEditPerm || !ctrl.isDraft
        }
      ];

      eventPropertiesLeftFormExtraFields.forEach(function(field) {
        eventPropertiesLeftForm.addField(field);
      });

      ctrl.defaultEndDateOffset = 0;

      var eventPropertiesRightFields = [
        {
          id: 'defaultEndDate',
          type: 'discrete',
          label: 'Default end date',
          required: false,
          disabled: !ctrl.hasEditPerm || !ctrl.isDraft,
          defaultValue: 'default',
          options: [
            { _id: 'default', name: 'Same as date occured' },
            { _id: 'offsetted', name: 'After default date occured' }
          ],
          onChange: function() {
          }
        },
        {
          id: 'defaultEndDateOffset',
          type: 'numeric',
          label: 'Offset in days',
          required: true,
          disabled: !ctrl.hasEditPerm || !ctrl.isDraft,
          defaultValue: 0,
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            if ($scope.model.defaultEndDate === 'default') {
              $scope.model.defaultEndDateOffset = 0;
              return true;
            }
          }
        }
      ];

      var eventPropertiesRightForm = ctrl.eventPropertiesRightForm;
      eventPropertiesRightFields.forEach(function(field) {
        eventPropertiesRightForm.addField(field);
      });

      return $q.when();
    }

    if (action === 'edit' && !$stateParams.id) {
      $state.go('epf.event-types.index');
    }

    if (action === 'edit') {
      // used for view and edit
      EventTypesStore.getFull($stateParams.id)
        .then(function(data) {
          ctrl.origDoc = angular.copy(data.doc);
          try {
            ctrl.eventType = data;
            ctrl.version = ctrl.eventType;
          } catch (err) {
            return $q.reject({ status: 500, message: err });
          }

          return Security.hasPermission('eventTypes.edit')
            .catch(function(error) {
              if (error.status === 403 && ctrl.eventType.doc.state === 'published') {
                return false;
              }

              $state.go('epf.event-types.index');
            });
        })
        .then(function(hasEditPerm) {
          ctrl.hasEditPerm = hasEditPerm;
          ctrl.isDraft = ctrl.eventType.doc.state === 'draft';
        })
        .then(function() {
          return buildForms();
        })
        // .then(function() {
        //   return ctrl.eventType.findAllReports();
        // })
        // .then(function(reports) {
        //   ctrl.reports = [{ id: 'default', value: 'Default' }].concat(reports);
        //   ctrl.eventType.doc.defaultVisibility =
        //    ctrl.eventType.doc.defaultVisibility || 'public';
        // })
        .then(function() {
          ctrl.loaded = true;
          Utils.setPageTitle(
            'Edit event type ' + ctrl.eventType.doc.name + ' version ' + ctrl.eventType.doc.version
          );
          ctrl.validate();
        })
        .catch(Utils.showError);
    } else {
      ctrl.hasEditPerm = true;
      ctrl.isDraft = true;
      var defaultDoc = !$stateParams.doc ? EventTypes.getDefaultDoc() : $stateParams.doc.doc;
      ctrl.eventType = new EventType(defaultDoc);
      ctrl.version = ctrl.eventType;
      Utils.setPageTitle('New event type');
      buildForms();
      ctrl.loaded = true;
    }

    function doSave(isValid, options) {
      options = options || {};
      // this method should not be use to change the state. Please use version action buttons.
      var isNew = !ctrl.eventType.doc._rev;

      // hack: retro compatibility: we've changed the default value to booleans to false
      if (
        !_.isUndefined(ctrl.origDoc) &&
        ctrl.origDoc.state !== 'draft' &&
        _.isUndefined(ctrl.origDoc.changableVisibility)
      ) {
        ctrl.eventType.doc.changableVisibility = undefined;
        ctrl.origDoc.changableVisibility = undefined; // in case it's not set at allx
      }

      if (!options.force && ctrl.origDoc && _.isEqual(ctrl.origDoc, ctrl.eventType.doc)) {
        return $q.when(isNew);
      }
      ctrl.formIsSubmitted = true;

      if (!ctrl.hasEditPerm) {
        return $q.reject({ message: 'You are not able to save!' });
      }

      if (!isValid) {
        return $q.reject({ message: 'Form not valid' });
      }

      if (isNew) {
        Utils.recordLog(ctrl.eventType.doc, 'created', Auth.currentUser());
      }

      return EventTypes.save(ctrl.eventType.doc)
        .then(function() {
          ctrl.formIsSaved = true;
          Notify.success('Event type has been saved');
          return $q.when(isNew);
        });
    }

    ctrl.save = function(isValid) {
      doSave(isValid, { force: true })
        .then(function(isNew) {
          if (isNew) {
            $state.go('epf.event-types.edit', { id: ctrl.eventType.doc._id });
          }

          return $q.when();
        })
        .catch(Utils.showError);
    };

    ctrl.preview = function(isValid) {
      doSave(isValid)
        .then(function() {
          $state.go('epf.event-types.preview', { id: ctrl.eventType.doc._id });
        });
    };

    /* Handle event type form sections */
    ctrl.addOrEditSection = function(action, isValid, sectionId) {
      var sectId = sectionId;
      if (action === 'new') {
        sectId = Utils.guid();
        var section = { _id: sectId, filledBy: '', fields: [] };
        ctrl.eventType.doc.sections = ctrl.eventType.doc.sections ?
          ctrl.eventType.doc.sections :
          [];
        ctrl.eventType.doc.sections.push(section);
      }

      doSave(isValid)
        .then(function() {
          $state.go('epf.event-types.section', {
            eventTypeId: ctrl.eventType.doc._id,
            sectionId: sectId
          });
        })
        .catch(Utils.showError);
    };

    ctrl.removeEventTypeFormSection = function(id) {
      Utils.swal({
        title: 'Are you sure you want to remove this section?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          _.remove(ctrl.eventType.doc.sections, function(section) { return section._id === id; });
        }
      });
    };

    ctrl.validate = function() {
      return ctrl.eventType.validate()
        .then(function(errors) {
          ctrl.errors = errors;
        });
    };

    ctrl.handleSort = function(evt) {
      if (evt.oldIndex === evt.newIndex) {
        return;
      }

      _.forEach(evt.models, function(model) {
        delete model.skipCondition;
        delete model.dependentSectionsPerms;
      });
    };

    ctrl.loadAuditlog = function() {
      EventTypes.getAuditlog($stateParams.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };
  }

  EventTypeController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    'AuthService',
    'EventTypeFactory',
    'EventTypesService',
    'EventTypesStore',
    'RolesService',
    'BlueprintsService',
    'BlueprintMapsService',
    'BLUEPRINT_TYPES',
    'UserFieldsService',
    'FormsService',
    'NotifyService',
    'SecurityService',
    'UtilsService',
    'TREE_OPTIONS'
  ];

  angular.module('component.eventTypes')
    .controller('EventTypeController', EventTypeController);
})();
