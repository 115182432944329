(function() {
  'use strict';

  var dateFormatMapping = {
    date: 'dateonly',
    datetime: 'datetime'
  };

  function SingleReportViewDirective(
    $q,
    $log,
    $filter,
    Auth,
    Event,
    Events,
    Goal,
    Goals,
    Reports,
    Report,
    Utils,
    Types,
    LocalizationService,
    CHART_COLORS
  ) {
    return {
      scope: {
        reportEx: '=report',
        reportDoc: '=',
        reportId: '@',
        model: '=',
        field: '=',
        generated: '=?',
        resultLoaded: '&',
        storedResult: '=',
        extra: '=',
        itemsToDisplay: '=',
        mode: '@?',
        size: '=?',
        needPreview: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/single-report-view.html',
      replace: true,
      link: function($scope) {
        if ($scope.itemsToDisplay.display === 'all') {
          $scope.showTable = true;
          $scope.showCharts = true;
        } else {
          $scope.showTable = _.indexOf(['table', 'both'], $scope.itemsToDisplay.display) > -1;
          $scope.showCharts = _.indexOf(['charts', 'both'], $scope.itemsToDisplay.display) > -1;
        }

        function loadCharts(result) {
          if (!$scope.showCharts || _.isEmpty(result.hits)) {
            return $q.when([]);
          }

          var fieldsMap = {};
          var fields = $scope.report.getAggOutputFields();
          _.forEach(fields, function(field, index) {
            fieldsMap[field.id] = { index: index, def: field };
          });

          var proms = _.chain($scope.report.conf.charts)
            .filter(function(chart) {
              return $scope.itemsToDisplay.display === 'all' ||
                _.indexOf($scope.itemsToDisplay.charts, chart.id) > -1;
            })
            .map(function(chart) {
              try {
                var labelsPosition = fieldsMap[chart.x.outputField].index;
                var defField = fieldsMap[chart.x.outputField].def;
                var labelsProms = _.map(result.hits, function(row) {
                  var value = row.row[labelsPosition];
                  if (_.isObject(value) && value.value !== undefined) {
                    value = value.value;
                  }

                  // hack: this is in the case they use groupby and metric, any better solution?
                  if (_.isObject(value) && !_.isUndefined(value.items) && value.items.length > 0) {
                    // Just pick first agg
                    value = value.items[0][1];
                  }

                  if (!_.isUndefined(value) && !_.isObject(value) &&
                     ['date', 'datetime'].indexOf(defField.def.type) !== -1
                  ) {
                    var format = dateFormatMapping[defField.def.type];
                    var dateFormat = LocalizationService.getDateTimeFormat(format);
                    value = $filter('date')(value, dateFormat);
                  }

                  return $q.when(value);
                });

                return $q.all(labelsProms)
                  .then(function(labels) {
                    var shuffledColors = _.shuffle(CHART_COLORS);
                    var chartsColumns;
                    if ($scope.itemsToDisplay === undefined) {
                      chartsColumns = ['__all__'];
                    } else if ($scope.itemsToDisplay.chartsColumns === undefined) {
                      chartsColumns = ['__all__'];
                    } else {
                      chartsColumns = $scope.itemsToDisplay.chartsColumns;
                    }

                    var datasets = _.map(chart.ys, function(y, yindex) {
                      if (
                        _.indexOf(chartsColumns, '__all__') === -1 &&
                        _.indexOf(chartsColumns, y.id) === -1) {
                        return {};
                      }

                      var dataPosition = fieldsMap[y.outputField].index;
                      var data = _.map(result.hits, function(row) {
                        var value;
                        _.forEach(row.row[dataPosition].items, function(item) {
                          if (item[0] === y.agg) {
                            value = item[1];
                          }
                        });

                        return value;
                      });

                      var dataset = {
                        label: y.label,
                        data: data,
                        borderWidth: 1
                      };

                      if (y.type !== 'polar') {
                        // polar does not like to have the type overriden
                        dataset.type = y.type;
                      }

                      if (_.indexOf(['line', 'radar'], y.type) > -1) {
                        var c = Utils.generateColor();
                        dataset.borderColor = c;
                        if (y.type === 'line') {
                          dataset.fill = false;
                        } else {
                          dataset.backgroundColor = c;
                        }
                      } else {
                        var start = yindex * labels.length;
                        var end = start + labels.length;
                        var rangeOfColors = shuffledColors.slice(start, end);
                        var colors = { border: [], bg: [] };
                        _.forEach(rangeOfColors, function(color) {
                          var args = color.r + ', ' + color.g + ', ' + color.b;
                          colors.border.push('rgba(' + args + ', 1)');
                          colors.bg.push('rgba(' + args + ', 0.4)');
                        });

                        dataset.borderColor = colors.border;
                        dataset.backgroundColor = colors.bg;
                      }

                      return dataset;
                    });

                    var data = { labels: labels, datasets: datasets };

                    var options = {
                      title: {
                        display: true,
                        text: chart.title
                      },
                      tooltips: {
                        enabled: true,
                        mode: 'nearest',
                        callbacks: {
                          title: function(tooltipItems, data) {
                            var idx = tooltipItems[0].index;
                            return data.labels[idx];
                          }
                        }
                      }
                    };

                    var type = chart.ys[0].type;
                    if (_.indexOf(['bar', 'line'], type) > -1) {
                      options.legend = { display: !!chart.showLegend };
                      options.scales = {
                        xAxes: [{
                          scaleLabel: {
                            display: true,
                            labelString: chart.x.label
                          },
                          ticks: {
                            stepSize: 1,
                            min: 0,
                            autoSkip: false,
                            callback: function(value) {
                              if (_.isUndefined(value) || value === null) {
                                return '';
                              }

                              if (value.length > 40) {
                                var truncated = value.substr(0, 40);
                                return truncated + '...';
                              }

                              return value;
                            }
                          }
                        }],
                        yAxes: [{
                          ticks: {
                            beginAtZero: true
                          }
                        }]
                      };
                    }

                    return {
                      type: chart.ys[0].type,
                      description: chart.description,
                      data: data,
                      options: options
                    };
                  });
              } catch (error) {
                return { error: error, title: chart.title };
              }
            })
            .value();

          return $q.all(proms);
        }
        $scope.dateFormat = LocalizationService.getDateTimeFormat('datetime');
        $scope.dateOnlyFormat = LocalizationService.getDateTimeFormat('date');
        function loadReport() {
          var promise;
          if ($scope.reportEx !== undefined) {
            $scope.report = $scope.reportEx;
            promise = $q.when();
          } else if ($scope.reportDoc !== undefined) {
            $scope.report = new Report($scope.reportDoc);
            promise = $q.when();
          } else {
            promise = Reports.getGroupByItemId($scope.reportId, true)
              .then(function(doc) {
                $scope.report = new Report(doc);
              })
              .catch(function(err) {
                $log.warn('Report not found', err);
              });
          }

          return promise;
        }

        // This is a hack and should be done properly on the backend
        function fixNullAggValues(result) {
          var columns = [],
              aggValues = {},
              order = [];

          if (result.table) {
            _.forEach(result.table.hits, function(hit) {
              var x;
              var finalValue;
              _.forEach(hit.row, function(item, columnNumber) {
                if (_.isUndefined(columns[columnNumber])) {
                  columns[columnNumber] = [];
                }

                if (item.composed && item.subtype !== 'extended') {
                  var aggType = item.items[0][0],
                      value = item.items[0][1];

                  if (
                    _.indexOf(['avg', 'sum', 'max', 'min'], aggType) > -1 &&
                    (value === null || value === 0)
                  ) {
                    var values = columns[columnNumber];
                    if (aggType === 'avg') {
                      value = _.sum(values) / values.length;
                    } else if (aggType === 'sum') {
                      value = _.sum(values);
                    } else if (aggType === 'max') {
                      value = _.max(values);
                    } else if (aggType === 'min') {
                      value = _.min(values);
                    }

                    item.items[0][1] = value;
                    finalValue = value;
                  }

                  columns = [];
                } else {
                  if (item) {
                    x = item;
                  }
                  columns[columnNumber].push(item);
                }

                if (x && finalValue && !aggValues[x]) {
                  aggValues[x] = finalValue;
                  order.push(x);
                }
              });
            });
          }
          if (result.chart) {
            var hitByAgg = {};
            _.forEach(result.chart.hits, function(hit) {
              var x;
              var positionComposedValue;
              _.forEach(hit.row, function(item, columnNumber) {
                if (
                  item.composed &&
                  _.indexOf(['avg', 'sum', 'max', 'min'], item.items[0][0]) > -1 &&
                  (item.items[0][1] === null || item.items[0][1] === 0)
                ) {
                  positionComposedValue = columnNumber;
                } else if (item) { x = item; }

                if (x && !_.isUndefined(positionComposedValue)) {
                  hit.row[positionComposedValue].items[0][1] = aggValues[x];
                  hitByAgg[x] = hit;
                }
              });
            });

            if (!_.isEmpty(hitByAgg)) {
              result.chart.hits = _.map(order, function(x) {
                return hitByAgg[x];
              });
            }
          }

          return result;
        }

        function cleanResult(result) {
          var tableColumns;
          if ($scope.itemsToDisplay === undefined) {
            tableColumns = ['__all__'];
          } else if ($scope.itemsToDisplay.tableColumns === undefined) {
            tableColumns = ['__all__'];
          } else {
            tableColumns = $scope.itemsToDisplay.tableColumns;
          }

          // filter columns if necessary
          if (_.indexOf(tableColumns, '__all__') === -1) {
            result.table.headers = _.filter(result.table.headers, function(_headers, index) {
              return _.indexOf(tableColumns, index) > -1;
            });

            _.forEach(result.table.hits, function(hit) {
              hit.row = _.filter(hit.row, function(_r, index) {
                return _.indexOf(tableColumns, index) > -1;
              });
            });
          }
        }

        function runReport() {
          $scope.loading = true;
          $scope.error = undefined;
          if ($scope.report === undefined) {
            return;
          }

          // debugger
          // $scope.fields = $scope.report.getOutputFields()
          return $scope.report.run(
            $scope.model, $scope.sizeData.size, undefined,
            ($scope.extra || {}).user, $scope.needPreview
          )
            .then(function(result) {
              result = fixNullAggValues(result);
              $scope.showPaginator = result.table.total > $scope.sizeData.size ||
                  $scope.origSize !== $scope.sizeData.size || result.table.loadMore;
              $scope.showReport = !($scope.report.conf.hideIfEmpty &&
                  result.table.hits.length === 0);

              $log.debug('Loaded report data');

              cleanResult(result);

              $scope.result = result.table;
              $scope.loading = false;
              $scope.loaded = true;
              $scope.generated = true;
              $scope.regenerated = true;

              return result;
            })
            .then(function(result) {
              var chartResult = result.chart || result.table;
              return loadCharts(chartResult)
                .then(function(charts) {
                  $scope.charts = charts;
                  $scope.chartLoadMore = chartResult.loadMore;

                  if ($scope.resultLoaded) {
                    if ($scope.report.elementId) {
                      $scope.resultLoaded(
                        { result: { report: $scope.report.elementId, data: result } }
                      );
                    } else {
                      $scope.resultLoaded({ result: result });
                    }
                  }
                });
            })
            .catch(function(err) {
              $scope.result = {};
              $scope.loading = false;
              $scope.error = err;
              $scope.loading = false;
              $scope.regenerated = true;
              $scope.loaded = true;
              $scope.generated = true;
              $scope.showReport = true;
            });
        }

        $scope.size = ($scope.model || {}).size || $scope.size || 50;
        $scope.sizeData = { size: $scope.size };
        $scope.sizeDataInput = { size: $scope.size.toString() };
        $scope.origSize = $scope.sizeData.size;
        $scope.start = 0;

        $scope.loading = true;

        $log.debug('Loading report');
        loadReport()
          .then(function() {
            $scope.fields = $scope.report.getOutputFields();
            // filter columns if necessary
            if (
              !_.isUndefined($scope.itemsToDisplay.tableColumns) &&
              _.indexOf($scope.itemsToDisplay.tableColumns, '__all__') === -1
            ) {
              $scope.fields = _.filter($scope.fields, function(_field, index) {
                return _.indexOf($scope.itemsToDisplay.tableColumns, index) > -1;
              });
            }

            if ($scope.storedResult !== undefined && $scope.storedResult.error === undefined) {
              // Support for old format
              if (_.isUndefined($scope.storedResult.table)) {
                $scope.result = $scope.storedResult;
              } else {
                $scope.result = $scope.storedResult.table;
              }
              $scope.regenerated = false;

              $scope.loading = false;
              $scope.loaded = true;
              $scope.generated = true;
              $scope.showReport = !($scope.report.conf.hideIfEmpty &&
                   $scope.result.hits.length === 0);

              var chartResult = $scope.storedResult.chart || $scope.storedResult.table || {};
              loadCharts(chartResult)
                .then(function(charts) {
                  $scope.charts = charts;
                  $scope.chartLoadMore = chartResult.loadMore;

                  $scope.loading = false;
                  $scope.loaded = true;
                  $scope.generated = true;
                  $log.debug('Reusing report');
                });
            } else {
              $scope.$watch('generated', function(val) {
                if (!val) {
                  return runReport();
                }
              });
              $scope.$on('RegenerateReport', function() {
                $scope.generated = false;
              });
            }
          });

        $scope.apply = function() {
          $scope.sizeData.size = parseInt($scope.sizeDataInput.size);
          $scope.start = 0;
          runReport();
        };

        $scope.prevPage = function() {
          return $scope.report
            .run(
              $scope.model, $scope.sizeData.size,
              $scope.start - $scope.sizeData.size, ($scope.extra || {}).user
            )
            .then(function(result) {
              cleanResult(result);
              $scope.result = result.table;
              $scope.start = $scope.start -= $scope.sizeData.size;
            });
        };

        $scope.nextPage = function() {
          return $scope.report
            .run(
              $scope.model, $scope.sizeData.size,
              $scope.start + $scope.sizeData.size, ($scope.extra || {}).user
            )
            .then(function(result) {
              cleanResult(result);
              $scope.result = result.table;
              $scope.start = $scope.start += $scope.sizeData.size;
            });
        };

        $scope.lowest = function() {
          if ($scope.result.total === 0) {
            return 0;
          }

          return ($scope.start || 0) + 1;
        };

        $scope.highest = function() {
          var total;
          try {
            total = ($scope.start || 0) + ($scope.result.hits.length || 0);
            if (total > $scope.result.total) {
              total = $scope.result.total;
            }
          } catch (err) {
            total = 0;
          }

          return total;
        };

        $scope.preview = function(url) {
          if ($scope.report.conf.source === 'events') {
            var id = url.split('users/edit/events/')[1];

            Events.findFor(id)
              .then(function(data) {
                var isLocal = data.doc.user === Auth.currentUser();
                var event = new Event(data.doc, { isLocal: isLocal });
                return event.init(data.doc);
              })
              .then(function(event) {
                event.openEventPreview();
              });
          } else if ($scope.report.conf.source === 'goals') {
            var urlpart = url.split('/users/work/')[1];
            var parts = urlpart.split('/');
            var username = parts[0];
            var goal = parts[2];
            Goals.findFor(goal, username)
              .then(function(data) {
                Types.openPreview(data);
              });
          } else {
            console.log('Not implemented: Source not available to preview');
          }
        };
        $scope.markGoal = function(url) {
          if ($scope.report.conf.source === 'goals') {
            var urlpart = url.split('/users/work/')[1];
            var parts = urlpart.split('/');
            var username = parts[0];
            var goalId = parts[2];
            var goal;
            Goal.find(goalId, username)
              .then(function(obj) {
                goal = obj;
                return goal.checkPermission('canMark');
              })
              .then(function() {
                goal.openResolveDataModal();
              });
          } else {
            console.log('Not implemented: Source not available to preview');
          }
        };

        $scope.labelStyle = function(value) {
          var label;
          value = parseInt(value);
          if (value >= 100) {
            label = 'success';
          } else if (value >= 66) {
            label = 'info';
          } else if (value >= 33) {
            label = 'warning';
          } else if (value >= 0) {
            label = 'danger';
          }

          return label;
        };
      }
    };
  }

  SingleReportViewDirective.$inject = ['$q',
    '$log',
    '$filter',
    'AuthService',
    'EventFactory',
    'EventsService',
    'GoalFactory',
    'GoalsService',
    'ReportTemplatesService',
    'ReportFactory',
    'UtilsService',
    'TypesService',
    'LocalizationService',
    'CHART_COLORS'
  ];

  angular.module('component.reports')
    .directive('kzSingleReportView', SingleReportViewDirective);
})();
