(function() {
  'use strict';

  function PendingTasks(AsyncTasks) {
    var ctrl = this;
    ctrl.$onInit = function() {
      ctrl.status = AsyncTasks.status;
    };
  }

  PendingTasks.$inject = [
    'AsyncTasksService'
  ];

  angular.module('component.tasks')
    .component('pendingTasks', {
      templateUrl: 'app/components/tasks/pendingtasks.html',
      controller: PendingTasks
    });
})();
