import * as Oidc from 'oidc-client';
import { initial } from "lodash";
import {trace} from "@uirouter/core";
trace.enable(1, 5);
var query_fast;
var getOrganisation = function() {
  var search = window.location.search;
  var query_org;
  if (typeof URLSearchParams != "undefined") {
    var sp = new URLSearchParams(search);
    query_org = sp.get('org')
    query_fast = sp.get('fast_redirect')
    sp.delete('org');
    sp.delete('fast_redirect');
    if (query_org) {
      var newSearch = sp.toString();
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      if (newSearch) {
        newurl += '?' + newSearch;
      }
      if (window.history && window.history.pushState) {
        window.history.pushState({path: newurl}, '', newurl);
      }
    }
  }

  var org = window.localStorage.getItem('currentOrganisation');
  if (!org) {
    // Try get it from cookie
    try {
      const cookies = document.cookie.split(';');
      cookies.forEach(function(cookie) {
        var spl = cookie.split('=');
        if (spl[0].trim() === 'currentOrganisation') {
          org = spl[1].trim();
        }
      });
    } catch (err) {
      console.log('Could not get organisation from cookie', err);
    }
  }
  if (query_org && org !== query_org) {
    org = query_org;
    window.localStorage.setItem('currentOrganisation', org);
  }
  return org;
}
var getConfiguration = function() {
  // If anything fails, lets have at least an empty object
  window.kzConfig = {};
  return fetch('__config__')
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      console.log('Config: Fetched new config from server');
      window.kzConfig = data;
      try {
        window.localStorage.setItem('kzConfig', JSON.stringify(data));
      } catch (err) {
        console.log('Config: Could not save kzConfig', err);
      }
    })
    .catch(function(error) {
      // Try get config from local storage
      try {
        var config = window.localStorage.getItem('kzConfig');
        if (config) {
          window.kzConfig = JSON.parse(config);
          console.log('Config: Recovered config from local storage');
        }
      } catch (err) {
        console.log('Config: Could not recover config from local storage');
      }
    })
    .then(function() {
      console.log('Config: Using configuration', window.kzConfig);
    });
}

var startKaizen = function() {
  if (window.appReady && window.runKaizen !== undefined) {
    console.log('Attempting to start kaizen');
    window.runKaizen();
  } else {
    setTimeout(startKaizen, 50);
  }
}

var handleAuth = function() {
 if (window.navigator && !window.navigator.onLine) {
    console.log('Browser is offline. Starting without user');
    return;
  }

  if (window.kzConfig.authType !== 'oidc') {
    return
  }

  console.log('Browser is online. Checking auth');

  // var Oidc = window.Oidc;
  var serverSettings = window.kzConfig ? window.kzConfig.oidc : {};
  var defaultSettings = {
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 60,
    scope: 'openid ' + window.localStorage.getItem('eas:lastScope') || '',
    loadUserInfo: true,
    // userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
  };

  var params = Object.assign({}, serverSettings, defaultSettings);

  var org = getOrganisation();
  if (org) {
    params.extraQueryParams = { kz_org: org };
    if (query_fast) {
      params.extraQueryParams.kz_fast_redirect = query_fast
    }
  }
  var manager = new Oidc.UserManager(params);
  return manager.getUser()
    .then(function(user) {
      console.log('Received logged in user');

      if (!user || user.expired) {
        console.log('Logged in user token has expired. Redirecting');
        try {
          var href = location.pathname + location.search + location.hash;
          window.localStorage.setItem('lastUrl', href);
          return manager.signinRedirect()
            .then(function(err) {
              return Promise.reject('Redirect to sign in');
            })
            .catch(function(err) {
              if (err === 'Redirect to sign in') {
                return Promise.reject('Redirect to sign in');
              }
              console.log(err);
              console.log('Could not redirect to sign in, trying to load anyway');
              return;
            })
        } catch (err) {
          console.log(err);
          // should we try to start kaizen?
          // startKaizen();
        }
      } else {
        try {
          var userData = JSON.parse(user.toStorageString());
          userData['id_token'] = '';
          userData['access_token'] = '';
          userData['refresh_token'] = '';
          userData['expires_at'] = -1;
          window.localStorage.setItem('currentOidcUser', JSON.stringify(userData));
        } catch (err) {
          console.log('Failed to store user info to localstorage', err);
        }
      }
    });
  }

var initial = async function initial() {
  return getConfiguration()
    .then(function(resp) {
      return handleAuth();
    });
}

export default initial;
