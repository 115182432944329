(function() {
  'use strict';

  function TasksService(UserService) {
    var service = new UserService('celeryTask');

    return service;
  }

  TasksService.$inject = ['UserService'];

  angular.module('component.tasks')
    .factory('TasksService', TasksService);
})();
