(function() {
  'use strict';

  var orderMap = {
    dueDate: 'eventAndDueDate',
    createdDateDesc: 'eventAndCreatedDate',
    createdDate: 'eventAndCreatedDateDesc'
  };

  var eventOrderMap = {
    dueDate: 'addedDate',
    createdDateDesc: 'addedDate',
    createdDate: 'addedDateDesc'
  };

  function GoalSetsViewWidgetController(
    $q,
    config,
    EventTypes,
    EventsStore,
    Goal,
    GoalsStore,
    Users,
    Network,
    Auth
  ) {
    var ctrl = this;

    ctrl.config = config;

    ctrl.events = [];
    ctrl.loading = true;
    ctrl.user = Users.remoteUser || Auth.currentUser();
    ctrl.isRemoteUser = ctrl.user !== Auth.currentUser();

    var DEFAULT_LIMIT = 8;
    // Set the display limit
    if (_.isUndefined(ctrl.config.limit)) {
      ctrl.displayLimit = DEFAULT_LIMIT;
    } else if (ctrl.config.limit === 0) {
      ctrl.displayLimit = undefined;
    } else {
      ctrl.displayLimit = ctrl.config.limit;
    }

    var fetchGoalsOnline = function() {
      var eventSearch = {
        size: ctrl.displayLimit
      };
      var eventFetchPromise;

      var state;
      if (config.byState && _.indexOf(config.byState, '__all__') === -1) {
        state = config.byState;
        eventSearch.state = config.byState === 'open' ? 'inProgress' : undefined;
      }

      if (ctrl.isRemoteUser) {
        eventSearch.user = ctrl.user;
      }

      eventSearch.sort_on = eventOrderMap[config.orderBy];

      if (config.byEventTypeVersion && _.indexOf(config.byEventTypeVersion, '__all__') === -1) {
        eventSearch.eventType_versionGroupId = config.byEventTypeVersion;
        eventFetchPromise = EventsStore.search(eventSearch, { own: !ctrl.isRemoteUser });
      } else {
        eventFetchPromise = $q.when(undefined);
      }


      return eventFetchPromise
        .then(function(ids) {
          if (ids && ids.length === 0) {
            return [];
          }
          return GoalsStore.fetch({
            user: Users.remoteUser,
            eventId: ids,
            goalset_state: state,
            sort_on: orderMap[config.orderBy],
            size: 1000
          }, { own: !ctrl.isRemoteUser });
        })

        .then(function(goals) {
          return $q.all(_.map(goals, function(item) {
            return Goal.init(item.doc, { light: item });
          }));
        });
    };

    var fetchGoalsOffline = function() {
      if (_.isUndefined(config.byEventTypeVersion)) {
        return [];
      }
      var eventTypesIdsProm = $q.when(true);
      if (_.indexOf(config.byEventTypeVersion, '__all__') === -1) {
        eventTypesIdsProm = EventTypes.getGroupsByIds(config.byEventTypeVersion)
          .then(function(allVersions) {
            return _.chain(allVersions)
              .map(function(version) {
                return version.linkedVersions;
              })
              .reduce(function(next, prev) {
                return prev.concat(next);
              })
              .map(function(eventType) {
                return eventType._id;
              })
              .value();
          });
      }

      return eventTypesIdsProm
        .then(function(eventTypesIds) {
          var options = {};
          if (Users.remoteUser) {
            options.username = Users.remoteUser;
          }

          // filter
          return Goal.findAll(options)
            .then(function(goals) {
              if (_.indexOf(config.byEventTypeVersion, '__all__') === -1) {
                goals = _.filter(goals, function(goal) {
                  if (_.isEmpty(goal.event)) {
                    return false;
                  }

                  return _.indexOf(eventTypesIds, goal.event.doc.eventType) > -1;
                });
              }

              if (config.byState === '__all__') {
                return goals;
              }

              return _.filter(goals, function(goal) {
                if (config.byState === 'open') {
                  return !goal.isSetClosed();
                }
                return goal.isSetClosed();
              });
            });
        })
        .then(function(goals) {
          var iteratees = [angular.copy(config.orderBy)],
              ordering = ['asc'];

          if (config.orderBy === 'createdDateDesc') {
            ordering = ['desc'];
            iteratees = ['createdDate'];
          }

          var eventIds = _.chain(goals)
            .map(function(goal) {
              var createdDate = '';
              if (!_.isUndefined(goal.doc.createdDate)) {
                createdDate = goal.doc.createdDate;
              } else if (!_.isUndefined(goal.doc.dates) && goal.doc.dates.length) {
                var temp = _.filter(goal.doc.dates, function(date) {
                  return date.action === 'created';
                });
                if (temp.length) {
                  createdDate = temp[0].date;
                }
              }

              return {
                eventId: goal.doc.eventId,
                dueDate: goal.doc.dueDate,
                createdDate: createdDate
              };
            })
            .unique('eventId')
            .sortByOrder(iteratees, ordering)
            .take(config.limit)
            .map(function(obj) {
              return obj.eventId;
            })
            .value();

          return _.filter(goals, function(goal) {
            return _.indexOf(eventIds, goal.doc.eventId) > -1;
          });
        });
    };

    var fetchGoals = function() {
      if (Network.isOffline()) {
        return fetchGoalsOffline();
      }

      return fetchGoalsOnline();
    };


    if (!_.isUndefined(config.byEventTypeVersion)) {
      fetchGoals()
        .then(function(goals) {
          ctrl.goals = goals;

          ctrl.options = {
            showSearch: false,
            showTargets: false,
            showGoalSetName: false,
            defaultListOrder: orderMap[config.orderBy]
          };

          ctrl.loading = false;
        });
    } else {
      ctrl.loading = false;
    }
  }

  GoalSetsViewWidgetController.$inject = [
    '$q',
    'config',
    'EventTypesService',
    'EventsStore',
    'GoalFactory',
    'GoalsStore',
    'UsersService',
    'NetworkService',
    'AuthService'
  ];

  function GoalSetsEditWidgetController(
    config,
    Form,
    EventTypesStore
  ) {
    var ctrl = this;

    ctrl.config = config;

    ctrl.queryForm = new Form([
      {
        id: 'byEventTypeVersion',
        type: 'discrete_multiple',
        label: 'Filter by Event Types',
        required: true,
        options: EventTypesStore.fetch(
          {
            containsGoal: 'yes',
            size: 1000
          }, {
            transformType: 'stub'
          }
        )
          .then(function(versions) {
            var allOption = [{ _id: '__all__', key: '_all__', name: 'All Goals Types' }];
            var options = _.chain(versions)
              .map(function(version) {
                return {
                  _id: version.versionGroupId,
                  key: version.versionGroupId,
                  name: version.name
                };
              })
              .sortBy('name')
              .value();

            return allOption.concat(options);
          })
      },
      {
        id: 'byState',
        type: 'discrete',
        label: 'Filter by State',
        required: true,
        options: [
          { _id: '__all__', key: '_all__', name: 'All States' },
          { _id: 'open', key: 'open', name: 'Open' },
          { _id: 'closed', key: 'closed', name: 'Closed' }
        ]
      },
      {
        id: 'limit',
        type: 'numeric',
        label: 'Number of goalsets to display',
        required: true
      },
      {
        id: 'orderBy',
        type: 'discrete',
        label: 'Ordered by',
        required: true,
        options: [
          {
            _id: 'dueDate',
            key: 'dueDate',
            name: 'Due Date'
          },
          {
            _id: 'createdDateDesc',
            key: 'createdDateDesc',
            name: 'Created date (most recent first)'
          },
          {
            _id: 'createdDate',
            key: 'createdDate',
            name: 'Created date (oldest first)'
          }
        ]
      }
    ]);
  }

  GoalSetsEditWidgetController.$inject = [
    'config',
    'FormsService',
    'EventTypesStore'
  ];

  function config(dashboardProvider) {
    dashboardProvider.widget('goalSets', {
      title: 'List of goal sets filtered',
      description: 'Displays list of goal sets filtered',
      controller: 'GoalSetsViewWidgetController',
      controllerAs: 'goalSetsViewWidgetCtrl',
      templateUrl: 'app/components/goals/widgets/goalsets/view.html',
      reload: true,
      edit: {
        templateUrl: 'app/components/goals/widgets/goalsets/edit.html',
        controller: 'GoalSetsEditWidgetController',
        controllerAs: 'goalSetsEditWidgetCtrl'
      },
      category: 'Goals'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('component.goals')
    .config(config)
    .controller('GoalSetsViewWidgetController', GoalSetsViewWidgetController)
    .controller('GoalSetsEditWidgetController', GoalSetsEditWidgetController);
})();
