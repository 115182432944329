(function() {
  'use strict';

  function InviteFormController($q, $timeout, $scope, Form, Roles, Utils, Network, Notify) {
    var ctrl = this;

    ctrl.form = new Form();
    ctrl.source = $scope.source;
    ctrl.formOptions = ($scope.options || {}).formData || {};
    var isMultiSource = $scope.def.multiSource;
    var filledBy = $scope.def.filledBy;

    var DEFAULT_HELP = 'After typing at least 3 characters, we will suggest matches from known ' +
        'users in Kaizen. You may enter a user\'s name or email address';

    var getHelpText = function(allowExternal, maxInvites, isOffline, allowedDomains) {
      var defaultHelp = isOffline ? 'You may enter a user\'s email address' : DEFAULT_HELP;
      var domainHelp = '';
      if (!_.isEmpty(allowedDomains)) {
        domainHelp += '<br />Invitations to people without a Kaizen account may only be sent to ' +
                       'the following domains: ' + allowedDomains.join(', ');
      }
      var count = maxInvites && maxInvites > 1 ? ' a maximum of ' + maxInvites : '';
      var helpTexts = {
        default: defaultHelp,
        canInvite: 'You can only invite' + count + ' users with a Kaizen account ' +
          'to fill in the next section. ' + defaultHelp,
        canInviteExternalUsers:
          'You can invite users with or without a Kaizen account to fill in the next section. ' +
          'After typing at least 3 characters, we will suggest matches from known users in ' +
          'Kaizen. You may enter a user\'s name or email address.' + domainHelp
      };

      return allowExternal ? helpTexts.canInviteExternalUsers : helpTexts.canInvite;
    };

    function loadCurrent() {
      // Load all selected
      var invites = Utils.toArray($scope.model.invites);

      var responsible = Utils.toArray($scope.model.responsible);
      invites = invites.concat(responsible);

      return $q.all(_.map(invites, function(item) {
        return ctrl.source({ filledBy: filledBy, search: item });
      }))
      .then(function(result) {
        var res = _.reduce(result, function(total, itm) {
          return total.concat(itm);
        }, []);
        return res;
      })
      .then(function(data) {
        $scope.model.responsible = [];
        if (data.length === 0) {
          return [];
        }

        // Set the defaults
        if (!isMultiSource) {
          $scope.model.invites = [data[0].email];
        } else {
          $scope.model.invites = _.map(data, 'email');
        }

        return data;
      });
    }


    function showForm(externalInviteInfo) {
      var allowExternal = externalInviteInfo.canInvite;
      ctrl.form = new Form();
      if ($scope.def.filledBy.length === 1 && $scope.def.filledBy[0] === 'system:timeline-owner') {
        return;
      }

      if (isMultiSource) {
        ctrl.form.addField({
          id: 'invites',
          type: isMultiSource ? 'email_multiple' : 'email_select',
          label: ctrl.formOptions.label,
          required: !isMultiSource,
          placeholder: 'Start typing to search',
          helpTextHtml: getHelpText(
            allowExternal,
            $scope.def.multiSourceMax,
            Network.isOffline(),
            externalInviteInfo.allowedDomains
          ),
          controller: ['$scope', function($scope) {
            $scope.allowedDomains = externalInviteInfo.allowedDomains;
            $scope.reload = function(search) {
              if (search.length < 3) {
                $scope.options.templateOptions.options = ctrl.loadedAll || [];
                return;
              }

              ctrl.source({ filledBy: filledBy, search: search })
                .then(function(data) {
                  var usernames = $scope.model.responsible || [];
                  var filtered = data.filter(function(obj) {
                    return usernames.indexOf(obj._id) === -1;
                  });
                  $scope.options.templateOptions.options = filtered;
                });
            };

            $scope.taggingFunc = function(val) {
              if (!Network.isOffline() && !allowExternal) {
                return;
              }

              if (!Utils.validateEmail(val)) {
                return;
              }

              if (!Utils.validateEmail(val, externalInviteInfo.allowedDomains)) {
                Notify.warning('An email is not from an allowed domain');
                return;
              }

              return {
                _id: val,
                // fullname: val,
                name: val,
                email: val
              };
            };
          }],
          ngModelAttrs: {
            limit: {
              bound: 'ng-limit',
              attribute: 'limit'
            },
            closeOnSelect: {
              bound: 'ng-close-on-select',
              attribute: 'close-on-select'
            }
          },
          ngModelAttrsValues: [
            {
              name: 'limit',
              value: !isMultiSource ? '1' : null
            },
            {
              name: 'closeOnSelect',
              value: !isMultiSource
            }
          ],
          options: $q.all([
            $scope.source({ filledBy: $scope.def.filledBy })
              .then(function(data) {
                if (!isMultiSource && data.length === 1) {
                  $scope.model.invites = data[0].email;
                }

                // This is an ugly way to say that we have all of them
                // as we have pagination at 10
                if (data.length < 9) {
                  ctrl.loadedAll = data;
                  return data;
                }

                return [];
              }),
            loadCurrent()
          ])
            .then(function(result) {
              return _.uniqBy(result[0].concat(result[1]), 'email');
            })
        });
      } else {
        ctrl.form.addField({
          name: $scope.def._id + ':invites',
          id: 'invites',
          type: 'typeahead',
          label: ctrl.formOptions.label,
          inputType: 'text',
          allowCustom: allowExternal || Network.isOffline(),
          tpOptions: {
            minLength: 3,
            autoselect: true
          },
          required: true,
          placeholder: 'Start typing to search',
          helpTextHtml: getHelpText(
             allowExternal, 1, Network.isOffline(), externalInviteInfo.allowedDomains
          ),
          controller: ['$scope', function($scope) {
            var promise;
            $scope.allowedDomains = externalInviteInfo.allowedDomains;

            // Lets try to find a user when they click away
            // This is so that we need to bypass domain validation in
            // case it is an already existing user
            $scope.$on('typeahead:change', function(_evt, emails) {
              var email = emails.length === 1 ? emails[0] : null;
              if (!email) {
                return;
              }

              if (!Utils.validateEmail(email)) {
                return;
              }

              ctrl.source({ filledBy: filledBy, search: email })
                .then(function(res) {
                  if (res.length === 1 && res[0].email === email) {
                    $scope.innerModel = res[0];
                  }
                })
                .catch(function(err) {
                  console.log('Could not find user', err);
                });
            });
            if (_.isEmpty($scope.model[$scope.options.key])) {
              promise = ctrl.source({ filledBy: filledBy })
                .then(function(data) {
                  return data.length === 1 ? data : [];
                });
            } else {
              promise = loadCurrent();
            }
            promise
              .catch(function() {
                return [];
              })
              .then(function(data) {
                if (data.length === 0) {
                  data = $scope.model[$scope.options.key] || [];
                }
                $scope.innerModel = data.length > 0 ? data[0] : undefined;
              })
              .finally(function() {
                $scope.$watch('innerModel', function(value) {
                  if (_.isEmpty(value)) {
                    $scope.model[$scope.options.key] = [];
                    return;
                  }
                  $scope.model[$scope.options.key] = _.isObject(value) ? [value.email] : [value];
                }, true);
              });
            $scope.datasets = [{
              displayKey: 'name',
              name: 'my-set',
              templates: {
                empty: [
                  '<div class="tt-suggestion tt-empty-message">',
                  'No results were found ...',
                  '</div>'
                ].join('\n')
              },
              source: function(search, _sync, process) {
                ctrl.source({ filledBy: filledBy, search: search })
                  .then(function(data) {
                    // var usernames = $scope.model.responsible || [];
                    // var filtered = data.filter(function(obj) {
                    //   return usernames.indexOf(obj._id) === -1;
                    // });
                    process(data);
                  });
              }
            }];
          }],
          validators: {
            // This has been moved to own directive inviteValidator
            // email: {
            //   // This overrides default email validation
            //   expression: function(value) {
            //     return false;
            //     if (_.isEmpty(value)) {
            //       console.log(value);
            //       return false;
            //     }

            //     if (_.isObject(value)) {
            //       console.log(value);
            //       return true;
            //     }

            //     var res = Utils.validateEmail(value);
            //     console.log(value, res);
            //     return res;
            //   },
            //   message: '$viewValue + " is not a valid email"'
            // }
          }
        });
      }
    }

    ctrl.filledOnChanged = function(value) {
      $scope.$emit('kzFilledOnChanged', { value: value });
    };

    function getExternalInviteInfo() {
      return Roles.findByIds(filledBy)
        .then(function(roles) {
          // check if at least one of the next responsible roles can be invited.
          var invitable = _.filter(roles, function(role) {
            return role.doc.invitable;
          });

          var canInvite = invitable.length > 0;
          var allowedDomains = _.map(invitable, 'doc.invitableDomains');
          var globalAllow = false;
          var domains = [];
          _.forEach(allowedDomains, function(dom) {
            if (globalAllow || !dom) {
              globalAllow = true;
              return;
            }
            domains = domains.concat(dom);
          });
          if (globalAllow) {
            domains = null;
          }

          return {
            canInvite: canInvite,
            allowedDomains: domains
          };
        })
        .catch(function(err) {
          console.log(err);
          return {
            canInvite: false,
            allowedDomains: null
          };
        });
    }

    function setup() {
      isMultiSource = $scope.def.multiSource;
      ctrl.source = $scope.source;
      filledBy = $scope.def.filledBy;
      if (_.indexOf(filledBy, 'system:timeline-owner') > -1) {
        delete $scope.model.invites;
      }

      ctrl.show = false;

      getExternalInviteInfo()
        .then(function(inviteInfo) {
          showForm(inviteInfo);
        })
        .then(function() {
          if (!$scope.def.multiSource && $scope.allowFill) {
            ctrl.form.addField({
              id: 'filledOnSameDevice',
              type: 'boolean',
              label: 'Fill in on the same device',
              onChange: ctrl.filledOnChanged
            });
          }
        })
        .finally(function() {
          ctrl.show = true;
        });
    }

    setup();
    $scope.$on('KZNextSectionChanged', function(_evt, args) {
      if (args.idx !== $scope.idx) {
        console.log('Skipping', args.idx, $scope.idx);
        return;
      }
      console.log('Setting up', args.idx, $scope.idx);
      $timeout(function() {
        setup();
      });
    });
  }

  InviteFormController.$inject = [
    '$q',
    '$timeout',
    '$scope',
    'FormsService',
    'RolesService',
    'UtilsService',
    'NetworkService',
    'NotifyService'
  ];

  function InviteFormDirective() {
    return {
      scope: {
        model: '=',
        def: '=',
        source: '&',
        allowFill: '=',
        options: '=?',
        form: '=?',
        label: '@',
        idx: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/invite.form.html',
      controller: InviteFormController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('inviteForm', InviteFormDirective)
    .controller('InviteFormController', InviteFormController);
})();
