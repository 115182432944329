(function() {
  'use strict';

  function SlotController($scope, $state, Security) {
    var ctrl = this;

    // this may be also used for the details view, move it if necessary
    ctrl.actionButtons = [
      // {
      //   label: 'Edit',
      //   icon: 'icon-edit',
      //   href: (function() {
      //     return $state.href('epf.event-types.edit', { id: $scope.item.doc._id });
      //   })(),
      //   showCondition: function() {
      //     if ($scope.item.doc.state !== 'draft') {
      //       return false;
      //     }

      //     return Security.hasPermission.bind(this, 'eventTypes.edit');
      //   },
      //   klass: 'text-info'
      // },
      // {
      //   label: 'View',
      //   icon: 'icon-view',
      //   href: (function() {
      //     return $state.href('epf.event-types.edit', { id: $scope.item.doc._id });
      //   })(),
      //   showCondition: function() {
      //     if ($scope.item.doc.state === 'draft') {
      //       return false;
      //     }

      //     return Security.hasPermission.bind(this, 'eventTypes.edit');
      //   },
      //   klass: 'text-info'
      // },
      {
        label: 'Preview',
        icon: 'icon-edit',
        href: (function() {
          return $state.href('epf.event-types.preview', { id: $scope.item.doc._id });
        })(),
        showCondition: Security.hasPermission.bind(this, 'eventTypes.view'),
        klass: 'text-info'
      }
    ];

    ctrl.secondaryActionButtons = [
    ];

    ctrl.headVersion = _.last($scope.item.linkedVersions);
    var published = _.find($scope.item.linkedVersions, { state: 'published' });
    if (published !== ctrl.headVersion) {
      ctrl.published = published;
    }
  }

  SlotController.$inject = ['$scope', '$state', 'SecurityService'];

  function SlotDirective() {
    return {
      scope: {
        item: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/event-types/event-type.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('eventTypeSlot', SlotDirective)
    .controller('eventTypeSlotController', SlotController);
})();
