(function() {
  'use strict';

  function OfflineSettingsController($q, $scope, $state, Profile, Users, Auth, Notify, Form,
    UserStore, ConfStore, VersionStore, Cache, Setup, Status, Utils, PACKAGE_VERSIONS) {
    var ctrl = this;

    Utils.setPageTitle('Offline settings');

    ctrl.troubleExpanded = $state.params.showAdvanced;
    ctrl.versions = {
      // kaizen: '1.3.7',
      frontend: PACKAGE_VERSIONS.frontend,
      buildAt: PACKAGE_VERSIONS.buildAt
    };
    ctrl.stores = [UserStore, ConfStore, VersionStore];

    var storageOptions = [
      {
        _id: 'none',
        key: 'none',
        name: 'No data stored in the browser',
        class: 'text-danger',
        description: 'Without data stored on the device Kaizen will not be available offline. ' +
                     'This is the most secure version but may negatively affect performance.' +
                     'Recommended for use on other people\'s devices or if the device does not ' +
                     'support local storage'
      },

      {
        _id: 'session',
        key: 'session',
        name: 'Data temporarily stored in the browser',
        class: 'text-warning',
        description: 'Your data are stored in the browser but will be removed upon logout. ' +
                     'This is recommended for shared devices but does not allow you to use ' +
                     'Kaizen in offline mode (no network connection)'
      },
      {
        _id: 'persistent',
        key: 'persistent',
        name: 'Data persistently stored in the browser',
        class: 'text-success',
        description: 'Your data are stored in the browser and ready for offline use'
      }
    ];

    function getPin() {
      return Profile.find({ cached: false })
        .then(function(doc) {
          return doc.pin;
        });
    }

    function getPinForm() {
      var pinFields = [];
      pinFields.push({
        id: 'pin',
        type: 'password',
        label: 'Enter new PIN',
        required: true
      });

      pinFields.push({
        id: 'pinSame',
        type: 'password',
        label: 'Re-type new PIN',
        required: true
      });

      return new Form(pinFields);
    }

    function updatePin(pin, form) {
      return Profile.find({ cached: false })
        .then(function(doc) {
          return Users.updatePin(doc, pin);
        })
        .then(function() {
          if (pin === undefined) {
            Notify.success('PIN has been successfully cleared');
          } else {
            Notify.success('PIN has been successfully set');
          }
          ctrl.pinData = {};
          ctrl.pinFormError = '';
          form.$setPristine();
          form.$setUntouched();
          ctrl.showPinForm = false;
          // Make sure we have loaded the modified profile
          return Profile.find({ cached: false });
        })
        .then(function(doc) {
          ctrl.pin = doc.pin;
          return Status.setOfflineCapability();
        })
        .catch(function(error) {
          Notify.error(error.message || 'Unknown error');
          ctrl.pinFormError = error.message;
        });
    }

    function setupPin() {
      return getPin()
        .then(function(pin) {
          ctrl.pin = pin;
        });
    }

    function setupStorageMode() {
      ctrl.noIDBSupport = Auth.noIDBSupport;
      return Auth.getStorageMode()
        .then(function(mode) {
          ctrl.storage = _.find(storageOptions, { _id: mode });
        });
    }

    function getStorageForm() {
      return new Form([{
        id: 'storageMode',
        type: 'discrete',
        required: true,
        label: 'Data storage mode',
        options: storageOptions
      }]);
    }

    function setup() {
      ctrl.loaded = false;
      return $q.all([setupPin(), setupStorageMode()])
        .catch(function(err) {
          Notify.error('Could not retrieve all data');
          console.log(err);
        })
        .finally(function() {
          ctrl.loaded = true;
        });
    }

    setup();

    ctrl.setupPinForm = function() {
      ctrl.showPinForm = true;
      ctrl.pinData = {};
      ctrl.pinForm = getPinForm();
    };

    ctrl.hidePinForm = function() {
      ctrl.showPinForm = false;
      ctrl.pinData = {};
    };

    ctrl.updatePin = function(form) {
      if (ctrl.pinData.pin !== ctrl.pinData.pinSame) {
        Notify.error('PINs are not same. Please try again.');
        return;
      }

      if (form.$invalid) {
        Notify.error('Form is not valid');
        return;
      }

      return updatePin(ctrl.pinData.pin, form);
    };

    ctrl.unsetPin = function(form) {
      return updatePin(undefined, form);
    };

    ctrl.setupStorageForm = function() {
      ctrl.showStorageForm = true;
      ctrl.storageData = { storageMode: ctrl.storage._id };
      ctrl.storageForm = getStorageForm();
    };

    ctrl.hideStorageForm = function() {
      ctrl.showStorageForm = false;
      ctrl.storageData = {};
    };

    ctrl.updateStorageMode = function(form) {
      if (Auth.noIDBSupport) {
        Notify.error('There is no support for local storage on this device.');
        return $q.reject();
      }

      return Auth.setStorageMode(ctrl.storageData.storageMode)
        .then(function() {
          setupStorageMode();
          form.$setPristine();
          form.$setUntouched();
          ctrl.showStorageForm = false;
        });
    };

    ctrl.inspectStores = function() {
      var promises = _.map(ctrl.stores, function(store) {
        var promise;
        promise = store.getStored();
        return promise;
      });

      return $q.all(promises)
        .then(function(data) {
          var stores = [];
          _.forEach(data, function(data) {
            stores.push({
              id: data.storeId,
              size: _.reduce(data.stores, function(result, item) {
                return result + item.size;
              }, 0)
            });
          });

          ctrl.itemCount = _.reduce(stores, function(result, item) {
            return result + item.size;
          }, 0);
          ctrl.storesError = undefined;
        })
        .catch(function(err) {
          ctrl.storesError = err;
        })
        .finally(function() {
          ctrl.storesLoaded = true;
        });
    };

    ctrl.updateAll = function() {
      return $q.all(_.map(ctrl.stores, function(store) {
        return store.updateTypes()
          .then(function() {
            return store.flushStores();
          });
      }))
      .then(function() {
        return ctrl.inspectStores();
      })
      .then(function() {
        Notify.success('Local data updated');
        ctrl.storesError = undefined;
      })
      .catch(function(err) {
        ctrl.storesError = err;
      });
    };

    ctrl.clearAll = function() {
      return Cache.removeLocalData()
        .then(function() {
          _.forEach(ctrl.stores, function(store) {
            store.stores = {};
          });
          return ctrl.inspectStores();
        })
        .then(function() {
          ctrl.storesError = undefined;
          Notify.success('Local data removed');
        })
        .catch(function(err) {
          ctrl.storesError = err;
        });
    };

    ctrl.clearCache = function() {
      var promise = Setup.cleanUpData();
      Auth.removeLocalSettings();

      // unregister any service worker
      if ('serviceWorker' in navigator) {
        promise = promise
          .then(function() {
            return navigator.serviceWorker.getRegistrations();
          })
          .then(function(registrations) {
            return $q.all(registrations.map(function(registration) {
              return registration.unregister();
            }));
          })
          .catch(function() {
            return;
          });
      }

      return promise
        .then(function() {
          window.location.reload();
        });
    };

    ctrl.inspectStores();

    $scope.$on('KZStatusInfoUpdated', function(_evt, info) {
      ctrl.info = info;
    });

    $scope.$on('KZLocalSettingsChanged', function(_evt) {
      setup();
    });
  }

  OfflineSettingsController.$inject = [
    '$q',
    '$scope',
    '$state',
    'ProfileService',
    'UsersService',
    'AuthService',
    'NotifyService',
    'FormsService',
    'BaseUserStoreService',
    'BaseConfStoreService',
    'BaseVersionStoreService',
    'CacheService',
    'SetupService',
    'StatusService',
    'UtilsService',
    'PACKAGE_VERSIONS'
  ];

  angular.module('component.settings')
    .controller('OfflineSettingsController', OfflineSettingsController);
})();
