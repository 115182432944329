(function() {
  'use strict';

  function kzSearchApplied($q, $filter, LocalizationService) {
    return {
      restrict: 'AE',
      templateUrl: 'app/blocks/widgets/search/kz-search-applied.html',
      link: function(scope) {
        function loadFilters() {
          var filters = [];
          _.forOwn(scope.list.searchModel.filteredBy, function(value, key) {
            filters.push({
              key: key,
              value: value
            });
          });
          var result = _.chain(filters)
            .filter(function(item) {
              return !_.isEmpty(item.value);
            })
            .map(function(item) {
              var facet = _.find(scope.list.search.facetly.facets, { id: item.key });
              item.label = _.isUndefined(facet) ? item.key : facet.label;

              if (_.isUndefined(facet)) {
                return item;
              }

              if (facet.type === 'date') {
                var dateFormat = LocalizationService.getDateTimeFormat('dateonly');
                item.value = $filter('date')(item.value, dateFormat);
                item.titled = true;
              }

              if (facet.type === 'text') {
                item.titled = true;
              }

              var promOptions = $q.when(facet.options);
              if (!_.isUndefined(facet._options)) {
                promOptions = $q.when(facet._options);
              } else if (!_.isUndefined(facet.options) && angular.isFunction(facet.options.then)) {
                // check that's a promise
                promOptions = facet.options;
              }

              return promOptions
                .then(function(options) {
                  if (_.isArray(options)) {
                    if (!_.isArray(item.value)) {
                      item.value = [item.value];
                    }

                    item.value = _.map(item.value, function(itm) {
                      var fnd = _.find(options, { id: itm });
                      return _.isUndefined(fnd) ? itm : fnd.title;
                    });
                    item.titled = true;
                  }

                  return item;
                });
            })
            .value();
          return $q.all(result);
        }

        scope.$watch('list.searchModel.filteredBy', function() {
          loadFilters()
            .then(function(filters) {
              scope.filters = filters;
            });
        }, true);
      }
    };
  }

  kzSearchApplied.$inject = ['$q', '$filter', 'LocalizationService'];

  angular.module('blocks.widgets')
    .directive('kzSearchApplied', kzSearchApplied);
})();
