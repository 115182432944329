(function() {
  'use strict';

  function TargetLightFactory(
    $log
  ) {
    var Target = function(doc, periods) {
      this.id = doc._id;
      this.doc = doc;
      this.periods = periods;
      this.cachedPeriods = doc.cachedPeriods;
      if (_.isUndefined(this.cachedPeriods) || _.isEmpty(this.cachedPeriods)) {
        this.cachedPeriods = {
          generatedBy: 'frontend',
          periods: [],
          global: {
            linkedEvents: [],
            countable: []
          }
        };
      }

      // this is to know if we can show the "Others" period or not.
      var _this = this;
      this.hasNoPeriod = _.some(this.periods, function(period) {
        return _this.hasPeriod(period._id) === false;
      });
    };

    Target.prototype.init = function() {
      $log.debug('Initiating target', this.id);
    };

    Target.prototype.getPeriodMeasurements = function() {
      if (!this.doc.conditions || this.doc.conditions.length === 0) {
        return [];
      }
      return this.doc.conditions[0].periodMeasurements || [];
    };

    Target.prototype.getPeriodById = function(periodId) {
      var periodMeasurements = this.getPeriodMeasurements();
      return _.find(periodMeasurements || [], function(count) {
        return count.period === periodId;
      });
    };

    Target.prototype.hasPeriod = function(period) {
      if (period === 'all') {
        return true;
      }

      var periodMeasurements = this.getPeriodMeasurements();
      if (periodMeasurements.length === 0) {
        return period === '__others__';
      }

      return _.find(periodMeasurements, { period: period });
    };

    Target.prototype.getDefaultCache = function() {
      return {
        linkedEvents: [],
        countable: []
      };
    };

    Target.prototype.getDetails = function(periodId) {
      return this.getPeriodDetails(periodId).details;
    };

    Target.prototype.getPeriodDetails = function(periodId) {
      periodId = periodId || this.periodId || '__global__';
      var cache = this.cachedPeriods;
      var details;
      var period;
      if (periodId === '__global__') {
        var condition = this.doc.conditions && this.doc.conditions[0];
        details = cache.global || {};
        if (condition !== undefined) {
          period = {
            filterMeasurements: condition.filterMeasurements,
            count: condition.count
          };
        } else {
          // in the case we are only counting links.
          period = {
            count: 0
          };
        }
      } else {
        details = _.find(cache.periods, { periodId: periodId });
        period = this.getPeriodById(periodId);
      }
      return {
        details: details || this.getDefaultCache(),
        period: period
      };
    };

    Target.prototype.calculateProgress = function(periodId, cache) {
      cache = cache === undefined ? this.cachedPeriods : cache;
      var periodDetails = this.getPeriodDetails(periodId, cache);
      return this.calculateProgressForPeriod(periodDetails.details, periodDetails.period);
    };

    Target.prototype.calculateProgressForPeriod = function(details, period) {
      if (
        _.isUndefined(details) || _.isEmpty(details) ||
        _.isUndefined(period) || _.isEmpty(period)
      ) {
        return {
          progress: 0,
          max: 0,
          ratio: 0
        };
      }

      var filterMeasurements = period.filterMeasurements;
      var progress;
      var max;
      if (!filterMeasurements) {
        progress = details.countable.length;
        max = period.count || 0;
      } else {
        progress = this.calculateProgressForMeasurements(filterMeasurements, details.countable);
        max = this.calculateMaxForMeasurements(filterMeasurements);
      }

      return {
        progress: progress,
        max: max,
        ratio: Math.min(max !== 0 ? progress / max : 0, 1),
        other: details.linkedEvents.length - details.countable.length
      };
    };

    Target.prototype.calculateProgressForMeasurements = function(
      filterMeasurements, countableLinkedEvents
    ) {
      var measurements = [];
      _.forEach(filterMeasurements, function(measurement) {
        var values = [];
        // Backward compatibility
        if (measurement.blueprintId) {
          measurement = angular.copy(measurement);
          measurement.blueprints = [measurement.blueprintId];
        }

        _.forEach(countableLinkedEvents, function(countableLinkedEvent) {
          var totalPerEvent = _.sumBy(_.map(measurement.blueprints || [], function(blId) {
            return (countableLinkedEvent.blueprintsInFields || {})[blId] || 0;
          }));
          values.push(totalPerEvent);
        });

        measurements.push({ aggr: measurement.aggr, values: values });
      });

      var values = [];
      _.forEach(measurements, function(item) {
        var count = item.values.length;

        var value = 0;
        if (item.aggr === 'count') {
          value = count;
        } else if (item.aggr === 'sum') {
          value = _.sum(item.values);
        } else if (item.aggr === 'min') {
          value = item.values.length > 0 ? _.min(item.values) : 0;
        } else if (item.aggr === 'max') {
          value = item.values.length > 0 ? _.max(item.values) : 0;
        } else if (item.aggr === 'avg' && count) {
          value = _.sum(item.values) / count;
        }

        values.push(value);
      });

      // do the average
      if (values.length === 0) {
        return 0;
      }
      return _.sum(values);
    };

    Target.prototype.calculateMaxForMeasurements = function(filterMeasurements) {
      if (filterMeasurements.length === 0) {
        return 0;
      }

      return _.sumBy(filterMeasurements, 'count') / filterMeasurements.length;
    };

    Target.prototype.getLinkingFilters = function() {
      if (_.isUndefined(this.doc.conditions) || _.isEmpty(this.doc.conditions)) {
        return [];
      }

      return this.doc.conditions[0].filters;
    };

    /**
     * Return relevant event types that can satisfy this target
     *
     * @return {arrau} Array of event types ids
     */
    Target.prototype.getRelevantEventTypes = function() {
      var filters = _.filter(this.getLinkingFilters(), { dataType: 'eventType_versionGroupId' });

      if (!_.isArray(filters)) {
        return [];
      }

      return _.chain(filters)
        .map(function(et) {
          return et.value;
        })
        .reduce(function(sum, item) {
          return sum.concat(item);
        }, [])
        .value();
    };

    return Target;
  }

  TargetLightFactory.$inject = [
    '$log'
  ];

  angular.module('component.goals')
    .factory('TargetLightFactory', TargetLightFactory);
})();
