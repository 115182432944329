(function() {
  'use strict';

  function Search($q, Roles, EventTypes, USER_BULK_ACTIONS) {
    function getTitlesFromRoles(item) {
      return $q.all(_.map(item.doc.data.roles, function(role) {
        return Roles.find(role, { cached: true });
      }))
      .then(function(res) {
        return _.reduce(res, function(titles, role) {
          titles += ' ' + role.title;
          return titles;
        }, '');
      });
    }

    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              label: 'Start typing to search'
            },
            {
              id: 'state',
              type: 'select',
              label: 'State',
              multiselect: false,
              options: [
                { id: 'new', title: 'Queuing' },
                { id: 'progress', title: 'In progress' },
                { id: 'success', title: 'Success' },
                { id: 'failure', title: 'Failed' }
              ],
              facetly: false
            }
          ],
          options: {
            defaultFacet: 'text',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'text',
            matchFunc: function(item, _key, value) {
              var action = _.find(USER_BULK_ACTIONS, { id: item.doc.action });
              var txt = '';
              if (!_.isEmpty(action)) {
                txt += action.title + ' ';
              }

              var promise;
              if (_.isUndefined(action)) {
                promise = $q.when('');
              } else if (action.id === 'AddUserRoles') {
                promise = getTitlesFromRoles(item);
              } else if (action.id === 'RemoveUserRoles') {
                promise = getTitlesFromRoles(item);
              } else if (action.id === 'AddEvent') {
                promise = EventTypes.find(item.doc.data.event.eventType, { useCache: true })
                  .then(function(et) {
                    return et.name;
                  });
              } else {
                promise = $q.when('');
              }

              promise = promise.then(function(title) {
                txt += title + ' ';
                console.log(txt.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                return txt.toLowerCase().indexOf(value.toLowerCase()) !== -1;
              });

              return promise;
            }
          },
          {
            id: 'state'
          }
        ],
        orders: {
          createdDate: function(item) {
            if (item.doc.createdDate) {
              return -(new Date(item.doc.createdDate).getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          }
        },
        orderGroups: {
          date: {
            title: 'date',
            orders: ['createdDate']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'date'
      };
      return search;
    };

    var service = {
      search: getSearch()
    };

    return service;
  }

  Search.$inject = ['$q', 'RolesService', 'EventTypesService', 'USER_BULK_ACTIONS'];

  angular.module('component.tasks')
    .service('TaskSearch', Search);
})();
