(function() {
  'use strict';

  function SettingsService($q, $window, $log, Auth, Database, Profile, Utils, Cache,
                           LocalStore, LocalUsers) {
    var service = {};

    var randomString = function(length) {
      var text = '';
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    var rpt = 100;
    var dataSize = 256 * 1024; // 256KB
    var data = randomString(dataSize);

    // Return size in MB
    var getSize = function(count) {
      return Math.round((10 * (count * dataSize)) / (1024 * 1024)) / 10;
    };

    var createDb = function() {
      return Database.getDb('offlinetest', {}, { alwaysAllow: true });
    };

    var getInfo = function() {
      return createDb()
        .then(function(db) {
          return db.info();
        });
    };

    var clearDb = function() {
      return createDb()
        .then(function(db) {
          return db.destroy();
        })
        .then(function() {
          return createDb();
        });
    };

    // Test functions
    var testCreateDb = function() {
      return createDb()
        .then(function() {
          return { message: 'Database created successfully', state: 'success' };
        });
    };

    var testGetInfo = function() {
      return getInfo()
        .then(function(info) {
          return {
            message: 'Successfully retrieved db information using ' + info.adapter,
            state: 'success'
          };
        })
        .catch(function(err) {
          return {
            message: 'Could not retrieve info' + JSON.stringify(err),
            state: 'error'
          };
        });
    };

    var testClearDb = function() {
      return clearDb()
        .then(function() {
          return { message: 'Successfully cleared db', state: 'success' };
        });
    };

    var count = 0;
    var pushOne = function() {
      return createDb()
        .then(function(db) {
          return db.put({ _id: Utils.guid(), data: data });
        })
        .then(function() {
          count += 1;
        });
    };

    var pushData = function() {
      count = 0;
      var promise = $q.when();
      for (var i = 0; i < rpt; i++) {
        promise = promise.then(pushOne);
      }
      return promise
        .catch(function() {})
        .then(function() {
          var pushed = getSize(count);
          var maxSize = getSize(rpt);
          var res = { message: 'Pushed ' + pushed + 'MB out of ' + maxSize + 'MB' };
          if (pushed < 10) {
            res.state = 'error';
          } else if (pushed < 20) {
            res.state = 'warning';
          } else {
            res.state = 'success';
          }
          return res;
        });
    };

    var appCache = function() {
      if ($window.offlineState !== undefined && $window.offlineState.state === 'ready') {
        return $q.when({
          message: 'This device has successfully installed Kaizen for offline use ('
                   + $window.offlineState.engine + ')'
        });
      }

      return $q.reject({
        message: 'This device is not capable of installing Kaizen for offline use',
        state: 'error'
      });
    };

    var testSettings = function() {
      return Auth.getStorageMode()
        .then(function(mode) {
          if (mode === 'persistent') {
            return { message: 'Storage is set correctly', state: 'success' };
          } else if (mode === 'session') {
            return {
              message: 'Storage is cleared upon logout',
              state: 'warning',
              button: {
                label: 'Change',
                sref: 'epf.settings.offlinesettings',
                class: 'btn-warning'
              }
            };
          } else if (mode === 'none') {
            return $q.reject({
              message: 'No local store is allowed',
              state: 'error',
              button: {
                label: 'Change',
                sref: 'epf.settings.offlinesettings',
                class: 'btn-danger'
              }
            });
          }

          return $q.reject({ message: 'Invalid mode ' + mode, state: 'error' });
        });
    };

    var testPin = function() {
      return Profile.find({ cached: false })
        .then(function(profile) {
          if (!_.isEmpty(profile.pin)) {
            return { message: 'PIN for offline log in is set' };
          }

          return $q.reject({
            message: 'No PIN set up. You will not be able to login while offline ',
            state: 'error',
            button: {
              label: 'Set',
              sref: 'epf.settings.offlinesettings',
              class: 'btn-danger'
            }
          });
        });
    };

    var testDB = function(db) {
      return db.info()
        .then(function(info) {
          if (Auth.DBAdapter === 'idb' && info.adapter !== 'idb') {
            return {
              message: 'Not using optimal adapter: ' + info.adapter,
              state: 'warning'
            };
          }
        });
    };

    var testCacheDB = function() {
      return Cache.idbCache({ alwaysAllow: true })
        .then(function(db) {
          return testDB(db);
        });
    };

    var testLocalData = function() {
      return LocalStore.idbStore({ alwaysAllow: true })
        .then(function(db) {
          return testDB(db);
        });
    };

    var testLocalUsers = function() {
      return LocalUsers.getDb({ alwaysAllow: true })
        .then(function(db) {
          return testDB(db);
        });
    };

    var testsTemplate = [
      { func: appCache, description: 'Testing app cache', catch: true },
      { func: testSettings, description: 'Test settings', catch: true },
      { func: testPin, description: 'Test PIN is set up', catch: true },
      {
        description: 'Test storage type',
        catch: true,
        tests: [
          { func: testCacheDB, description: 'Testing cache db adapter', catch: true },
          { func: testLocalData, description: 'Testing local db adapter', catch: true },
          { func: testLocalUsers, description: 'Testing user db adapter', catch: true }
        ],
        handleResult: function(result) {
          if (result && result.state !== 'success') {
            result.button = {
              label: 'Troubleshoot',
              sref: 'epf.settings.offlinesettings({ showAdvanced: true })',
              class: 'btn-danger'
            };
          }
          return result;
        }
      },
      {
        description: 'Testing local storage',
        catch: true,
        level: 2,
        tests: [
          { func: testCreateDb, description: 'Testing creating database' },
          { func: testGetInfo, description: 'Testing accessing db' },
          { func: pushData, description: 'Testing pushing data', level: 2 },
          { func: testClearDb, description: 'Testing clear up db' }
        ],
        handleResult: function(result) {
          if (result && result.state !== 'success') {
            result.button = {
              label: 'Troubleshoot',
              sref: 'epf.settings.offlinesettings({ showAdvanced: true })',
              class: 'btn-danger'
            };
          }
          return result;
        }
      }

    ];

    var testOne = function(test) {
      return test.func()
        .then(function(output) {
          if (_.isObject(output)) {
            test.result = output;
          } else {
            test.result = { message: 'OK' };
          }
          test.result.state = test.result.state || 'success';
        })
        .catch(function(err) {
          test.result = err || {};
          test.result.state = test.result.state || 'error';
          return $q.reject(err);
        });
    };

    var testList = function(tests, options) {
      options = options || {};
      var promise = $q.when();
      _.forEach(tests, function(test) {
        if (options.level !== undefined && (test.level || 1) > options.level) {
          test.result = { message: 'Skipped', state: 'skipped' };
          return;
        }

        if (test.tests !== undefined) {
          promise = promise
            .then(testList.bind(this, test.tests, options))
            .catch(function() {})
            .then(function() {
              var state = service.getWorst(test.tests);
              var mapping = {
                success: 'OK',
                warning: 'Limited but ok',
                error: 'Not OK'
              };

              test.result = { state: state, message: mapping[state] };
              if (_.isFunction(test.handleResult)) {
                test.result = test.handleResult(test.result);
              }
            });
        } else {
          promise = promise.then(testOne.bind(this, test));
        }

        if (test.catch) {
          promise = promise.catch(function(err) {
            return err;
          });
        }
      });
      return promise;
    };

    service.getTests = function() {
      return angular.copy(testsTemplate);
    };

    service.testAll = function(tests, options) {
      options = options || {};

      return testList(tests, options)
        .then(function() {
          $log.debug('Tests ran success fully');
        })
        .catch(function(err) {
          $log.error('Tests failed', err);
        })
        .then(function() {
          var state = service.getWorst(tests);
          var mapping = {
            success: 'This device is capable of working offline',
            warning: 'This device may be able to work offline',
            error: 'This device is not ready for offline use'
          };
          return { state: state, message: mapping[state] };
        })
        .finally(function() {
          // clearDb();
        });
    };

    service.getWorst = function(tests) {
      if (tests.length === 0) {
        return;
      }

      var mapping = {
        error: 1,
        warning: 2,
        success: 3
      };
      var states = _.map(tests, 'result.state');
      var ordered = _.sortBy(states, function(item) {
        return mapping[item] || 4;
      });
      return ordered[0];
    };

    service.getDbInfo = function() {
      return createDb()
        .then(function(db) {
          return db.info();
        });
    };

    return service;
  }

  SettingsService.$inject = [
    '$q',
    '$window',
    '$log',
    'AuthService',
    'DatabaseService',
    'ProfileService',
    'UtilsService',
    'CacheService',
    'LocalStoreService',
    'LocalUsersService'
  ];

  angular.module('component.settings')
    .service('SettingsService', SettingsService);
})();
