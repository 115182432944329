import { UserFieldsTemplates } from './components/user-fields/tplCache';
import { EventTypesTemplates } from './components/event-types/partials/tplCache';
import { GoalsTypesTemplates } from './components/goals/tplCache';
import { TasksTypesTemplates } from './components/tasks/tplCache';
import { BlueprintsTemplates } from './components/blueprints/tplCache';
import { RelationsTemplates } from './components/relations/tplCache';
import { RolesTemplates } from './components/roles/tplCache';
import { EventsTemplates } from './components/events/tplCache';
import { ReportsTemplates } from './components/reports/tplCache';

export const templates = {
  'app/layout/placeholders/list.html': require('./layout/placeholders/list.html').default,
  'versions-list.html': require('./blocks/versions/versions-list.html').default,
  'app/blocks/image/image.html': require('./blocks/image/image.html').default,
  'app/blocks/image/picture.html': require('./blocks/image/picture.html').default,
  'app/blocks/list/dotbuttons.html': require('./blocks/list/dotbuttons.html').default,
  'app/blocks/list/listbuttons.html': require('./blocks/list/listbuttons.html').default,
  'app/blocks/list/listview.html': require('./blocks/list/listview.html').default,
  'app/blocks/widgets/progress/progress-inline.html': require('./blocks/widgets/progress/progress-inline.html').default,
  'app/blocks/widgets/progress/progress.html': require('./blocks/widgets/progress/progress.html').default,
  'app/components/dashboard/partials/widget.html': require('./components/dashboard/partials/widget.html').default,
  'app/components/dashboard/partials/dashboard-title.html': require('./components/dashboard/partials/dashboard-title.html').default,
  'app/components/dashboard/partials/dashboard-title-buttons.html': require('./components/dashboard/partials/dashboard-title-buttons.html').default,
  'app/components/dashboard/partials/dashboard-edit.html': require('./components/dashboard/partials/dashboard-edit.html').default,
  'app/components/dashboard/partials/dashboard-addwidget.html': require('./components/dashboard/partials/dashboard-addwidget.html').default,
  'app/components/dashboard/partials/widget-title.html': require('./components/dashboard/partials/widget-title.html').default,
  'app/components/accounts/partials/organisation-select.html': require('./components/accounts/partials/organisation-select.html').default,
  'app/layout/templates/2-columns-left.html': require('./layout/templates/2-columns-left.html').default,
  ...UserFieldsTemplates,
  ...EventTypesTemplates,
  ...GoalsTypesTemplates,
  ...TasksTypesTemplates,
  ...BlueprintsTemplates,
  ...RelationsTemplates,
  ...GoalsTypesTemplates,
  ...RolesTemplates,
  ...EventsTemplates,
  ...ReportsTemplates,
};

/*
./blocks/list/listview.html:  <div data-ng-show="list.isLoading || !list.ready" data-ng-include="'app/layout/placeholders/list.html'"></div>
./blocks/widgets/kz-tree/kz-tree.js:$templateCache.put('node.html','<div>\n  <div class="kzt-checkbox" ng-if="options.selectable">\n    <label>\n      <input type="checkbox" value="node._id" ng-click="toggleSelected(node._id)" ng-checked="isSelected(node._id)">\n    </label>\n  </div>\n\n  <kz-tree-node-title key="node._id" title="node.name" has-children="node.categories.length" show-children="!showSelected && isExpanded(node._id)" icons="options.icons" on-expand="expand(node._id)"></kz-tree-node-title>\n\n  <span ng-show="(isSearching || showSelected) && node.path" class="kzt-node-path">\n    {{ node.path }}\n  </span>\n\n  <ul ng-if="node.categories.length && (isExpanded(node._id) || options.expanded)">\n    <li ng-repeat="node in node.categories track by node._id" ng-include="\'node.html\'"></li>\n  </ul>\n</div>\n');
./blocks/widgets/kz-tree/kz-tree.js:$templateCache.put('tree.html','<div class="kz-tree">\n\n  <kz-tree-toolbar\n    nodes="nodes"\n    selected="selected"\n    options="options"\n    on-change-selection="doChangeSelection(selected)"\n    on-toggle-selected="doToggleSelected(show)"\n    on-search="doSearch(query)">\n  </kz-tree-toolbar>\n\n  <ul class="kzt-list">\n    <li ng-if="!isSearching && !showSelected" ng-repeat="node in nodes track by node._id" ng-include="\'node.html\'"></li>\n    <li ng-if="isSearching && !showSelected" ng-repeat="node in searchResults track by node._id" ng-include="\'node.html\'"></li>\n    <li ng-if="showSelected" ng-repeat="node in selectedNodes track by node._id" ng-include="\'node.html\'"></li>\n  </ul>\n\n</div>');}]);

./components/tasks/task.slot.html:          <span ng-include="'app/components/tasks/partials/' + action.id + '.html'"></span>

./components/blueprints/form.html:      <div class="col-xs-12" data-ng-include="'app/components/blueprints/partials/settings.other.html'"></div>
./components/blueprints/form.html:        <div data-ng-include="'app/components/blueprints/form.discrete.html'" data-ng-if="blueprintCtrl.blueprint.blueprintType === 'discrete'"></div>
./components/blueprints/form.html:        <div data-ng-include="'app/components/blueprints/form.likert.html'" data-ng-if="blueprintCtrl.blueprint.blueprintType === 'likert'"></div>
./components/blueprints/list.map.blueprint.html:    <li data-ng-repeat="category in category.categories track by category._id" data-ui-tree-node data-ng-include="'blueprint-subcategory-hierarchy.html'">
./components/blueprints/list.map.blueprint.html:      <li data-ui-tree-node data-ng-repeat="category in blueprint.value.categories track by category._id" data-ui-tree-node data-nodrop data-ng-include="'blueprint-category-hierarchy.html'"></li>
./components/blueprints/form.discrete.html:    <li data-ng-repeat="category in category.categories track by category._id" data-ui-tree-node data-ng-include="'blueprint-hierarchy.html'">
./components/blueprints/form.discrete.html:    <li data-ng-repeat="category in blueprintCtrl.blueprint.categories track by category._id" data-ui-tree-node data-ng-include="'blueprint-hierarchy.html'"></li>
./components/blueprints/form.likert.html:    <li data-ng-repeat="category in blueprintCtrl.blueprint.categories track by category._id" data-ui-tree-node data-ng-include="'custom-fields-hierarchy.html'"></li>
./components/blueprints/form.map.blueprint.html:    <li data-ui-tree-node class="list-unstyled" data-ng-repeat="blueprint in blueprintsCtrl.map.blueprints track by blueprint._id" data-ng-include="'map-blueprint-hierarchy.html'"></li>

./components/relations/form.html:        <div data-ng-include="'app/components/relations/partials/relations.hierarchy.html'"></div>
./components/relations/partials/relations.hierarchy.html:    <li data-ng-repeat="category in category.categories" data-ui-tree-node data-ng-include="'relations-hierarchy.html'">
./components/relations/partials/relations.hierarchy.html:    <li data-ng-repeat="category in relationCtrl.relation.categories" data-ui-tree-node data-ng-include="'relations-hierarchy.html'"></li>

./components/goals/directives/goals.define.html:                    <div class="col-xs-12" data-ng-if="goal.targets.length" data-ng-include="'app/components/goals/partials/define-target.html'"></div>
./components/goals/listview.html:  <div data-ng-show="list.isLoading || !list.ready" data-ng-include="'app/layout/placeholders/list.html'"></div>
./components/goals/goal.preview.html:    <div class="row audit-log" data-ng-if="goalCtrl.shownDescription" data-ng-include="'app/components/events/partials/audit-log.include.html'"></div>
./components/goals/listview-no-featured.html:  <div data-ng-show="list.isLoading || !list.ready" data-ng-include="'app/layout/placeholders/list.html'"></div>
./components/goals/goal.html:    data-ng-include="'app/components/events/partials/audit-log.include.html'"></div>
./components/goals/partials/define-target.html:    <div data-ng-include="'app/components/goals/partials/define-conditions.html'"></div>
./components/goals/partials/define-conditions.html:      <!--<div data-ng-include="'app/components/goals/partials/define-filters.html'"></div>-->

./components/event-types/form.html:            <div class="row" data-ng-include="'app/components/event-types/partials/blueprint-maps.html'"></div>
./components/event-types/form.html:            <div class="row" data-ng-include="'app/components/event-types/partials/blueprint-maps.html'"></div>
./components/event-types/event-type-section-field.html:        <div class="col-xs-12" data-ng-if="fieldCtrl.field.type" data-ng-include="'app/components/event-types/partials/field.' + fieldCtrl.field.type + '.html'"></div>
./components/event-types/event-type-section-field.html:        <div class="col-xs-12" data-ng-include="'app/components/event-types/partials/field.properties.html'"></div>
./components/event-types/event-type-section-field.html:        <div class="col-xs-12" data-ng-include="'app/components/event-types/partials/field.restrictedBy.html'"></div>
./components/event-types/partials/discrete.custom-field.html:    <li data-ng-repeat="category in category.categories track by category._id" data-ui-tree-node data-ng-include="'custom-fields-hierarchy.html'">
./components/event-types/partials/discrete.custom-field.html:    <li data-ng-repeat="category in fieldCtrl.field.categories track by category._id" data-ui-tree-node data-ng-include="'custom-fields-hierarchy.html'"></li>
./components/event-types/partials/field.properties.html:    <div class="col-xs-12" data-ng-include="'app/components/event-types/partials/field.restrictedBy.html'"></div>
./components/event-types/partials/field.properties.html:      <div class="col-xs-12" data-ng-include="'app/components/event-types/partials/tree.custom-field.html'"></div>
./components/event-types/partials/field.properties.html:      <div class="col-xs-12" data-ng-include="'app/components/event-types/partials/likert.custom-field.html'"></div>
./components/event-types/partials/likert.custom-field.html:    <li data-ng-repeat="category in fieldCtrl.field.categories track by category._id" data-ui-tree-node data-ng-include="'custom-fields-hierarchy.html'"></li>

./components/roles/partials/roles.hierarchy.html:    <li data-ng-repeat="role in role.roles" data-ui-tree-node data-ng-include="'roles-hierarchy.html'">
./components/roles/partials/roles.hierarchy.html:    <li data-ng-repeat="role in roleCtrl.role.roles" data-ui-tree-node data-ng-include="'roles-hierarchy.html'"></li>

./components/dashboard/partials/widget.html:    <div ng-include src="definition.titleTemplateUrl"></div>

./components/user-fields/form.html:      <div class="row" data-ng-include="'app/components/user-fields/form.discrete.html'" data-ng-if="userFieldCtrl.userField.fieldType === 'discrete' || userFieldCtrl.userField.fieldType === 'discrete_multiple'"></div>
./components/user-fields/form.discrete.html:    <li data-ng-repeat="category in category.categories track by category._id" data-ui-tree-node data-ng-include="'user-field-hierarchy.html'">
./components/user-fields/form.discrete.html:    <li data-ng-repeat="category in userFieldCtrl.userField.categories track by category._id" data-ui-tree-node data-ng-include="'user-field-hierarchy.html'"></li>

./components/accounts/login.html:          <div ng-if="loginCtrl.organisations.length" ng-include="'app/components/accounts/partials/organisation-select.html'"></div>
./components/accounts/login.html:            <div ng-if="loginCtrl.organisations.length" ng-include="'app/components/accounts/partials/organisation-select.html'"></div>

./components/events/partials/audit-log.include.html:    <!-- Any better solution than this? That's the issue when you ng-include -->

./components/reports/directives/single-report-view.html:                    <span data-ng-include="'app/components/reports/directives/report-value.html'"></span>
./components/reports/directives/single-report-view.html:                <span data-ng-include="'app/components/reports/directives/report-value.html'"></span>
*/
