import * as Raven from 'raven-js';

(function() {
  'use strict';

  function config($provide) {
    $provide.decorator('$exceptionHandler', ['$delegate',
      function($delegate) {
        return function(exception, cause) {
          if (exception.message === 'cancelled' || exception.status === 401) {
            console.log(exception.message);
            return;
          }

          $delegate(exception, cause);

          if (exception &&
              exception.indexOf &&
              exception.indexOf('Possibly unhandled rejection') !== -1
          ) {
            // Ignore the rest if just possible error
            return;
          }

          if (exception && exception.status && exception.status === 401) {
            return;
          }

          // $log.debug(exception.stack);
          if (window.kzConfig && window.kzConfig.sentry === 'enabled') {
            Raven.captureException(exception);
          }

          // alert(exception.message); // jshint ignore:line
        };
      }
    ]);
  }

  config.$inject = ['$provide'];

  angular.module('blocks.utils')
    .config(config);
})();
