angular.module('component.importer')
  .component('optionsUsed', {
    templateUrl: 'app/components/importer/components/optionsUsed.html',
    controller: function() {
      var ctrl = this;

      function update(options) {
        if (_.isUndefined(options) || _.isEmpty(options) || !options) {
          return;
        }

        var readableState = { true: 'Yes', false: 'No' };
        var dryrunReadableState = { true: 'Mock run', false: 'Real import' };
        var actionReadableState = {
          createOnlyIfNotExists: 'Create the entries that do not exists and do not update ' +
            'the existing ones.',
          forceCreate: 'Create all entries in the file no matter if they are matching existing',
          createOrUpdate: 'Create the entries that do not exists AND update the one that are',
          updateOnly: 'Only update existing entries'
        };

        var linkExistingEventsToTargets = options.linkExistingEventsToTargets || false;
        ctrl.readableOptions = {
          Action: actionReadableState[options.action],
          'Type of import': dryrunReadableState[options.dryrun],
          'Email notifications': readableState[options.sendEmails],
          'Link existing events to targets': readableState[linkExistingEventsToTargets]
        };
      }

      this.$onInit = function() {
        update(ctrl.options);
      };

      this.$onChanges = function(changes) {
        update(changes.options.currentValue);
      };
    },
    bindings: {
      options: '<'
    }
  });
