(function() {
  'use strict';

  function PendingSectionsController(
    $state,
    $rootScope,
    Profile,
    EventSection,
    PendingSectionSearch,
    APIList,
    Utils
  ) {
    var ctrl = this;

    Utils.setPageTitle('Pending sections');

    ctrl.options = {
      highlightField: 'text'
    };

    var listName = 'pending-sections';
    ctrl.options.search = ctrl.searchModel;

    ctrl.searchModel = Profile.getListPreferences(listName).current;

    var makeDocs = function(item) {
      return new EventSection(item);
    };

    var loadSections = function() {
      return ctrl.list.doLoadItems()
        .catch(function(error) {
          Utils.showError(error, function() {
            $state.go('epf.admin.index');
          });
        });
    };

    function setupList(search) {
      ctrl.list = new APIList(null, {
        endpoint: 'es_pending_sections',
        search: search,
        model: ctrl.searchModel,
        makeDocs: makeDocs,
        defaultFilter: {
          state: 'pending'
        }
      });
      return loadSections();
    }

    PendingSectionSearch.search
      .then(function(search) {
        return setupList(search);
      })
      .then(function() {
        ctrl.loaded = true;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName });
      });

    ctrl.actions = [
      // {
      //   label: 'Import CSV',
      //   icon: 'icon-edit',
      //   href: (function() { return $state.href('epf.admin.importing-sections'); })(),
      //   klass: 'btn-success',
      //   showCondition: function() {
      //     return Security.hasPermission('events.import')
      //       .then(function() {
      //         return Admin.getImportableSections();
      //       })
      //       .then(function(sections) {
      //         return sections.length > 0;
      //       });
      //   }
      // }
    ];
  }

  PendingSectionsController.$inject = [
    '$state',
    '$rootScope',
    'ProfileService',
    'EventSectionFactory',
    'PendingSectionSearch',
    'APIListFactory',
    'UtilsService'
  ];

  angular.module('component.admin')
    .controller('PendingSectionsController', PendingSectionsController);
})();
