(function() {
  'use strict';

  function SlotDirective($state, moment, Tasks, CELERY_TASKS, LocalizationService) {
    return {
      scope: {
        item: '=',
        options: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/tasks/task.slot.html',
      link: function(scope) {
        scope.control = {};
        scope.control.shownDetails = false;
        scope.action = _.find(CELERY_TASKS, { id: scope.item.doc.action });
        scope.dateFormat = LocalizationService.getDateTimeFormat('datetime');
        var updateDuration = function() {
          scope.duration = moment
            .duration(
              moment(scope.item.doc.updatedDate).diff(
                scope.item.doc.startDate || scope.item.doc.createdDate
              )
            )
            .humanize();
        };

        var updateItem = function() {
          updateDuration();
          try {
            scope.failedUsers = _.map(scope.item.doc.meta.errors, function(item) {
              return item.user;
            });
          } catch (err) {
            scope.failedUsers = [];
          }
        };

        updateItem();

        scope.secondaryActionButtons = [
          {
            label: 'View task',
            icon: 'icon-view',
            href: (function() {
              return $state.href('epf.admin.pending-sections-import', {
                eventType: scope.item.doc.data.eventType,
                section: scope.item.doc.data.section,
                task: scope.item.doc._id
              });
            })(),
            showCondition: function() {
              try {
                return ((scope.item.doc.subtype || 'user-action') === 'import-file');
              } catch (err) {
                return false;
              }
            }
          },
          {
            label: 'Run a new operation from the same user filter',
            icon: 'icon-reload',
            onClick: function() {
              $state.go('epf.users.bulk', { taskDoc: scope.item.doc });
            },
            showCondition: function() {
              try {
                if ((scope.item.doc.subtype || 'user-action') !== 'user-action') {
                  return false;
                }

                return _.isUndefined(scope.item.doc.data.users);
              } catch (err) {
                return true;
              }
            }
          },
          {
            label: 'Run again for this group of users',
            icon: 'icon-reload',
            onClick: function() {
              $state.go('epf.users.bulk', { taskDoc: scope.item.doc });
            },
            showCondition: function() {
              try {
                if ((scope.item.doc.subtype || 'user-action') !== 'user-action') {
                  return false;
                }

                return !_.isUndefined(scope.item.doc.data.users);
              } catch (err) {
                return false;
              }
            }
          }
        ];


        scope.actionButtons = [
          {
            label: 'Retry for failed users',
            icon: 'icon-reload',
            onClick: function() {
              var doc = angular.copy(scope.item.doc);
              doc.data.users = _.map(doc.meta.errors, function(item) {
                return item.user;
              });
              delete doc.data.filter;
              $state.go('epf.users.bulk', { taskDoc: doc });
            },
            showCondition: function() {
              try {
                if ((scope.item.doc.subtype || 'user-action') !== 'user-action') {
                  return false;
                }

                return scope.item.doc.meta.errors.length > 0;
              } catch (err) {
                return false;
              }
            }
          }
        ];

        scope.getFailedUsers = function() {
          var users;
          try {
            users = _.map(scope.item.doc.meta.errors, function(item) {
              return [item.user, item.error ? item.error.message : 'unknown'];
            });
          } catch (err) {
            users = [];
          }

          return users;
        };

        scope.getSuccessUsers = function() {
          return _.map(scope.item.doc.meta.success || [], function(item) {
            return [item];
          });
        };

        scope.$on('DBPullChange', function(_evt, args) {
          if (args.id === scope.item.doc._id) {
            // Leave some time for pouchdb to process
            console.log('Noticed change for', args.id);
            Tasks.find(scope.item.doc._id)
              .then(function(doc) {
                scope.item.doc = doc;
                updateItem();
              });
          }
        });
      }
    };
  }

  SlotDirective.$inject = ['$state', 'moment', 'TasksService', 'CELERY_TASKS',
    'LocalizationService'];

  angular.module('component.tasks')
    .directive('taskSlot', SlotDirective);
})();
