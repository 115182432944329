import * as Raven from 'raven-js';

(function() {
  'use strict';

  function AuthService($rootScope, $http, $q, $log, $state, $location, $timeout, md5,
    kzLocalStorage, Network, Api, Cookies, Utils, OidcService, Notify,
    COUCHDB_URLS, AUTH_ERRORS, AUTH_MODES, SERVER_CONFIG) {
    var pinKey = 'kz-pin';
    var service = {};
    service._currentLogin = null;
    service._currentUser = null;
    service._currentOrganisation = null;
    service._isdisabled = false;

    service.MODES = AUTH_MODES;
    service.preferredMode = service.MODES.online;

    service.login = function(organisation, username, password, options) {
      $log.warn('Deprecated: Login should not be called');
      options = options || {};
      var loginHttpConfig = {
        method: 'POST',
        url: COUCHDB_URLS.login,
        data: {
          organisation: organisation,
          name: username,
          password: password
        },
        withCredentials: true
      };

      // We got rid of "require_valid_user" so this is not needed
      // $http.defaults.headers.common['Authorization'] = 'Basic ' + hash; // jshint ignore:line
      var promise = $q.when();
      if (!options.force) {
        promise = Network.forceOnline();
      }

      return promise
        .then(function() {
          return Api.post('login', undefined, undefined, { force: true })
            // Catch any problem as we want not to be authenticated
            .catch(function() {})

            // Do not let login if there is a different user logged in
            //  - this is because we cannot easily handle different types of
            //  providers - e.g. cannot easily log out SSO users
            .then(function(response) {
              if (response.username && response.username !== username) {
                return $q.reject({
                  status: 444,
                  data: { reason: 'There is already a different user logged in' }
                });
              }
            });
        })
        .then(function() {
          return $http(loginHttpConfig);
        })
        .catch(function(response) {
          if (response.status === 0 || response.status === -1 ||
              response.status === 503 || response.status === 502) {
            return service.loginOffline();
          }
          return $q.reject({ status: response.status, message: response.data.reason });
        })
        .then(function(response) {
          var promise;
          if (service._isdisabled) {
            promise = $q.reject(AUTH_ERRORS.userDisabled);
          } else if (response.data.name) {
            if (options.forceUsername && response.data.name !== options.forceUsername) {
              promise = service._couchLogout()
                .catch(function() {})
                .then(function() {
                  return $q.reject({
                    status: 444,
                    data: { reason: 'There is already a different user logged in' }
                  });
                });
            } else {
              promise = $q.when(response.data.name); // Api.post('login');
            }
          } else {
            promise = $q.reject(AUTH_ERRORS.notLoggedIn);
          }

          return promise;
        })
        .then(function() {
          return Api.post('login', undefined, undefined, { force: true });
        })
        .then(function(result) {
          $rootScope.$broadcast('KZClearCache');
          $rootScope.$broadcast('KZLoggedIn');
          Cookies.put('currentUser', {
            username: result.username,
            login: result.login
          });
          Cookies.put('currentLoginType', result.login_type);
          kzLocalStorage.setItem('currentLoginType', { type: result.login_type });
          kzLocalStorage.setItem('kz-login', result.org);
          service._currentUser = result.username;
          service._currentLogin = result.login;
          $rootScope.needRelogin = false;
          service.startLoginCheck();
        })
        .catch(function(response) {
          return $q.reject(
            { status: response.status, message: response.message || response.data.reason }
          );
        });
    };

    service.loginOffline = function(user, pin) {
      // if (!pin) {
      //   return $q.reject({ status: 401, message: 'Please enter your PIN number' });
      // }

      var username = user.doc.username;

      // var pins = service._getPins();
      var hashed = md5.createHash(pin);

      var pins = service.getPinsFor(user);

      if (pins.length === 0) {
        return $q.reject({ status: 401,
          message: 'There is no PIN code set for this user. Please log in online and set' });
      }

      pins = _.filter(pins, function(pin) {
        return pin === hashed;
      });

      if (pins.length === 0) {
        return $q.reject({ status: 401, message: 'The entered PIN is not valid' });
      }

      // // Do not allow to log in as someone else if there is already a currentUser
      // // This would work as if it was just locked, not logged out
      // if (currentUser.username && username !== currentUser.username) {
      //   // This is a different error but by doing this we would reveal that somebody
      //   // is actually using the specified PIN. So lets pretend it doesn't exist
      //   return $q.reject({ status: 401, message: 'The entered PIN is not valid' });
      // }

      Cookies.put('currentUser', { username: username });
      Cookies.put('pin', hashed);
      service._currentUser = username;
      var userData = {
        profile: {
          sub: username
        },
        scope: 'openid',
        expires_at: -1
      };
      try {
        window.localStorage.setItem('currentOidcUser', JSON.stringify(userData));
      } catch (err) {
        console.log('Could not set oidc user');
      }
      $rootScope.needRelogin = false;
      return OidcService.getLoggedInUser();
      // // service._currentLogin = currentUser.login;
      // return $q.when();
    };

    service.getPinsFor = function(user) {
      var profiles = user.profiles || [user.doc];
      var pins = _.filter(_.map(profiles, 'doc.pin'), function(pin) {
        return !_.isEmpty(pin);
      });
      return pins;
    };

    service._couchLogout = function() {
      var deleteHttpConfig = {
        method: 'DELETE',
        url: COUCHDB_URLS.login,
        withCredentials: true
      };

      return $http(deleteHttpConfig);
    };

    service._oidcLogout = function() {
      return OidcService.logout();
    };

    service._logout = function() {
      return service.getStorageMode()
        .then(function(mode) {
          var options = { strong: mode !== 'persistent' };
          $rootScope.$broadcast('KZClearCache');
          if (options.strong) {
            $rootScope.$broadcast('KZRemoveLocalData', { username: service._currentUser });
          }
          // First do what we want to do and then try loging out from couch
          Cookies.remove('currentUser');

          // Cookies.remove('currentOrganisation');
          Cookies.remove('currentLoginType');

          // Clear AuthSession too in case we don't reach the server
          Cookies.remove('AuthSession');
          try {
            kzLocalStorage.popItem('currentOidcUser');
          } catch (err) {
            console.log('Could not clear currentOidcUser', err);
          }
          service._currentLogin = null;
          service._currentUser = null;
          service._localSettings = undefined;
          service.cancelLoginCheck();

          if (SERVER_CONFIG.authType !== 'oidc') {
            return service._logoutLegacy();
          }
          return service._oidcLogout();
        })
        .catch(function(response) {
          // We don't care here
          $log.debug('Logout error - ignoring');
          Raven.setUserContext();
          return response.data;
        })
        .then(function() {
          // remove user context from Sentry
          Raven.setUserContext();
          if (Network.isOffline()) {
            return $state.go('accounts.loggedout');
          }
        });
    };

    service._logoutLegacy = function() {
      $log.warn('Deprecated: Couchd logout should not be called');
      return service._couchLogout()
        .then(function(response) {
          // Check for sso logout in request
          var headers = response.headers();
          var logoutPath = headers['x-auth-sso-logout'];
          if (logoutPath !== undefined) {
            window.location.href = logoutPath;
            return;
          }
          return response.data;
        });
    };

    service.logout = function(options) {
      options = options || {};
      return service._logout()
        .then(function() {
          if (SERVER_CONFIG.authType === 'oidc') {
            return;
          }
          var prm = $state.go('accounts.loggedout');
          if (options.query) {
            prm.then(function() {
              $location.search(options.query);
            });
          }
        });
    };

    service.isLoggedIn = function() {
      $log.debug('Network status is', Network.networkStatus);
      if (Network.isOffline()) {
        return service._isLoggedInOffline();
      }

      return service._isLoggedInOnline();
    };

    service.ensureLoggedIn = function() {
      $log.debug('Network status is', Network.networkStatus);
      if (Network.isOffline()) {
        return service._isLoggedInOffline();
      }

      if (SERVER_CONFIG.authType !== 'oidc') {
        return $q.when();
      }

      return OidcService.isLoggedIn()
        .then(function(loggedIn) {
          if (!loggedIn) {
            return OidcService.loginPopup();
          }
        })
        .catch(function(err) {
          console.log(err);
          return $q.reject(AUTH_ERRORS.notLoggedIn);
        });
    };

    service.hasCurrentUser = function() {
      var user = service.currentUser();
      if (_.isEmpty(user)) {
        return $q.reject({ status: 401, message: 'User is not logged in' });
      }

      return $q.when();
    };

    service.isAuthenticated = function() {
      if (SERVER_CONFIG.authType === 'oidc') {
        return OidcService.isLoggedIn();
      }

      $log.debug('Deprecated: isAuthenticated should not be called');
      return Api.post('login', undefined, undefined, { force: true })
        .then(function(response) {
          var username = response.username;
          if (username) {
            if (service._currentUser && service._currentUser !== username) {
              return $q.reject({
                status: 444,
                message: 'There is already different user logged in'
              });
            }

            return $q.when(response);
          }

          return $q.reject(AUTH_ERRORS.notLoggedIn);
        });
    };

    service._isLoggedInOnlineLegacy = function() {
      $log.debug('Deprecated: _isLoggedInOnlineLegacy should not be called');
      return Api.post('login', undefined, undefined, { force: true })
        .catch(function(err) {
          // If error is undefined we didn't reach the server, change to offline
          if (err.status === 0 || err.status === -1 || err.status === 503 || err.status === 502) {
            $rootScope.$broadcast('NetworkOffline');
            return $q.reject({ status: 510, message: 'Connection was lost, retrying in offline' });
          }

          if (err.status === 403) {
            $rootScope.needRelogin = true;
            return {};
          }

          if (err.status === 467) {
            // $rootScope.needRelogin = true;
            return $q.reject({ status: err.status });
          }

          $rootScope.$broadcast('NetworkOnline');
          $log.error(err);
          return $q.reject(
            {
              status: err.status,
              message: 'Cannot reach the backend - ' + err.message,
              details: err.details
            }
          );
        })
        .then(function(response) {
          if (response.minVersion && !Utils.validVersion(response.minVersion)) {
            return $q.reject(AUTH_ERRORS.upgradeRequired);
          }

          $rootScope.$broadcast('NetworkOnline');

          // var username = response.data.userCtx.name;
          var username = response.username;
          if (username) {
            if (service._currentUser && service._currentUser !== username) {
              return service._logout()
                .finally(function() {
                  return $q.reject(AUTH_ERRORS.notLoggedIn);
                });
            }

            return $q.when(response);
          }

          return $q.reject(AUTH_ERRORS.notLoggedIn);
        });
    };

    service._isLoggedInOnline = function() {
      var def;
      if (SERVER_CONFIG.authType === 'oidc') {
        def = OidcService.getLoggedInUser();
      } else {
        def = service._isLoggedInOnlineLegacy();
      }


      return def
        .catch(function() {
          return $q.reject(AUTH_ERRORS.notLoggedIn);
        })
        .then(function(response) {
          if (service._currentUser) {
            return $q.when();
          }
          $log.warn('Setting current user');
          Cookies.put('currentUser', {
            username: response.username,
            login: response.login
          });

          Cookies.put('currentLoginType', response.login_type);
          kzLocalStorage.setItem('currentLoginType', { type: response.login_type });
          kzLocalStorage.setItem('kz-login', response.org);
          service._currentUser = response.username;
          service._currentLogin = response.login;
          $rootScope.needRelogin = false;
          Raven.setUserContext({
            username: service._currentUser,
            organisation: service._currentOrganisation
          });
          service.startLoginCheck();
        })
        .catch(function(error) {
          if (error.status === 0 || error.status === 510) {
            return service._isLoggedInOffline();
          }

          if (Math.floor(error.status / 100) === 5) {
            return $q.reject({
              status: error.status,
              message: 'It is not possible to reach the backend to authenticate.',
              help: 'Please, try again later'
            });
          }

          return $q.reject(error);
        });
    };

    service._isLoggedInOffline = function() {
      if (service._currentUser) {
        console.log('Auth: getting current user', service._currentUser);
        return $q.when();
      }

      var currentUser = Cookies.get('currentUser') || {};

      if (currentUser.username) {
        service._currentUser = currentUser.username;
        service._currentLogin = currentUser.login;
        // return $q.when();
      }

      return OidcService.getLoggedInUser()
        .then(function(user) {
          service._currentUser = user.username;
          service._currentLogin = user.login;
        })
        .catch(function() {
          return $q.reject(AUTH_ERRORS.notLoggedIn);
        });

      // FIXME
      /* eslint-disable */
      /* jshint-disable */
      var hashed = Cookies.get('pin');
      var pins = service._getPins();
      var username = currentUser.username;

      if (username !== pins[hashed]) {
        return $q.reject(AUTH_ERRORS.notLoggedIn);
      }

      service._currentUser = username;
      service._currentLogin = currentUser.login;
      $rootScope.needRelogin = false;
      return $q.when();
      /* jshint-enable */
      /* eslint-enable */
    };

    service.currentUser = function() {
      return service._currentUser;
    };

    service.currentLogin = function() {
      return service._currentLogin || service._currentUser;
    };

    service.currentOrganisation = function() {
      var org = service._currentOrganisation;

      if (!org) {
        org = window.localStorage.getItem('currentOrganisation');
        if (!org) {
          org = Cookies.get('currentOrganisation');
        }
        if (org !== undefined) {
          service._currentOrganisation = org;
          OidcService.setOrganisation(org);
          // window.localStorage.setItem('currentOrganisation', org);
        }
      }

      return org;
    };

    service.setOrganisation = function(orgId) {
      service._currentOrganisation = orgId;
      OidcService.setOrganisation(orgId);

      try {
        if (orgId) {
          window.localStorage.setItem('currentOrganisation', orgId);
        } else {
          window.localStorage.removeItem('currentOrganisation');
        }
      } catch (err) {
        console.log(err);
      }
      var expires = new Date();
      expires.setFullYear(expires.getFullYear() + 10);
      Cookies.put('currentOrganisation', orgId, {
        expires: expires
      });
      $rootScope.$broadcast('OrganisationSettingsChanged');
      Raven.setExtraContext({ organisation: orgId }); // Sentry
    };

    service.unsetOrganisation = function() {
      $rootScope.$broadcast('KZClearCache');
      delete service._currentOrganisation;
      Cookies.remove('currentOrganisation');
      Raven.setExtraContext({ organisation: undefined }); // Sentry
    };

    service.register = function(register) {
      return Api.post('register', register)
        .catch(function(err) {
          return $q.reject({ status: err.status, message: err.message });
        });
    };

    service.getSettings = function() {
      if (!service._currentUser) {
        $log.warn('Trying to get settings for an undefined user');
        return {};
      }

      var key = 'settings_' + service._currentUser;
      var settings = kzLocalStorage.getItem(key);

      // Load defaults
      settings.preferredMode = settings.preferredMode || 'online';
      return settings;
    };

    service.setSettings = function(settings) {
      if (!service._currentUser) {
        $log.warn('Trying to set settings for an undefined user');
        return {};
      }

      settings.preferredMode = !settings.preferredOffline ? 'online' : 'offline';

      var key = 'settings_' + service._currentUser;
      kzLocalStorage.setItem(key, settings);
      service.processSettings();

      $rootScope.$broadcast('SettingsChanged', settings);
    };

    service.getStorageMode = function() {
      if (service.noIDBSupport) {
        return $q.when('none');
      }

      return service.getLocalSettings()
        .then(function(settings) {
          return settings.storageMode || 'persistent';
        })
        .catch(function() {
          return 'persistent';
        });
    };

    service.getAllowSyncInOffline = function() {
      return service.getLocalSettings()
        .then(function(settings) {
          return settings.alloSyncInOffline !== undefined ? settings.allowSyncInOffline : true;
        })
        .catch(function() {
          return true;
        });
    };

    service.setStorageMode = function(mode) {
      return service.getLocalSettings()
        .then(function(settings) {
          settings.storageMode = mode;
          return service.setLocalSettings(settings);
        })
        .then(function() {
          var msgs = {
            none: 'No data will be stored on this device',
            session: 'Data will be stored temporarily on this device and cleared upon logout',
            persistent: 'Data will be stored on this device'
          };

          Notify.success(msgs[mode]);
        });
    };

    service.setLocalSettings = function(settings) {
      if (!service._currentUser) {
        $log.warn('Trying to set settings for an undefined user');
        return $q.reject({ status: 403, messsage: 'User unknown' });
      }

      var key = 'kz_settings_' + service._currentUser;
      var expires = new Date();
      kzLocalStorage.setItem(key, settings);
      expires.setFullYear(expires.getFullYear() + 10);
      // Cookies.put(key, settings, { expires: expires });
      service._localSettings = undefined;
      $rootScope.$broadcast('KZLocalSettingsChanged');
      return $q.when();
    };

    service.getLocalSettings = function() {
      if (!service._currentUser) {
        // $log.warn('Trying to get settings for an undefined user');
        return $q.reject({ status: 403, message: 'Trying to get settings for unknown user' });
      }

      if (service._localSettings !== undefined) {
        return $q.when(service._localSettings);
      }

      var key = 'kz_settings_' + service._currentUser;
      var settings = kzLocalStorage.getItem(key);
      if (_.isEmpty(settings)) {
        settings = Cookies.get(key) || {};
        if (!_.isEmpty(settings)) {
          kzLocalStorage.setItem(key, settings);
          Cookies.remove(key);
        }
      }
      service._localSettings = settings;
      // $rootScope.$broadcast('KZLocalSettingsChanged');
      return $q.when(settings);
    };

    service.removeLocalSettings = function() {
      if (!service._currentUser) {
        // $log.warn('Trying to get settings for an undefined user');
        return $q.reject({ status: 403, message: 'Trying to get settings for unknown user' });
      }

      var key = 'kz_settings_' + service._currentUser;
      service._localSettings = undefined;
      kzLocalStorage.popItem(key);
      Cookies.remove(key);
      // $rootScope.$broadcast('KZLocalSettingsChanged');
    };

    service.updatePin = function(pin) {
      var pins = service._getPins();
      if (service.preferredMode === 'online') {
        delete pins[service._currentUser];
      } else {
        pins[service._currentUser] = pin;
      }

      service._setPins(pins);
    };

    service._getPins = function() {
      return kzLocalStorage.getItem(pinKey);
    };

    service._setPins = function(pins) {
      kzLocalStorage.setItem(pinKey, pins);
    };

    service.processSettings = function() {
      var settings = service.getSettings();
      service.preferredMode = settings.preferredMode;
    };

    service.startLoginCheck = function() {
      if (service.checkRunning) {
        return;
      }

      var checkLogin = function() {
        if (service.abortCheck) {
          service.checkRunning = false;
          return;
        }

        service.isLoggedIn();
        $timeout(function() {
          checkLogin();
        }, 5 * 60 * 1000);
      };

      service.checkRunning = true;
      service.abortCheck = false;
      $timeout(function() {
        checkLogin();
      }, 5 * 60 * 1000);
    };

    service.cancelLoginCheck = function() {
      service.abortCheck = true;
    };

    service.isLoggedInBy = function() {
      return Cookies.get('kz_login_by');
    };

    return service;
  }

  AuthService.$inject = ['$rootScope', '$http', '$q', '$log', '$state', '$location', '$timeout',
    'md5', 'kzLocalStorage', 'NetworkService', 'ApiService', 'CookiesService',
    'UtilsService', 'OidcService', 'NotifyService', 'COUCHDB_URLS', 'AUTH_ERRORS', 'AUTH_MODES',
    'SERVER_CONFIG'];

  angular.module('blocks.auth')
    .service('AuthService', AuthService);
})();
