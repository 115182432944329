(function() {
  'use strict';

  function VersionFactory($q, Utils, VERSION_STATES) {
    var checkStructure = function(group) {
      return group && !_.isUndefined(group.doc) && !_.isUndefined(group.linkedVersions);
    };

    var Version = function(group) {
      if (checkStructure(group)) {
        this.init(group);
      } else {
        throw ('The structure of the object passed to the Version Factory is not correct!');
      }
    };

    Version.prototype.init = function(group) {
      this.type = 'versioned';
      this.doc = group.doc;
      this.linkedVersions = _.map(group.linkedVersions, function(item) {
        if (item._id === undefined) {
          item._id = item.id;
        }
        return item;
      });

      if (group._id !== undefined) {
        this.groupId = group._id;
      } else {
        this.groupId = this.doc.versionGroupId;
      }

      this.versionNumber = this.getVersionNumber(this.doc._id);
      this.headVersion = this.getHeadVersion();
    };

    Version.prototype.getVersionNumber = function(vid) {
      return _.findIndex(this.linkedVersions, { id: vid }) + 1;
    };

    Version.prototype.hasState = function(state) {
      return this.doc.state === state;
    };

    Version.prototype.isSystem = function() {
      return this.doc.systemType === 'system';
    };

    Version.prototype.validate = function(action) {
      var _this = this;
      return $q.when()
        .then(function() {
          if (action === 'createNewVersion') {
            return _this.checkStateNotPresent(VERSION_STATES.draft);
          } else if (action === 'delete') {
            if (!_this.hasState(VERSION_STATES.draft)) {
              return $q.reject(
                { message: 'You can only delete draft version' }
              );
            }
          }

          return $q.when();
        }).then(function() {
          if (['createNewVersion', 'archive'].indexOf(action) > -1) {
            if (_this.isSystem()) {
              return $q.reject({ message: 'You cannot do this action with system versions' });
            }
          }

          return $q.when();
        });
    };

    Version.prototype.checkStateNotPresent = function(state) {
      var states = _.map(this.linkedVersions, 'state');
      return states.indexOf(state) === -1;
    };

    Version.prototype.getHeadVersion = function() {
      return _.last(this.linkedVersions);
    };

    Version.prototype.getPublishedVersion = function() {
      return _.find(this.linkedVersions, function(linkedVersion) {
        return linkedVersion.state === VERSION_STATES.published;
      });
    };

    Version.prototype.getPreviousVersion = function() {
      // Version is 1 based, arrays 0 based, that is why subtracting 2
      if (this.versionNumber > 1) {
        return this.linkedVersions[this.versionNumber - 2];
      }
    };

    Version.prototype.createNewVersion = function() {
      return this.service.new_version(this.doc.versionGroupId);
    };

    Version.prototype.cloneVersion = function() {
      var newVersion;
      var _this = this;

      // If creating from a draft, lets save it first
      var promise = $q.when();
      if (this.doc.state === 'draft') {
        promise = this.service.save(this.doc);
      }

      return promise
        .then(function() {
          var doc = _this.doc;
          newVersion = angular.copy(doc);
          newVersion._id = Utils.guid();
          if (doc.name) {
            newVersion.name = 'Copy of ' + doc.name;
          }
          if (doc.title) {
            newVersion.title = 'Copy of ' + doc.title;
          }
          newVersion.versionGroupId = Utils.guid();
          delete newVersion._rev;
          delete newVersion.nextVersion;
          newVersion.state = VERSION_STATES.draft;
          newVersion.version = 1;

          // add audit log
          newVersion.dates = [];

          return {
            doc: newVersion,
            linkedVersions: [newVersion],
            _id: newVersion.versionGroupId
          };
        });
    };

    Version.prototype.publish = function() {
      return this.service.publish(this.doc._id);
    };

    Version.prototype.archive = function() {
      return this.service.archive(this.doc._id);
    };

    Version.prototype.delete = function() {
      return this.service.remove(this.doc._id);
    };

    Version.prototype.getBorderClass = function() {
      var map = {
        draft: 'pending',
        published: 'complete',
        archived: 'danger'
      };

      return 'progress-border-' + map[this.doc.state];
    };


    return Version;
  }

  VersionFactory.$inject = ['$q', 'UtilsService', 'VERSION_STATES'];

  angular.module('blocks.versions')
    .factory('VersionFactory', VersionFactory);
})();
