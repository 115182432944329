(function() {
  'use strict';

  function MultiReportFactory(
    $q,
    SingleReport,
    SQLReport,
    ReportFields,
    Form,
    Utils,
    Api
  ) {
    var MultiReport = function(reportDoc, options) {
      this.type = 'multiReport';
      this.reportId = reportDoc._id;
      this.reportDoc = reportDoc;
      this.options = options || {};
      reportDoc.globalFilters = reportDoc.globalFilters || {};
      this.globalFilters = reportDoc.globalFilters;
      reportDoc.elements = reportDoc.elements || [];
      this.elements = reportDoc.elements;
      this.loadSingleReports();
      this.loadSQLReports();
    };

    MultiReport.prototype.getTitle = function() {
      return this.reportDoc.title;
    };

    MultiReport.prototype.loadSingleReports = function() {
      this.singleReports = {};
      var _this = this;
      _.forEach(this.elements, function(element) {
        if (element.type !== 'report') {
          return;
        }
        var data = {
          reportId: _this.reportId,
          elementId: element._id,
          conf: element.conf,
          sourceGlobalFilters: _this.globalFilters[element.conf.source]
        };
        _this.singleReports[element._id] = new SingleReport(data, _this.options);
      });
    };

    MultiReport.prototype.loadSQLReports = function() {
      this.sqlReports = {};
      var _this = this;
      _.forEach(this.elements, function(element) {
        if (element.type !== 'sqlreport') {
          return;
        }
        var data = {
          reportId: _this.reportId,
          elementId: element._id,
          conf: element.conf,
          sourceGlobalFilters: _this.globalFilters[element.conf.source]
        };
        _this.sqlReports[element._id] = new SQLReport(data, _this.options);
      });
    };

    MultiReport.prototype.getElement = function(elementId) {
      return _.find(this.elements, function(element) {
        return elementId === element._id;
      });
    };

    // static
    MultiReport.getBaseFormFields = function(disableAll) {
      return [
        {
          id: 'description',
          type: 'text',
          label: 'Description',
          required: false,
          disabled: disableAll
        }
      ];
    };

    MultiReport.getDefaultMultiReportElement = function(elementType) {
      var element = { _id: Utils.guid(), type: elementType };
      switch (elementType) {
        case 'report':
          element.conf = {
            presource: 'events',
            source: 'events',
            filter: {
              id: Utils.guid(),
              filterType: 'compFilter',
              operator: 'and',
              filters: []
            },
            sort: 'asc'
          };
          break;
        case 'text':
          element.text = '';
          break;
        default:
          break;
      }

      return element;
    };

    MultiReport.getTextElementFields = function() {
      return [
        {
          id: 'text',
          type: 'wysiwyg',
          label: 'Text Element',
          required: true,
          helpText: 'Enter your text. You can enter a placeholder for a current date using this ' +
                    'format: {{ current_date }} or {{ current_date | datetime }} or ' +
                    '{{ current_date | timeonly }}'
        }
      ];
    };

    MultiReport.prototype.validate = function() {
      // FIXME - validate filters
      var proms = _.map(Object.values(this.singleReports), function(report, idx) {
        return report.validate()
          .then(function(errors) {
            return _.map(errors, function(err) {
              return 'Report ' + (idx + 1) + ': ' + err;
            });
          });
      });

      return $q.all(proms)
        .then(function(allErrors) {
          return _.reduce(allErrors, function(prev, curr) {
            return prev.concat(curr);
          }, []);
        });
    };

    MultiReport.prototype.getForm = function(options) {
      options = options || {};
      return this.getFields(options)
        .then(function(fields) {
          return new Form(fields);
        });
    };

    MultiReport.prototype.mergeFields = function(fields) {
      var groups = _.groupBy(fields, function(fld) {
        return fld.type + ':' + fld.label;
      });
      var newFields = _.mapValues(groups, function(group) {
        var nid = _.reduce(group, function(cum, fld) {
          if (cum.length === 0) {
            return fld.id;
          }
          return cum + ':' + fld.id;
        }, '');
        var nfld = group[0];
        nfld.id = nid;
        return nfld;
      });
      return Object.values(newFields);
    };

    MultiReport.prototype.getFieldsFromFilter = function(source, filter) {
      function _getFields(fltr) {
        var _fields = [];
        if (fltr.filterType === 'compFilter') {
          fltr.filters.forEach(function(_fltr) {
            _fields = _fields.concat(_getFields(_fltr));
          });
          return _fields;
        }

        // This is a flat form used only when running so we need to filter out
        // fields that cannot be changed
        if (!fltr.variable) {
          return [];
        }

        // if (!_.isUndefined(_this.options.variable)) {
        //   if (_.indexOf(_this.options.variable, fltr.id) === -1) {
        //     return [];
        //   }
        // }

        // Even if there is only one filter, we still need to return an array
        // so the return type is the same for composed and simple filter
        return [
          ReportFields.buildField(source, fltr)
        ];
      }

      var fields = _getFields(filter);
      return $q.when(fields);
    };

    MultiReport.prototype.getFields = function() {
      var _this = this;
      var proms = _.map(this.singleReports, function(report) {
        return report.getFields();
      });
      _.forOwn(this.globalFilters, function(fltr, source) {
        proms.unshift(_this.getFieldsFromFilter(source, fltr));
      });
      _.forEach(this.sqlReports, function(report) {
        proms.push(report.getFields());
      });
      return $q.all(proms).then(function(res) {
        var fields = [];
        _.forEach(res, function(flds) {
          fields = fields.concat(flds);
        });
        var merged = _this.mergeFields(fields);

        // filter based on variable
        if (_this.options.variable !== undefined) {
          merged = _.filter(merged, function(fld) {
            return _this.options.variable.indexOf(fld.id) !== -1;
          });
        }
        return merged;
      });
    };

    MultiReport.prototype.getDefaultModel = function() {
      return this.getFields()
        .then(function(fields) {
          var model = {};
          fields.forEach(function(fld) {
            model[fld.id] = fld.default;
          });
          return model;
        });
    };

    MultiReport.prototype.clearModel = function(model, options) {
      if (model === undefined) {
        return $q.when();
      }

      return this.getFields(options)
        .then(function(fields) {
          var _model = {};
          fields.forEach(function(fld) {
            if (model[fld.id] !== undefined) {
              _model[fld.id] = model[fld.id];
            }
          });
          return _model;
        });
    };

    MultiReport.prototype.modelWithUser = function(model, user) {
      model = angular.copy(model);
      _.forOwn(model, function(value, key) {
        if (Array.isArray(value)) {
          model[key] = _.map(value, function(item) {
            return item === '__current__' ? user : item;
          });
        } else {
          model[key] = value === '__current__' ? user : value;
        }
      });
      return model;
    };

    /**
     * Generate the report as csv as Celerty Task
     * @param  {object} data The model from the form above
     * @return {Promise}      An object containing the result
     */
    MultiReport.prototype.generateAsTask = function(
      reportModel, user, resultValidityInDays, options
    ) {
      reportModel = angular.copy(reportModel);
      // this._updateModelWithUser(reportModel, user);
      var data = {
        model: reportModel,
        report_id: this.reportId,
        currentUser: user,
        resultValidityInDays: resultValidityInDays,
        options: options
      };

      return Api.post(
        'reports_generate_task',
        data,
        { report_id: this.reportId }
      );
    };

    MultiReport.prototype.canGenerateAsTask = function() {
      var keys = Object.keys(this.singleReports);
      if (keys.length !== 1) {
        return false;
      }

      return this.singleReports[keys[0]].canGenerateAsTask();
    };

    // methods
    return MultiReport;
  }

  MultiReportFactory.$inject = [
    '$q',
    'SingleReportFactory',
    'SQLReportFactory',
    'ReportFieldService',
    'FormsService',
    'UtilsService',
    'ApiService'
  ];

  angular.module('component.reports')
    .factory('MultiReportFactory', MultiReportFactory);
})();
