(function() {
  'use strict';

  function EventSectionFormController($scope, Form, EventUtils) {
    var ctrl = this;

    this.form = new Form();
    this.options = { formState: $scope.options || {} };
    ctrl.kzOpts = {
      showAllBlueprints: $scope.displayOptions.showAllBlueprints || false
    };

    function buildForm() {
      // FIX ME: we need to remove the filters from the view; Related to EPORT-1176 EPORT-1180
      // if (false &&
      //   $scope.def.multiSource &&
      //   ctrl.options.formState.readOnly &&
      //   $scope.event.isMine()
      // ) {
      //   ctrl.kzOpts.multiSource = $scope.def.multiSource;
      //   $scope.cons = $scope.event.concatSection($scope.def._id);
      // } else {
      //   $scope.cons = $scope.section;
      // }

      $scope.cons = $scope.section;

      // used for the goals
      ctrl.options.formState.sectionState = $scope.section.state;

      ctrl.kzOpts.event = $scope.event;
      ctrl.kzOpts.docId = $scope.event.doc._id;
      $scope.def.fields.forEach(function(field) {
        var fld = EventUtils.createFormField(field, ctrl.options, ctrl.kzOpts);
        ctrl.form.addField(fld);
      });

      // show only responsibles that has not responded yet
      var alreadyRespondedResp = _.map($scope.section.items, function(item) {
        return item.filledBy;
      });

      $scope.remainingResponsibles = _.filter($scope.section.actualResponsibles, function(resp) {
        var userInfo;
        if (resp.type === 'user') {
          userInfo = resp.username;
        } else if (resp.type === 'request') {
          userInfo = resp.email;
        }
        return _.indexOf(alreadyRespondedResp, userInfo) === -1;
      });

      console.log(Date(), 'Finished section loading');
    }

    this.documentRemoved = function(model, key, docId) {
      // document has been removed from section attachments
      var newList = [];

      model[key].forEach(function(row) {
        var index = row.value.indexOf(docId);

        if (index > -1) {
          row.value.splice(index, 1);
        }

        if (row.value.length !== 0) {
          newList.push(row);
        }
      });

      model[key] = newList;
    };

    buildForm();
  }

  EventSectionFormController.$inject = [
    '$scope',
    'FormsService',
    'EventUtils'
  ];

  function EventSectionFormDirective() {
    return {
      scope: {
        section: '=',
        event: '=',
        def: '=',
        parent: '=?',
        displayOptions: '=?',
        originalEvent: '=',
        allowActions: '=?',
        options: '=?',
        form: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-form.html',
      replace: true,
      controller: EventSectionFormController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventSectionForm', EventSectionFormDirective)
    .controller('EventSectionFormController', EventSectionFormController);
})();
