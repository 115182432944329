(function() {
  'use strict';

  function UserSearch($q, $filter, Relations, UserFields, Roles, Cache, USER_STATES) {
    function getRelations(relations) {
      return $q.when(relations)
        .then(function(filtered) {
          var result = [];

          var flatten = function(obj) {
            var result = [];
            result.push({
              id: obj._id,
              title: obj.name
            });

            if (obj.categories && obj.categories.length) {
              obj.categories.forEach(function(subobj) {
                result = result.concat(flatten(subobj));
              });
            }

            return result;
          };

          filtered.forEach(function(obj) {
            result = result.concat(flatten(obj.doc));
          });

          return result;
        });
    }

    function getRelationFacets() {
      return Relations.findAll()
        .then(function(relations) {
          return _.chain(relations)
            .map(function(obj) {
              return {
                id: 'relation$' + obj.doc._id,
                filterId: 'relation',
                type: 'select',
                label: obj.doc.name,
                multiselect: true,
                options: getRelations([obj]),
                lunrIndex: 'users.relations-' + obj.doc._id,
                facetly: false,
                advanced: true
              };
            })
            .value();
        });
    }

    function getUserFieldsFacets() {
      return UserFields.findAll({ withVisibility: true })
        .then(function(userFields) {
          return _.chain(userFields)
            .filter(function(userField) {
              return userField.doc.isVisible && (
                userField.doc.fieldType === 'discrete' ||
                userField.doc.fieldType === 'discrete_multiple' ||
                userField.doc.fieldType === 'tree' ||
                userField.doc.fieldType === 'string'
              );
            })
            .map(function(obj) {
              var type = 'select';
              var multiselect = true;
              var options = getRelations([obj]);
              if (obj.doc.fieldType === 'string') {
                type = 'text';
                multiselect = false;
                options = [];
              }
              return {
                id: '__uf__{' + obj.doc._id + '}',
                filterId: 'userField',
                type: type,
                label: obj.doc.name,
                multiselect: multiselect,
                options: options,
                facetly: false,
                advanced: true
              };
            })
            .value();
        });
    }

    var getUserSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'roles',
              type: 'select',
              multiselect: true,
              label: 'Show only users with these roles',
              options: Roles.findAll()
                .then(function(data) {
                  return _.map(data, function(role) {
                    return { id: role.doc._id, title: role.doc.title };
                  });
                }),
              facetly: false,
              advanced: true
            },
            {
              id: 'fullname',
              type: 'text',
              label: 'Start typing to search',
              facetly: true,
              advanced: false
            },
            {
              id: 'email',
              type: 'text',
              label: 'User email',
              facetly: false,
              advanced: true
            },
            {
              id: 'state',
              type: 'select',
              label: 'State',
              options: _.map(USER_STATES, function(item) {
                return { id: item.id, title: item.name };
              }),
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'fullname',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        orderGroups: {
          firstname: {
            title: 'first name',
            orders: ['firstName', 'lastName']
          },
          lastname: {
            title: 'last name',
            orders: ['lastName', 'firstName']
          },
          state: {
            title: 'state',
            orders: ['state', 'lastName', 'firstName'],
            groupBy: {
              title: 'User state is',
              notitle: 'Users without a state',
              getter: function(item) {
                return USER_STATES[item.doc.state].name;
              }
            }
          },
          date: {
            title: 'date added',
            orders: ['createdDate', 'lastName', 'firstName'],
            groupBy: {
              title: 'Users created in ',
              notitle: 'Users without a date',
              getter: function(item) {
                return $filter('date')(item.doc.createdDate, 'MMMM y');
              }
            }
          }
        },
        defaultOrder: 'firstname'
      };
      search.ready = false;

      $q.all([getRelationFacets(), getUserFieldsFacets()])
        .then(function(facets) {
          var arr = [search.facetly.facets.length, 0].concat(facets[0]).concat(facets[1]);
          search.facetly.facets.splice.apply(search.facetly.facets, arr);
          search.ready = true;
          return search;
        })
        .catch(function(error) {
          console.log('Could not load facets', error);
        });

      return $q.when(search);
    };

    var getSearch = function() {
      var func = getUserSearch;
      var ext = _.assignIn({
        key: 'search-user-usersearch',
        maxAge: 60 * 60 * 1000,
        cached: true
      });
      return Cache.cachedPromise(func, ext);
    };

    return {
      search: getSearch
    };
  }

  UserSearch.$inject = ['$q', '$filter', 'RelationsService', 'UserFieldsService', 'RolesService',
    'CacheService', 'USER_STATES'];

  angular.module('component.users')
    .service('UserSearch', UserSearch);
})();
