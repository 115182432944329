(function() {
  'use strict';

  var taskNames = {
    import_file: 'Bulk import users or events via CSV',
    bulk_merge_users: 'Bulk Merge users'
  };

  var importTasksStates = {
    new: {
      id: 'new',
      name: 'New',
      labelStyle: 'info',
      borderStyle: 'info'
    },
    processing: {
      id: 'processing',
      name: 'Processing',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    success: {
      id: 'success',
      name: 'Completed',
      labelStyle: 'success',
      borderStyle: 'complete'
    },
    failure: {
      id: 'failure',
      name: 'Failed',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    aborted: {
      id: 'aborted',
      name: 'Aborted',
      labelStyle: 'danger',
      borderStyle: 'danger'
    }
  };

  angular.module('component.importer')
    .constant('TASK_NAMES', taskNames)
    .constant('IMPORT_TASKS_STATES', importTasksStates);
})();
