(function() {
  'use strict';

  function ReportFactory(
    $q,
    MultiReport,
    SingleReport,
    Reports,
    Roles,
    Utils,
    Version,
    SINGLE_REPORT_ATTRS
  ) {
    var Report = function(group, options) {
      Version.call(this, group);
      this.options = options || {};
      this.service = Reports;
    };

    Utils.extends(Report, Version);

    /**
     * Load the report from database so that it can be acted upon
     * @param  {id} docId Document Id
     * @return {Promise}       Returns the report object
     */
    Report.prototype.load = function(docId) {
      var _this = this;
      return Reports.getGroupByItemId(docId, true)
        .then(function(group) {
          _this.init(group);
          return _this;
        });
    };

    Report.prototype.loadByGroup = function(groupId) {
      var _this = this;
      return Reports.getGroupById(groupId)
        .then(function(group) {
          _this.init(group);
          return _this;
        });
    };

    Report.prototype.find = Report.prototype.load;

    Report.getBaseFormFields = function(disableAll) {
      var fields = [
        { id: 'title', type: 'string', label: 'Report name', required: true },
        {
          id: 'roles',
          type: 'discrete_multiple',
          label: 'Only users with these roles will have visibility of this report',
          required: false,
          options: Roles.findAll()
            .then(function(roles) {
              var items = _.map(roles, function(item) {
                return { _id: item.doc._id, key: item.doc._id, name: item.doc.title };
              });
              items.splice(0, 0, { _id: '__all__', key: '__all__', name: 'All roles' });
              return items;
            })
        },
        {
          id: 'enablePDF',
          type: 'boolean',
          label: 'Allow users to download this report as a PDF',
          required: false,
          disabled: disableAll
        }
      ];

      if (disableAll) {
        return _.map(fields, function(field) {
          field.disabled = true;
          return field;
        });
      }
      return fields;
    };

    Report.prototype.getEventTypeUsage = function() {
      return Reports.getEventTypeUsage(this.doc._id);
    };

    Report.prototype.getSingleOrMultiReportByDoc = function() {
      if (this.doc.elements) {
        return new MultiReport(this.doc, this.options);
      }

      // the conf and the doc cannot be dissociated otherwise the two ways binding won't work.
      var data = {
        reportId: this.doc._id,
        conf: this.doc
      };
      return new SingleReport(data, this.options);
    };

    // Multi report
    Report.prototype.switchToMultiReport = function(report) {
      if (report.type !== 'singleReport') {
        return $q.reject('You cannot convert already converted multi report');
      }

      // create elements with the singleReport conf
      var singleReport = report,
          reportElement = MultiReport.getDefaultMultiReportElement('report');
      reportElement.conf = angular.copy(singleReport.conf);
      this.doc.elements = [reportElement];
      var multiReport = new MultiReport(this.doc);

      // remove singleReport attrs from doc
      var _this = this;
      _.forEach(SINGLE_REPORT_ATTRS, function(attr) {
        var value = _this.doc[attr];
        if (!_.isUndefined(value)) {
          delete _this.doc[attr];
        }
      });

      return $q.when(multiReport);
    };

    Report.prototype.getForm = function(options) {
      var report = this.getSingleOrMultiReportByDoc();
      return report.getForm(options);
    };

    Report.prototype.getFields = function() {
      var report = this.getSingleOrMultiReportByDoc();
      return report.getFields();
    };

    Report.prototype.getDefaultModel = function() {
      var report = this.getSingleOrMultiReportByDoc();
      return report.getDefaultModel();
    };

    Report.prototype.validate = function() {
      var errors = [];
      if (!this.doc.title) {
        errors.push('Please provide a name');
      }

      var report = this.getSingleOrMultiReportByDoc();
      return report.validate()
        .then(function(reportErrors) {
          errors = errors.concat(reportErrors);
          return errors;
        });
    };

    return Report;
  }

  ReportFactory.$inject = [
    '$q',
    'MultiReportFactory',
    'SingleReportFactory',
    'ReportTemplatesService',
    'RolesService',
    'UtilsService',
    'VersionFactory',
    'SINGLE_REPORT_ATTRS'
  ];

  angular.module('component.reports')
    .factory('ReportFactory', ReportFactory);
})();
