(function() {
  'use strict';

  function EventSectionPreviewController(
    $q,
    $scope,
    $stateParams,
    $uibModalInstance,
    Auth,
    EventSectionEdit,
    Notify,
    Users,
    Utils,
    eventSection,
    options
  ) {
    var ctrl = this;
    ctrl.modal = $uibModalInstance;
    ctrl.remoteUser = Users.remoteUser;
    ctrl.eventOwner = $stateParams.user || Auth.currentUser();

    options = options || {};
    ctrl.actions = options.actions || [];
    ctrl.secondaryActions = options.secondaryActions || [];

    _.forEach([ctrl.actions, ctrl.secondaryActions], function(actions) {
      _.forEach(actions, function(action) {
        if (_.isFunction(action.onClick)) {
          var old = action.onClick;
          action.onClick = function() {
            return $q.when(old())
              .then(function(res) {
                if (action.dismiss) {
                  $uibModalInstance.dismiss(res);
                } else {
                  $uibModalInstance.close(res);
                }
              });
          };
        }

        if (action.href) {
          action.mouseUp = function() {
            ctrl.modal.close();
          };
        }
      });
    });

    function loadOriginalEvent() {
      ctrl.originalEvent = ctrl.eventSection.loadAdditionalActions(
        angular.copy(ctrl.eventSection.originalEvent)
      );
    }

    ctrl.username = $stateParams.user || Auth.currentUser();

    ctrl.eventSection = eventSection;
    var promise = $q.when();

    if (options.noFillin) {
      ctrl.canFillIn = false;
    } else {
      promise = promise
        .then(function() {
          return eventSection.checkPermission('canFillIn');
        })
        .then(function() {
          ctrl.canFillIn = !eventSection.isQuickEvent();
        })
        .catch(function() {
          ctrl.canFillIn = false;
        });
    }
    // Handle canFillIn permission
    promise
      .then(function() {
        if (eventSection.doc.state === 'merged') {
          ctrl.eventSection = eventSection;
        } else {
          return EventSectionEdit.init('edit', {
            eventSection: eventSection
          }, { noUpdateLocation: false })
            .then(function(options) {
              ctrl.eventSection = EventSectionEdit.eventSection;
              ctrl.eventExtras = EventSectionEdit.eventExtras;
              ctrl.actionTitle = EventSectionEdit.getActionTitle();
              ctrl.options = options;
            })
            .then(function() {
              loadOriginalEvent();
            });
        }
      })
      .then(function() {
        ctrl.authorized = true;
        ctrl.loaded = true;
        ctrl.setupNextSectionWatch();
      })
      .catch(function(error) {
        Utils.showError(error);
      });

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.open = function() {
      var url = eventSection.href;
      window.open(url, '_blank');
    };

    ctrl.mode = 'read';
    ctrl.switchMode = function(mode) {
      ctrl.mode = mode;
    };

    ctrl.fillIn = function() {
      ctrl.switchMode('fillin');
    };

    function confirmationDialogToStartGoals() {
      return Utils.confirm({
        title: 'This will create and start the goals which you have just defined. ' +
          'Would you like to proceed?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Proceed'
      });
    }

    ctrl.save = function(form, action /* either publish or save */) {
      ctrl.loading = true;

      // if the action is draft, set a default title
      var eventSection = ctrl.eventSection;
      var promise = $q.when();

      // We require the form to be valid if it is not a draft
      if (action === 'publish') {
        ctrl.submitted = true;
        form.$setSubmitted();
        ctrl.formIsSubmitted = true;
        if (form.$invalid) {
          Utils.swal({
            title: 'Please ensure all mandatory fields are completed and all errors corrected.',
            type: 'error'
          }, function() {
            ctrl.submitted = false;
          });
          return;
        }
        if (eventSection.containsGoal) {
          promise = promise.then(function() {
            return confirmationDialogToStartGoals();
          });
        }
      }

      promise
        .then(function() {
          return EventSectionEdit.save(action);
        })
        .then(function(statusMessage) {
          Notify.success(statusMessage, 'Success');
          ctrl.loading = false;
          ctrl.justSaved = true;
          ctrl.close();
        })
        .catch(function(error) {
          ctrl.submitted = false;
          if (error && error.status === 510) {
            ctrl.submitted = false;
            return;
          }

          Utils.swal({
            title: (error && error.message) || 'Unknown error',
            type: 'error'
          });
        });
    };

    ctrl.justSaved = false;
    $scope.$on('modal.closing', function() {
      if (ctrl.mode === 'fillin' && !ctrl.justSaved) {
        ctrl.save(ctrl.form, 'save');
      }
    });

    var currentNextSection;
    var handleNext = function handleNextSection() {
      var nextSection = ctrl.eventSection.getNextSection();
      if (nextSection === currentNextSection) {
        return;
      }

      currentNextSection = nextSection;
      if (nextSection) {
        ctrl.nextDefSection = ctrl.eventSection.getDefSection(nextSection);
        // ctrl.eventSection.nextDefSection = ctrl.nextDefSection;
        if (!ctrl.eventSection.doc.nextSection) {
          ctrl.eventSection.doc.nextSection = {};
        }
        if (!ctrl.eventSection.doc.invitation) {
          ctrl.eventSection.doc.invitation = {};
        }
      } else {
        // ctrl.eventSection.nextDefSection = undefined;
        delete ctrl.nextDefSection;
      }
      ctrl.nextFillOnSameDevice = EventSectionEdit.getFillOnSameDevice(ctrl.nextDefSection);
      ctrl.showNextSection = EventSectionEdit.showNextSection(ctrl.nextDefSection);
      if (!ctrl.eventSection.doc.nextInvitation) {
        ctrl.eventSection.doc.nextInvitation = {};
      }

      ctrl.nextSectionVisible = ctrl.nextFillOnSameDevice.enabled &&
        (ctrl.eventSection.doc.invitation || {}).filledOnSameDevice;

      console.log('Next section is', nextSection);
      $scope.$broadcast('KZNextSectionChanged', { idx: 0 });
    };

    var oldNextNextSection;
    var handleNextNext = function handleNextNextSection() {
      // If there is no next section it doesn't make sense looking for
      // another one
      if (!currentNextSection) {
        return;
      }
      var nextSection = ctrl.eventSection.findNextSection(
        currentNextSection, ctrl.eventSection.doc.nextSection
      );

      if (nextSection === oldNextNextSection) {
        return;
      }

      oldNextNextSection = nextSection;
      if (nextSection) {
        ctrl.nextNextDefSection = ctrl.eventSection.getDefSection(nextSection);
        if (!ctrl.eventSection.doc.nextInvitation) {
          ctrl.eventSection.doc.nextInvitation = {};
        }
      } else {
        delete ctrl.nextNextDefSection;
      }

      // FIXME
      // ctrl.showNextSection = EventSectionEdit.showNextSection();

      // ctrl.nextSectionVisible = ctrl.nextFillOnSameDevice.enabled &&
      //   (ctrl.eventSection.doc.invitation || {}).filledOnSameDevice;

      console.log('Next next section is', nextSection);
      $scope.$broadcast('KZNextSectionChanged', { idx: 1 });
    };

    ctrl.setupNextSectionWatch = function() {
      $scope.$watch(function() {
        return ctrl.eventSection.doc;
      }, function() {
        handleNext();
        handleNextNext();
      }, true);
    };

    $scope.$on('PostponedAction', function(_evt, postponedAction) {
      ctrl.eventSection.addAdditionalAction(postponedAction);
      loadOriginalEvent();
    });

    $scope.$on('CancelPostponedActions', function() {
      ctrl.originalEvent = angular.copy(ctrl.eventSection.originalEvent);
    });
  }

  EventSectionPreviewController.$inject = [
    '$q',
    '$scope',
    '$stateParams',
    '$uibModalInstance',
    'AuthService',
    'EventSectionEditService',
    'NotifyService',
    'UsersService',
    'UtilsService',
    'eventSection',
    'options'
  ];

  angular.module('component.events')
    .controller('EventSectionPreviewController', EventSectionPreviewController);
})();
