(function() {
  'use strict';

  function FeaturesService($q, Profile) {
    var service = {};

    service.features = [
      {
        id: 'autosave',
        title: 'Autosave',
        description: 'Allow the system to autosave user work periodically',
        active: true
      },
      {
        id: 'full-screen-mobile-menu',
        title: 'Full screen menu on mobile',
        description: 'Show a fullscreen menu on mobile',
        active: true
      },
      {
        id: 'linking-existing-goals',
        title: 'Linking existing goals',
        description: 'Linking existing goals to goal field',
        active: false
      },
      {
        id: 'tag-blueprints-to-goals',
        title: 'Tag blueprints to goals',
        description: 'Tag blueprints to goals in order to have a tree view',
        active: false
      },
      {
        id: 'blueprint-maps',
        title: 'Blueprint maps',
        description: 'Use maps to automatically tag or assign numerical values',
        active: false
      },
      {
        id: 'turnitin',
        title: 'Anti-plagiarism checks',
        description: 'Allow use of Turnitin check scores',
        active: false
      }
    ];

    service.customisableFeatures = ['blueprint-maps', 'turnitin'];

    service.set = function(features) {
      service.features = features;
    };

    service.getCustomisableFeatures = function() {
      return _.filter(service.features, function(feature) {
        return service.customisableFeatures.indexOf(feature.id) !== -1;
      });
    };

    service.findAll = function() {
      return Profile.findOrganisation()
        .then(function(org) {
          var enabled = org.enabledFeatures || {};
          var disabled = org.disabledFeatures || {};
          var features = angular.copy(service.features);
          _.forEach(features, function(feature) {
            if (_.indexOf(enabled, feature.id) !== -1) {
              feature.active = true;
            }
            if (_.indexOf(disabled, feature.id) !== -1) {
              feature.active = false;
            }
          });
          return features;
        });
    };

    service.find = function(key) {
      return service.findAll()
        .then(function(features) {
          var feature = _.find(features, { id: key });
          if (_.isUndefined(feature)) {
            return $q.reject({
              status: 404,
              message: 'This feature does not exist'
            });
          }

          return feature;
        });
    };

    service.isOn = function(key, opts) {
      var options = opts || {};
      return service.find(key)
        .then(function(feature) {
          if (!feature.active && options.reject) {
            return $q.reject({
              status: 403,
              message: 'This feature is not active'
            });
          }
          return feature.active;
        });
    };

    service.enable = function(key) {
      return service.find(key)
        .then(function(feature) {
          feature.active = true;

          return feature;
        });
    };

    service.disable = function(key) {
      return service.find(key)
        .then(function(feature) {
          feature.active = false;

          return feature;
        });
    };

    return service;
  }

  FeaturesService.$inject = ['$q', 'ProfileService'];

  angular.module('blocks.services')
    .service('FeaturesService', FeaturesService);
})();
