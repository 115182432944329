(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.tasks', {
        url: 'tasks/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }]
        }
      });

      // .state('epf.tasks.index', {
      //   url: '',
      //   views: {
      //     content: {
      //       templateUrl: 'app/components/tasks/list.html',
      //       controller: 'TasksController',
      //       controllerAs: 'tasksCtrl'
      //     }
      //   }
      // });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.tasks')
    .config(config);
})();
