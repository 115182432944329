(function() {
  'use strict';

  // this directive is used to be constructed and destructed once the user click
  // Collapse/Uncollapse (ng-if)

  function TargetLinkedEventsController($q, $rootScope, $scope, $stateParams, $uibModal, Auth,
    EventSearch, Goal, Goals, List, Utils, LocalizationService, EVENT_STATES) {
    var ctrl = this;

    ctrl.EVENT_STATES = EVENT_STATES;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.username = $stateParams.user || Auth.currentUser();
    ctrl.isOwn = Auth.currentUser() === $scope.target.goal.doc.user;
    ctrl.target = $scope.target;
    ctrl.options = $scope.options || {};

    function loadList() {
      // convert the plain list of ids to list of objects for linkedEvents
      var targetDoc = { linkedEvents: $scope.target.linkedEvents };
      var opts = {
        loadLinkedEvents: true,
        includeObj: true,
        onlyPeriod: true
      };
      $scope.target.loadLinkedEvents(targetDoc, opts)
        .then(function(result) {
          var privatesCount = result.privatesCount;
          var targetLinkedEvents = result.events;

          var prom;
          if ($scope.target.goal.isMine()) {
            var eventIds = _.map(targetLinkedEvents, function(result) {
              return result.id;
            });
            prom = Goals.getLinkedEventsToOtherTargets(
              $scope.target.goal.doc._id,
              $scope.target._id,
              { keys: eventIds }
            );
          } else {
            prom = Goals.getLinkedEventsToOtherTargetsFor(
              $scope.target.goal.doc.user,
              $scope.target.goal.doc._id,
              $scope.target._id
            );
          }

          // construct a map between this target.linkedEvents and linked Events on other targets.
          var linkedEvents = prom
            .then(function(allLinkedEvents) {
              return _.map(targetLinkedEvents, function(result) {
                // do not take the eventSection _id, take the event _id for those instead
                var eventId;
                if (result.obj.doc.type === 'event') {
                  eventId = result.obj.doc._id;
                } else {
                  eventId = result.obj.doc.event;
                }

                // assign the targets where the event is linked elsewhere than this target.
                result.obj.linkedTargets = allLinkedEvents[eventId] || [];
                return result.obj;
              });
            });
          return $q.all([$q.when(privatesCount), linkedEvents]);
        })
        .then(function(results) {
          ctrl.linkedEvents = results[1];
          EventSearch.getSearch('dbsearch')
            .then(function(search) {
              ctrl.countableList = new List({
                search: search,
                idField: 'doc._id'
              });
              ctrl.othersList = new List({
                search: search,
                idField: 'doc._id'
              });

              ctrl.options.noItemsText = 'There are no linked events which match this target';
              ctrl.options.hideEndOfListText = true;
              ctrl.options.hideHeaderGroup = true;

              var periodDetails = $scope.target.getPeriodDetails();
              var countable = periodDetails.details.countable;
              var countableLinkedEvents = [],
                  otherLinkedEvents = [];
              _.forEach(ctrl.linkedEvents, function(event) {
                if (_.find(countable, function(itm) {
                  if (event.doc.type === 'eventSection') {
                    return itm.eventId === event.doc.event;
                  }
                  return itm.eventId === event.doc._id;
                })) {
                  countableLinkedEvents.push(event);
                } else {
                  otherLinkedEvents.push(event);
                }
              });

              ctrl.countableList.doLoadItems(countableLinkedEvents);
              ctrl.othersList.doLoadItems(otherLinkedEvents);
              ctrl.deletedCount = periodDetails.details.linkedEvents.length -
                ctrl.linkedEvents.length - ctrl.privatesCount;
            });

          ctrl.privatesCount = results[0];
        })
        .then(function() {
          ctrl.loaded = true;
        });
    }

    // loadList();
    $scope.$watch('target.periodId', function() {
      loadList();
    });

    if (!ctrl.options.hideButtons) {
      var unLinkEvent = function(item) {
        Utils.swal({
          title: 'You are about to remove an event link for this target. ' +
          'Would you like to proceed?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Remove'
        },
        function(isConfirm) {
          if (isConfirm) {
            var eventId;
            if (item.doc.type === 'event') {
              eventId = item.doc._id;
            } else {
              eventId = item.doc.event;
            }

            return $scope.target.unLinkEventFromTarget(eventId)
              .then(function() {
                loadList();
                $rootScope.$broadcast('goalProgressUpdated');
              });
          }
        });
      };

      var alreadyLinked = function(event) {
        $uibModal.open({
          animation: true,
          templateUrl: 'app/components/goals/partials/event-linkedtargets.html',
          controller: ['$scope', '$uibModalInstance', 'event',
            function($scope, $uibModalInstance, event) {
              $scope.event = angular.copy(event);
              $scope.linkedTargets = $scope.event.linkedTargets;
              $scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

              // extract goals Ids and targets Ids
              var goalsIds = _.map($scope.linkedTargets, function(value) {
                return value.goalId;
              });

              var targetIds = _.map($scope.linkedTargets, function(value) {
                return value.targetId;
              });

              $scope.options = { trackBy: '_id', hideButtons: true, hideEndOfListText: true };
              var options = { goalsIds: goalsIds, targetsFilter: targetIds };
              if (!$scope.event.isMine()) {
                options.username = $scope.event.doc.user;
              }
              Goal.findAll(options)
                .then(function(goals) {
                  $scope.goals = goals;
                });

              $scope.dismiss = function() {
                $uibModalInstance.dismiss('cancel');
              };
            }],
          size: 'md',
          resolve: {
            event: function() { return event; }
          }
        });
      };

      ctrl.options.actionButtons = (function(item) {
        return [
          {
            label: 'Unlink this event',
            icon: 'icon-unlink',
            onClick: unLinkEvent.bind(this, item),
            klass: 'text-danger',
            showCondition: $scope.target.goal.checkPermission.bind(
              $scope.target.goal,
              'canWorkAndUnlink'
            )
          },
          {
            label: 'This event is linked to ' + item.linkedTargets.length + ' other targets',
            icon: 'icon-link',
            onClick: alreadyLinked.bind(this, item),
            klass: 'text-danger',
            showCondition: function() {
              return item.linkedTargets.length;
            }
          }
        ];
      }).bind(this);
    } else {
      ctrl.options.actionButtons = function() { return []; };
    }

    $scope.$on('ReloadLinkedEventsTargetList', function() {
      loadList();
    });
  }

  TargetLinkedEventsController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$stateParams',
    '$uibModal',
    'AuthService',
    'EventSearch',
    'GoalFactory',
    'GoalsService',
    'ListFactory',
    'UtilsService',
    'LocalizationService',
    'EVENT_STATES'
  ];

  function TargetLinkedEvents() {
    return {
      scope: {
        target: '=',
        readOnly: '=',
        options: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/target.linkedEvents.html',
      controller: TargetLinkedEventsController,
      controllerAs: 'targetLinkedEventsCtrl'
    };
  }

  angular.module('component.goals')
    .directive('targetLinkedEvents', TargetLinkedEvents)
    .controller('TargetLinkedEventsController', TargetLinkedEventsController);
})();
