(function() {
  'use strict';

  function SlotController($scope, Types, LocalStore, Utils, LocalizationService) {
    var ctrl = this;
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('datetime');
    // this may be also used for the details view, move it if necessary
    ctrl.actionButtons = [
      {
        label: 'View data stored on this device',
        icon: 'icon-view',
        onClick: function() {
          Types.openPreview($scope.item.item.doc);
        },
        klass: 'text-info'
      },
      {
        label: 'View conflicting data stored on Kaizen server',
        icon: 'icon-view',
        onClick: function() {
          Types.openPreview($scope.item.couchdb);
        },
        showCondition: function() {
          return $scope.item.couchdb !== undefined;
        },
        klass: 'text-info'
      },
      {
        label: 'Replace data on server',
        icon: 'icon-save',
        onClick: function() {
          ctrl.forcePush();
        },
        klass: 'text-warning'
      }
    ];

    ctrl.secondaryActions = [
      {
        label: 'Discard data stored on this device',
        icon: 'icon-trash',
        onClick: function() {
          ctrl.remove();
        },
        klass: 'text-danger'
      },
      {
        label: 'Force push',
        icon: 'icon-trash',
        onClick: function() {
          ctrl.forcePush();
        },
        klass: 'text-danger'
      }
    ];

    ctrl.remove = function() {
      var item = $scope.item.item;
      Utils.confirm({
        title: 'Discard data on local device',
        text: 'Remove the data stored on this device and use the version from the server.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      })
        .then(function() {
          return LocalStore.safeRemove(item);
        })
        .then(function() {
          return LocalStore.findAll();
        })
        .then(function(data) {
          var queue = _.find(data, function(itm) {
            return itm.doc.type === 'queue' && itm.doc.related === item._id;
          });

          if (queue !== undefined) {
            return LocalStore.safeRemove(queue);
          }
        })
        .then(function() {
          $scope.$emit('KZItemRemoved', { item: $scope.item });
        });
    };

    ctrl.forcePush = function() {
      Utils.confirm({
        title: 'Replace data on server with data from this device',
        text: 'This action will attempt to replace the data on the Kaizen server with the ' +
              'data stored on this device. This action cannot be undone. Do you wish to continue?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Replace data'
      })
        .then(function() {
          return LocalStore.forcePush($scope.item.item);
        })
        .then(function() {
          $scope.$emit('KZItemRemoved', { item: $scope.item });
        })
        .catch(function(err) {
          if (err && err.status === 510) {
            return;
          }
          $scope.item.error = err;
        });
    };
  }

  SlotController.$inject = ['$scope', 'TypesService',
    'LocalStoreService', 'UtilsService', 'LocalizationService'];

  function SlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/settings/review.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.settings')
    .directive('reviewSlot', SlotDirective)
    .controller('reviewSlotController', SlotController);
})();
