(function() {
  'use strict';

  function RelationController($q, $state, $stateParams, $timeout, Relations, Auth, Utils, Security,
                              Notify, Blueprints, TREE_OPTIONS) {
    var ctrl = this;

    ctrl.hasEditPermission = false;
    Security.hasPermission('relations.edit')
      .then(function(has) {
        ctrl.hasEditPermission = has;
      });

    ctrl.tree = {
      options: _.assign(angular.copy(TREE_OPTIONS), { limit: 0 }),
      template: { id: '_id', title: 'name', nodes: 'categories' }
    };

    ctrl.relation = { categories: [] };
    if ($stateParams.defaults) {
      ctrl.relation = $stateParams.defaults;
    }

    ctrl.blueprints = [];
    ctrl.blueprintTrees = {};

    // local storage for currently selected blueprint categories
    ctrl.bCategories = {};

    function buildPathMap(nodes) {
      var map = {};
      function loopSubNodes(subNodes, currentPath) {
        var path = angular.copy(currentPath);
        _.forEach(subNodes, function(subNode) {
          map[subNode._id] = path;

          if (subNode.categories && subNode.categories.length) {
            var deeperPath = angular.copy(path);
            deeperPath.push(subNode._id);
            loopSubNodes(subNode.categories, deeperPath);
          }
        });
      }

      loopSubNodes(nodes, []);
      return map;
    }

    function setupBlueprints(relCategory) {
      // Setup scope.categories at the page load
      ctrl.bCategories[relCategory._id] = {
        blueprint: '',
        selectedCategories: []
      };

      // iterate through all blueprints set for this relation category
      var selectedCategories = _.reduce(relCategory.blueprints, function(pre, cur) {
        return pre.concat(cur);
      });
      ctrl.bCategories[relCategory._id].selectedCategories = selectedCategories;

      // for (var blpId in relCategory.blueprints) {
      //   if (relCategory.blueprints.hasOwnProperty(blpId)) {
      //     ctrl.updateTree(relCategory._id, blpId);
      //   }
      // }

      // process sub categories
      relCategory.categories.forEach(function(relSubCategory) {
        setupBlueprints(relSubCategory);
      });
    }

    // Load a relation and all available blueprints
    $q.all([
      $stateParams.id ? Relations.getForEdit($stateParams.id) : $q.when(ctrl.relation),
      Blueprints.findDiscreteOnly()
    ]).then(function(result) {
      ctrl.relation = result[0];
      ctrl.blueprints = [];

      ctrl.blueprintTrees = result[1];

      // setup the blueprints stuff
      ctrl.relation.categories.forEach(function(relCategory) {
        setupBlueprints(relCategory);
      });
    })
    .then(function() {
      ctrl.pathMap = buildPathMap(ctrl.relation.categories);
    })
    .then(function() {
      if ($stateParams.id) {
        Utils.setPageTitle('Edit relation: ' + ctrl.relation.name);
      } else {
        Utils.setPageTitle('New relation');
      }
      Relations.getEventTypeUsage($stateParams.id)
        .then(function(data) {
          ctrl.eventTypes = data;
        })
        .catch(function(err) {
          ctrl.error = err;
        });
      ctrl.loaded = true;
    });

    ctrl.loadAuditlog = function() {
      Relations.getAuditlog($stateParams.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };

    // this is called at the begining and then when blueprint is changed in dropdown
    ctrl.updateTree = function(relCategoryId, blueprintId, oldBlueprintId) {
      if (blueprintId) {
        ctrl.blueprints.forEach(function(blueprint) {
          if (blueprintId === blueprint._id) {
            if (oldBlueprintId && oldBlueprintId !== blueprint._id) {
              ctrl.bCategories[relCategoryId].selectedCategories = [];
            }

            return;
          }
        });
      }

      return [];
    };

    // change ctrl.bCategories to ctrl.relation.blueprints format
    function saveBlueprints(relCategory) {
      relCategory.blueprints = {}; // clean up old values
      var category = ctrl.bCategories[relCategory._id];
      if (category && category.selectedCategories) {
        return $q.all(
            _.map(category.selectedCategories, function(catId) {
              return Blueprints.findByCategoryId(catId, true);
            })
        )
          .then(function(results) {
            _.forEach(results, function(blueprint) {
              if (_.isUndefined(blueprint)) {
                return;
              }

              if (_.isUndefined(relCategory.blueprints[blueprint.rootId])) {
                relCategory.blueprints[blueprint.rootId] = [];
              }

              relCategory.blueprints[blueprint.rootId].push(blueprint.key);
            });
          })
          .then(function() {
            return $q.all(
              _.map(relCategory.categories, function(subCat) {
                return saveBlueprints(subCat);
              })
            );
          });
      }

      return $q.when();
    }

    ctrl.save = function(isValid) {
      var error;
      if (!ctrl.hasEditPermission) {
        error = 'You don\'t have the permission to perform that action';
      } else if (!isValid) {
        error = 'Form not valid';
      }

      if (error) {
        Utils.showError({ message: error });
        return;
      }

      ctrl.formIsSubmitted = true;

      if (isValid) {
        var actionName = 'modified';
        if (ctrl.relation._rev === undefined) {
          actionName = 'created';
        }
        Utils.recordLog(ctrl.relation, actionName, Auth.currentUser());

        ctrl.relation.type = 'relation'; // Make sure this is relation and not something else
        ctrl.relation._id = ctrl.relation._id ? ctrl.relation._id : Utils.guid();
        ctrl.relation.organisation = Auth.currentOrganisation();

        // store id's of selected blueprint categories to every Relation category
        $q
          .all(
            _.map(ctrl.relation.categories, function(cat) {
              return saveBlueprints(cat);
            })
          )
          .then(function() {
            return Relations.save(ctrl.relation);
          })
          .then(function() {
            Notify.success('Your relation was saved successfully.', 'Success!');
            $state.go('epf.relations.index');
          })
          .catch(Utils.showError);
      }
    };

    ctrl.remove = function() {
      if (!ctrl.hasEditPermission) {
        Utils.showError({ message: 'You don\'t have the permission to perform that action' });
        return;
      }

      Utils.swal({
        title: 'Are you sure you want to remove this relation?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (!isConfirm) {
          return;
        }

        Relations.remove($stateParams.id)
          .then(function() {
            $state.go('epf.relations.index');
          })
          .catch(function(err) {
            $timeout(function() {
              Utils.showError(err);
            }, 100);
          });
      });
    };

    ctrl.addCategory = function() {
      ctrl.relation.categories = ctrl.relation.categories ? ctrl.relation.categories : [];
      ctrl.relation.categories.push({
        _id: Utils.guid(),
        name: '',
        categories: [],
        blueprints: {}
      }
      );
    };

    ctrl.removeNode = function(scope) {
      scope.remove();
    };

    ctrl.updateNodeEnability = function(scope, isDisabled) {
      // enable/disable all nodes and sub-nodes
      var node = scope.$modelValue;
      node.disabled = isDisabled;

      function disableSubNodes(subNodes) {
        _.forEach(subNodes, function(subNode) {
          if (subNode.categories && subNode.categories.length) {
            disableSubNodes(subNode.categories);
          }

          subNode.disabled = isDisabled;
        });
      }

      disableSubNodes(node.categories);

      // update parent node only when enabling
      function searchNode(idTosearch, list) {
        return _.find(list, function(node) {
          return node._id === idTosearch;
        });
      }

      if (!isDisabled) {
        var path = ctrl.pathMap[node._id];
        var parentNode = {};
        _.forEach(path, function(nodeId) {
          var list = parentNode.categories;
          if (_.isEmpty(parentNode)) {
            list = ctrl.relation.categories;
          }

          parentNode = searchNode(nodeId, list);
        });

        parentNode.disabled = isDisabled;
      }
    };

    ctrl.toggleNode = function(scope) {
      scope.toggle();
    };

    ctrl.newSubNode = function(scope) {
      var nodeData = scope.$modelValue;
      nodeData.categories.push({
        _id: Utils.guid(),
        name: '',
        categories: [],
        blueprints: {}
      });

      ctrl.pathMap = buildPathMap(ctrl.relation.categories); // reload the map
    };

    ctrl.isObjectEmpty = function(obj) {
      return _.isEmpty(obj);
    };
  }

  RelationController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    '$timeout',
    'RelationsService',
    'AuthService',
    'UtilsService',
    'SecurityService',
    'NotifyService',
    'BlueprintsService',
    'TREE_OPTIONS'
  ];

  angular.module('component.relations')
    .controller('RelationController', RelationController);
})();
