(function() {
  'use strict';

  function GoalFactory(
    $q,
    $uibModal,
    $state,
    $log,
    Goals,
    Auth,
    Event,
    Events,
    EventSection,
    EventLight,
    EventType,
    Form,
    GoalSearch,
    List,
    Target,
    Utils,
    Security,
    kzStorage,
    GOALS_STATES,
    EVENT_STATES
  ) {
    var Goal = function(doc) {
      this.doc = doc || {};
      this.state = '';
      this.targets = [];
      this.targetsByPeriods = {};
      this.completedTargets = 0;
      this.avgRate = 0;
      this.totalLinkedEvents = 0;

      this.event = {};
      this.eventState = ''; // to groupBy
      this.eventOrder = ''; // to groupBy
      this.isTheSetIAmInResolved = false;
      this.resolveData = {};
      this.linked = {};
    };

    Goal.setDefaults = function() {
      return {
        _id: Utils.guid(),
        type: 'goal',
        title: '',
        description: '',
        targets: []
      };
    };
    Goal.prototype.initialise = function(options) {
      options = options || {};
      var goal = this;
      var doc = goal.doc;
      if (options.light) {
        goal.lightTargets = options.light.targets;
        goal.lightEvent = new EventLight(options.light.event);
        goal.event = goal.lightEvent;
        goal.goalSetSummary = options.light.event.goalSetSummary;
      }
      if (goal.loaded) {
        return $q.when(this);
      }

      goal.state = goal.getState();

      // load event if necessary
      var prom = $q.when();
      if (options.loadEvent) {
        prom = goal.loadEvent({ id: doc.eventId });
      }

      return prom
        .then(function() {
          // load event state and event order
          goal.eventState = goal.getEventState();

          var order;
          if (goal.eventState === EVENT_STATES.INPROGRESS.id) {
            order = 1;
          } else {
            order = 2;
          }
          goal.eventOrder = order;
        })
        .then(function() {
          if (options.loadGoalSetSummary) {
            return Goals.getSummary(doc.eventId, doc.eventSectionId)
              .then(function(summary) {
                goal.goalSetSummary = summary;
              })
              .catch(function() {
                // in case we are offline don't fail badly
              });
          }

          return $q.when();
        })
        .then(function() {
          return goal.getViewLink();
        })
        .then(function(href) {
          goal.href = href;
        })
        .then(function() {
          if (!options.doNotLoadTargets) {
            return goal.getTargets()
              .then(function(targets) {
                goal.allTargets = targets;
              });
          }
        })
        .then(function() {
          goal.setPeriod();
          goal.loaded = true;
          $log.debug('Initiating goal', doc._id, 'DONE');
          return goal;
        })
        .catch(Utils.showError);
    };

    Goal.init = function(doc, options) {
      $log.debug('Initiating goal', doc._id);
      options = options || {};

      var goal = new Goal(doc);
      return goal.initialise(options);
    };

    Goal.findAll = function(kzOptions) {
      kzOptions = kzOptions || {};

      var func,
          args;

      if (kzOptions.username) {
        func = Goals.findAllFor;
        args = [kzOptions.username];
        if (kzOptions.goalsIds) {
          func = Goals.findAllByGoalsIdsFor;
          args = args.concat([kzOptions.goalsIds]);
        } else if (kzOptions.eventId && kzOptions.sectionId) {
          func = Goals.findAllByEventAndSectionFor;
          args = args.concat([kzOptions.eventId, kzOptions.sectionId]);
        }
      } else if (kzOptions.goalsIds) {
        func = Goals.findAllByGoalsIds;
        args = [kzOptions.goalsIds];
      } else if (kzOptions.eventId && kzOptions.sectionId) {
        func = Goals.findAllByEventAndSection;
        args = [kzOptions.eventId, kzOptions.sectionId];
      } else {
        func = Goals.findAll;
        args = [];
      }
      return func.apply(Goals, args)
        .then(function(goals) {
          var proms = _.chain(goals)
            .sortBy(function(goal) {
              return goal.doc.order;
            })
            .map(function(goal) {
              var options = kzOptions || {};
              options.loadEvent = options.loadEvent === undefined ? true : options.loadEvent;
              return Goal.init(goal.doc, options);
            })
            .value();

          return $q.all(proms);
        })
        .then(function(goals) {
          Goals.cachedEvents = {}; // reset cache
          return goals;
        });
    };

    Goal.findLinkableTargets = function(options) {
      return Goal.findAll(options)
        .then(function(goals) {
          return $q.all(_.map(goals, function(goal) {
            return goal.getTargets();
          }));
        })
        .then(function(data) {
          var targets = _.reduce(data, function(sum, item) {
            return sum.concat(item);
          }, []);

          return _.filter(targets, function(target) {
            var conditions = [];
            if (!options.generalTargets && options.eventType) {
              conditions.push(target.getRelevantEventTypes().indexOf(options.eventType) !== -1);
            }

            if (options.generalTargets) {
              conditions.push(_.isEmpty(target.conditions));
            }

            return _.some(conditions);
          });
        })
        .then(function(targets) {
          return Utils.asyncFilter(targets, function(target) {
            return target.goal.loadEvent({ id: target.goal.doc.eventId })
              .then(function() {
                return !target.goal.isSetClosed();
              });
          });
        });
    };

    Goal.find = function(goalId, username, options) {
      options = options || {};

      var func = Goals.find;
      var args = [goalId];
      if (username) {
        func = Goals.findFor;
        args = [goalId, username];
      }

      return func.apply(Goals, args)
        .then(function(goal) {
          return Goal.init(
            goal,
            {
              loadEvent: true,
              doNotLoadTargets: options.doNotLoadTargets,
              loadGoalSetSummary: options.loadGoalSetSummary
            }
          );
        });
    };

    Goal.areAllGoalsResolved = function(eventId, sectionId, username) {
      var options = { eventId: eventId, sectionId: sectionId, username: username };

      options.loadEvent = false;
      return Goal.findAll(options)
        .then(function(goals) {
          var areAllResolved = _.chain(goals)
            .map(function(goal) {
              return goal.isResolved();
            })
            .every()
            .value();

          var lastGoal = _.last(goals),
              lastGoalId;
          if (!_.isUndefined(lastGoal)) {
            lastGoalId = lastGoal.doc._id;
          }

          return {
            areAllGoalsResolved: areAllResolved,
            totalGoals: goals.length,
            lastGoalId: lastGoalId // to load the postTemplate or not.
          };
        });
    };

    Goal.getGoalsByEventIdAndFieldId = function(goals) {
      var goalsByEventIdAndFieldId = {};
      _.forEach(goals, function(goal) {
        if (_.isUndefined(goalsByEventIdAndFieldId[goal.doc.eventId])) {
          goalsByEventIdAndFieldId[goal.doc.eventId] = {};
        }

        if (_.isUndefined(goalsByEventIdAndFieldId[goal.doc.eventId][goal.doc.eventFieldId])) {
          goalsByEventIdAndFieldId[goal.doc.eventId][goal.doc.eventFieldId] = [];
        }

        goalsByEventIdAndFieldId[goal.doc.eventId][goal.doc.eventFieldId].push(goal);
      });

      _.forEach(goalsByEventIdAndFieldId, function(fields, eventId) {
        _.forEach(fields, function(goals, fieldId) {
          var areAllResolved = _.chain(goals)
            .map(function(goal) {
              return goal.isResolved();
            })
            .every()
            .value();

          goalsByEventIdAndFieldId[eventId][fieldId] = {
            isTheSetIAmInResolved: areAllResolved,
            lastGoalId: _.last(goals).doc._id
          };
        });
      });

      return $q.when(goalsByEventIdAndFieldId);
    };

    Goal.getSetPaginatedList = function(goals, listOpt) {
      return Goal.getGoalsByEventIdAndFieldId(goals)
        .then(function(goalsByEventIdAndFieldId) {
          return _.map(goals, function(goal) {
            if (goal.doc.eventId && goal.doc.eventFieldId) {
              var data = goalsByEventIdAndFieldId[goal.doc.eventId][
                goal.doc.eventFieldId
              ];

              goal.isTheSetIAmInResolved = data.isTheSetIAmInResolved;
            }

            return goal;
          });
        })
        .then(function(goals) {
          var opts = {
            idField: 'doc._id',
            search: GoalSearch.dbsearch(),
            pagination: 'links',
            findOptions: { limit: 10 }
          };

          opts = _.assign(opts, listOpt || {});
          var list = new List(opts);

          return list.doLoadItems(goals)
            .then(function() {
              var goToPage = function(page) {
                list.goToPage(+page);
              };
              return { list: list, goToPageFunc: goToPage };
            });
        });
    };

    Goal.getActions = function(eventId, sectionId, fieldId, user) {
      return [
        {
          label: 'Add more goals',
          icon: 'icon-plus',
          klass: 'btn btn-xs btn-success',
          href: (function() {
            var route = 'epf.goals.add';
            var args = {
              eventId: eventId,
              sectionId: sectionId,
              fieldId: fieldId
            };

            if (Auth.currentUser() !== user) {
              route = 'epf.users.goals-add';
              args.user = user;
            }

            return $state.href(route, args);
          })(),
          showCondition: function() {
            var event = new Event();
            return event.load(eventId, user)
              .then(function(event) {
                return event.checkPermission('canAddGoals');
              });
          }
        }
      ];
    };

    Goal.prototype.getState = function() {
      if (this.doc.state) {
        return this.doc.state;
      }

      return GOALS_STATES.inProgress.id;
    };

    Goal.prototype.loadEvent = function(eventOptions) {
      var _this = this;
      var event = new Event({}, { noExtra: true });

      function setEvent(e, doc, goal) {
        return e.init(doc)
          .then(function() {
            goal.event = e;
            Goals.cachedEvents[e.doc._id] = e;
          });
      }

      return this.hasPerm('viewEvent')
        .then(function() {
          _this.canViewEvent = true;

          if (eventOptions.doc) {
            return setEvent(event, eventOptions.doc, _this);
          } else if (_this.doc.event_doc) {
            return setEvent(event, _this.doc.event_doc, _this);
          } else if (eventOptions.id) {
            if (Goals.cachedEvents[eventOptions.id]) {
              _this.event = Goals.cachedEvents[eventOptions.id];
              return $q.when();
            }

            var func;
            if (!_this.isMine()) {
              func = Events.findFor(eventOptions.id, _this.doc.user);
            } else {
              func = Events.find(eventOptions.id);
            }

            return func
              .then(function(data) {
                var doc = data;
                if (!_this.isMine()) {
                  doc = data.doc;
                }

                return setEvent(event, doc, _this);
              })
              .catch(function(error) {
                // it has been deleted? still load the goal
                if (error.status === 404) {
                  return $q.when();
                }
                console.log('Could not init goal', error);
              });
          }
        })
        .catch(function(error) {
          if (error.status === 403) {
            _this.canViewEvent = false;
          }
        });
    };

    Goal.prototype.getCurrentPeriodId = function() {
      if (_.isUndefined(this.doc.periods)) {
        return;
      }

      if (this.doc.periods.length === 0) {
        return;
      }

      var currentPeriods = kzStorage.getItem('selectedPeriod');
      var periodId = currentPeriods[this.doc._id];
      if (periodId === '__others__') {
        return periodId;
      }

      var period = _.find(this.doc.periods, { _id: periodId });
      if (period === undefined) {
        period = this.doc.periods[0];
      }

      return period._id;
    };

    Goal.prototype.getEventState = function() {
      if (this.doc.event_state) {
        return this.doc.event_state; // from ES
      }

      if (!_.isEmpty(this.event)) {
        return this.event.doc.state;
      }

      if (this.lightEvent) {
        return this.lightEvent.doc.state;
      }
    };

    function getTargetsByPeriods(targets) {
      var targetsByPeriods = { all: [] };
      _.forEach(targets, function(target) {
        targetsByPeriods.all.push(target);
        if (
          !_.isUndefined(target.conditions) &&
          !_.isEmpty(target.conditions) &&
          !_.isUndefined(target.conditions[0].periodMeasurements)
        ) {
          _.forEach(target.conditions[0].periodMeasurements, function(periodMeasurement) {
            var targetCopied = angular.copy(target);
            if (_.isUndefined(targetsByPeriods[periodMeasurement.period])) {
              targetsByPeriods[periodMeasurement.period] = [];
            }

            targetsByPeriods[periodMeasurement.period].push(targetCopied);
          });
        } else {
          if (_.isUndefined(targetsByPeriods.others)) {
            targetsByPeriods.others = [];
          }

          targetsByPeriods.others.push(target);
        }
      });

      return targetsByPeriods;
    }

    /**
     * get targets depending on the periodId or not
     * @param options {targetsFilter, periodId}
     * @returns Targets
     */
    Goal.prototype.getTargets = function(options) {
      options = options || {};

      var targets;
      if (this.lightTargets) {
        targets = angular.copy(this.lightTargets);
        options.usingLight = true;
      } else {
        targets = angular.copy(this.doc.targets);
      }

      if (options.targetsFilter) {
        targets = _.filter(targets, function(target) {
          return options.targetsFilter.indexOf(target._id) > -1;
        });
      }

      var _this = this;
      var targetsByPeriods = getTargetsByPeriods(targets);
      this.targetsByPeriods = targetsByPeriods;
      targets = targetsByPeriods.all;
      if (options.periodId) {
        targets = targetsByPeriods[options.periodId];
      }

      var proms = _.map(targets, function(target) {
        return Target.init(target, _this, options);
      });

      return $q.all(proms);
    };

    Goal.prototype.getTargetsAndCalculateGoalProgress = function(periodId) {
      var _this = this;
      return this.getTargets({ periodId: periodId })
        .then(function(targets) {
          _this.targets = targets;
          _this.setPeriod(periodId);
        });
    };

    Goal.prototype.setPeriod = function(periodId) {
      var usingCurrent = false;
      if (periodId === undefined) {
        periodId = this.getCurrentPeriodId();
        usingCurrent = true;
      }
      this.targets = _.filter(this.allTargets, function(target) {
        return target.setPeriod(periodId);
      });
      this.completedTargets = this.getCompletedTargets();
      this.totalLinkedEvents = this.getTotalLinkedEvents();
      this.avgRate = this.getAvgRate();
      this.totals = this.getProgress();

      if (!usingCurrent) {
        var currentPeriods = kzStorage.getItem('selectedPeriod');
        currentPeriods[this.doc._id] = periodId;
        kzStorage.setItem('selectedPeriod', currentPeriods);
      }
    };

    Goal.prototype.getTargetById = function(targetId, periodId) {
      var targetDoc = _.find(this.doc.targets, function(target) {
        return target._id === targetId;
      });

      return Target.init(
        targetDoc,
        this,
        { periodId: periodId, loadLinkedEvents: true }
      );
    };

    Goal.prototype.getCompletedTargets = function() {
      return _.filter(this.targets, function(target) {
        return target.isCompleted();
      });
    };

    Goal.prototype.getTotalLinkedEvents = function() {
      // console.error('Obsolete: need to use countableLinkedEvents now');

      var totalLinkedEvents = 0;
      _.forEach(this.targets, function(target) {
        var details = target.getDetails();
        var linkedEvents = details.countable || [];
        totalLinkedEvents += linkedEvents.length;
      });

      return totalLinkedEvents;
    };

    Goal.prototype.getProgress = function() {
      var targets = this.targets;
      var generalTargets = _.filter(targets, function(target) {
        var progress = target.progress;
        return progress.max === 0;
      });

      if (targets.length === 0) {
        return {
          type: 'none',
          value: null
        };
      }

      if (generalTargets.length === 0) {
        return {
          type: 'ratio',
          value: this.getAvgRate()
        };
      }

      if (generalTargets.length === targets.length) {
        return {
          type: 'total',
          value: this.getTotalValue()
        };
      }

      // Mixed targets case:
      // It is the same as if they are the same but we are not confident enough
      // this is the best solution. lets leave it as a separate option that can easily
      // be changed/
      return {
        type: 'total',
        value: this.getTotalValue()
      };
    };

    Goal.prototype.getTotalValue = function() {
      var targets = this.targets;

      var sum = _.chain(targets)
      .map(function(target) {
        return target.progress.progress;
      })
      .reduce(function(pre, cur) {
        return pre + cur;
      })
      .value();

      return sum;
    };

    Goal.prototype.getAvgRate = function() {
      var generalTargets = _.filter(this.targets, function(target) {
        return target.progress.max === 0;
      });

      if (!_.isEmpty(generalTargets)) {
        return;
      }

      // get the rate from each target and get the avg
      var sum = _.chain(this.targets)
        .map(function(target) {
          return target.progress.ratio;
          // minEventsToBeLinked cannot be zero as it is filtered above
          // var ratio = target.progress / target.minEventsToBeLinked;
          // return ratio;

          // // filter by periods if needed
          // var countableLinkedEvents = target.doc.countableLinkedEvents;
          // if (_this.periodId) {
          //   countableLinkedEvents = _.filter(target.doc.countableLinkedEvents,
          //                                    function(linkedEvent)
          //   {
          //     return linkedEvent.periodId === _this.periodId;
          //   });
          // }

          // var ratio = countableLinkedEvents.length / target.minEventsToBeLinked;
          // if (ratio > 1) {
          //   ratio = 1;
          // }
          // return ratio;
        })
        .reduce(function(pre, cur) {
          return pre + cur;
        })
        .value();

      return Utils.getRate(sum, this.targets.length);
    };

    Goal.prototype.getPeriodById = function(periodId) {
      return _.find(this.doc.periods || [], function(period) {
        return period._id === periodId;
      });
    };

    // Booleans
    Goal.prototype.areAllFromThisGoalSetResolved = function() {
      var username = !this.isMine() ? this.doc.user : undefined;
      return Goal.areAllGoalsResolved(this.doc.eventId, this.doc.eventSectionId, username);
    };

    Goal.prototype.hasState = function(state) {
      return this.state === state;
    };

    Goal.prototype.isCompleted = function() {
      return this.completedTargets.length === this.targets.length;
    };

    Goal.prototype.isSetClosed = function() {
      if (this.goalSetSummary) {
        return this.goalSetSummary.isClosed;
      }

      if (this.eventState === EVENT_STATES.INPROGRESS.id) {
        var currentSection = this.event.currentSection;
        if (currentSection) {
          return this.doc.eventSectionId !== currentSection._id;
        }
      }

      return true;
    };

    Goal.prototype.isMine = function() {
      return Auth.currentUser() === this.doc.user;
    };

    Goal.prototype.isOverdue = function() {
      var dueDate = this.doc.dueDate;
      var today = new Date().toISOString().slice(0, 10);
      return dueDate < today;
    };

    Goal.prototype.isPredefined = function() {
      var sections = this.event.eventType.sections;
      var sectionId = this.doc.eventSectionId;
      var defField = EventType.getGoalField(sections, sectionId);
      var predefIds = _.map(defField.definition, function(def) {
        return def._id;
      });

      return _.indexOf(predefIds, this.doc.originalId) > -1;
    };

    Goal.prototype.isPrivate = function() {
      return this.doc.visibility === 'private';
    };

    Goal.prototype.isResolved = function() {
      return !_.isUndefined(this.doc.state);
    };

    Goal.prototype.isMarkStepEnabled = function() {
      if (this.goalSetSummary) {
        return $q.when(this.goalSetSummary.isMarkStepEnabled);
      }

      if (_.isEmpty(this.event)) {
        return false;
      }

      var sections = this.event.eventType.sections;
      var sectionId = this.doc.eventSectionId;
      var defField = EventType.getGoalField(sections, sectionId);
      if (!defField.markStep) {
        defField.markStep = 'always';
      }

      return defField.markStep !== 'never' && !defField.canAutoClose;
    };

    // methods
    Goal.prototype.getIndexOfTargetById = function(targetId) {
      var index = _.indexOf(_.map(this.doc.targets, '_id'), targetId);
      if (index === -1) {
        return $q.reject({ status: 404, message: 'Target not found' });
      }

      return $q.when(index);
    };

    Goal.prototype.updateTarget = function(targetDoc) {
      var _this = this;
      return this.getIndexOfTargetById(targetDoc._id)
        .then(function(index) {
          _this.doc.targets[index] = targetDoc;
        });
    };

    Goal.prototype.saveTarget = function(targetDoc) {
      var _this = this;
      return this.updateTarget(targetDoc)
        .then(function() {
          return _this.save();
        });
    };

    Goal.prototype.openResolveDataModal = function() {
      var _this = this;

      var def = $q.defer();

      var states = _.chain(GOALS_STATES)
        .filter(function(state) {
          return state.id !== GOALS_STATES.inProgress.id;
        })
        .map(function(state) {
          return { _id: state.id, name: state.name };
        })
        .reverse()
        .value();

      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/goals/partials/goal-resolve.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'goal',
          'form',
          function($scope, $uibModalInstance, goal, form) {
            $scope.goal = angular.copy(goal);

            $scope.form = form;

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            var save = function() {
              goal.doc = angular.copy($scope.goal.doc);

              var dataToSave = {
                _id: goal.doc._id,
                state: goal.doc.state,
                resolveComment: goal.doc.resolveComment
              };
              return goal.save({ updateResolution: true, dataToSave: dataToSave })
                .then(function() {
                  $scope.dismiss();
                  def.resolve();
                })
                .then(function() {
                  return goal.closeIfPossible();
                })
                .catch(function(error) {
                  if (error.status === 403) {
                    console.log('cannot close because the user has not the right permissions.');
                    return;
                  }
                  Utils.showError(error);
                });
            };

            $scope.resolve = function(isValid) {
              if (!isValid) {
                return $q.reject({ message: 'form not valid' });
              }
              save();
            };
          }
        ],
        size: 'md',
        resolve: {
          goal: function() {
            return _this;
          },
          form: function() {
            return new Form([
              {
                id: 'state',
                type: 'discrete',
                label: 'Mark goal as ',
                required: true,
                options: states
              },
              {
                id: 'resolveComment',
                type: 'text',
                label: 'Comment',
                expressionProperties: {
                  'templateOptions.required': function(_$viewValue, _$modelValue, scope) {
                    if (scope.model.state === 'achieved' && _this.isCompleted()) {
                      return false;
                    } else if (scope.model.state === 'achieved' && !_this.isCompleted()) {
                      return true;
                    }

                    return true;
                  }
                },
                required: !_this.isCompleted()
              }
            ]);
          }
        }
      });

      return def.promise;
    };

    Goal.prototype.openUpdateDueDateModal = function() {
      var _this = this;

      var def = $q.defer();

      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/goals/partials/update-goalset-due-date.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'currentDueDate',
          'eventId',
          'sectionId',
          'form',
          function($scope, $uibModalInstance, currentDueDate, eventId, sectionId, form) {
            $scope.model = { dueDate: currentDueDate };

            $scope.form = form;

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function(isValid) {
              if (!isValid) {
                return;
              }

              Events.updateGoalsetDueDate(eventId, sectionId, $scope.model.dueDate)
                .then(function() {
                  $scope.dismiss();
                  def.resolve();
                })
                .catch(function(err) {
                  $scope.dismiss();
                  def.reject(err);
                });
            };
          }
        ],
        size: 'md',
        resolve: {
          currentDueDate: function() {
            return _this.doc.dueDate;
          },
          eventId: function() {
            return _this.doc.eventId;
          },
          sectionId: function() {
            return _this.doc.eventSectionId;
          },
          form: function() {
            return new Form([
              {
                id: 'dueDate',
                type: 'date',
                label: 'Due date',
                required: true
              }
            ]);
          }
        }
      });

      return def.promise;
    };

    Goal.prototype.openUpdatePeriodsModal = function() {
      var _this = this;

      var def = $q.defer();

      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/goals/partials/update-goalset-periods.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'currentPeriods',
          'eventId',
          'sectionId',
          'form',
          function($scope, $uibModalInstance, currentPeriods, eventId, sectionId, form) {
            $scope.model = { periods: currentPeriods };

            $scope.form = form;

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function(isValid) {
              if (!isValid) {
                return;
              }

              Events.updateGoalsetPeriods(eventId, sectionId, $scope.model.periods)
                .then(function() {
                  $scope.dismiss();
                  def.resolve();
                })
                .catch(function(err) {
                  $scope.dismiss();
                  def.reject(err);
                });
            };
          }
        ],
        size: 'md',
        resolve: {
          currentPeriods: function() {
            return _this.doc.periods;
          },
          eventId: function() {
            return _this.doc.eventId;
          },
          sectionId: function() {
            return _this.doc.eventSectionId;
          },
          form: function() {
            return new Form([
              {
                id: 'dueDate',
                type: 'date',
                label: 'Due date',
                required: true
              }
            ]);
          }
        }
      });

      return def.promise;
    };

    Goal.prototype.closeIfPossible = function() {
      // don't resubmit the section if it's already submitted
      if (this.isSetClosed()) {
        return;
      }

      var _this = this;
      return this.event.checkPermission('canCloseGoal')
        .then(function() {
          return _this.areAllFromThisGoalSetResolved();
        })
        .then(function(result) {
          if (result.areAllGoalsResolved) {
            Utils.swal({
              title: 'You have now marked all of the goals within this set. Would you like to ' +
              'close and submit them?',
              type: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Close'
            },
            function(isConfirm) {
              if (isConfirm) {
                _this.closeEventSection();
              }
            });
          }
        });
    };

    Goal.prototype.closeEventSection = function() {
      if (_.isUndefined(this.event.nextSection.def)) {
        this.event.loadSections();
      }

      var defer = $q.defer();
      var _this = this;
      if (this.event.nextSection.def) {
        $uibModal.open({
          animation: true,
          templateUrl: 'app/components/goals/partials/close-event-section.html',
          size: 'sm',
          controller: [
            '$scope',
            '$uibModalInstance',
            'event',
            'def',
            function($scope, $uibModalInstance, event, def) {
              $scope.event = event;
              $scope.def = def;

              $scope.postAction = function() {
                $uibModalInstance.dismiss('cancel');
                defer.resolve();
              };

              $scope.dismiss = function() {
                $uibModalInstance.dismiss('cancel');
                defer.reject();
              };
            }
          ],
          resolve: {
            event: function() {
              return _this.event;
            },
            def: function() {
              return _this.event.nextSection.def;
            }
          }
        });
      } else {
        this.event.closeGoal({})
          .then(function() {
            defer.resolve();
          })
          .catch(function() {
            defer.reject();
          });
      }

      return defer.promise;
    };

    Goal.prototype.save = function(options) {
      var opts = options || {};
      var _this = this;

      var func;

      if (!this.isMine() || opts.updateResolution) {
        func = Goals.saveViaApi(opts.dataToSave);
      } else {
        func = Goals.save(this.doc);
      }

      return func
        .then(function(data) {
          _this.doc._rev = data._rev;
        });
    };

    Goal.prototype.remove = function() {
      var _this = this;
      var def = $q.defer();

      Utils.swal({
        title: 'Are you sure you want to remove this goal?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          Goals.remove(_this.doc._id)
            .then(function() {
              def.resolve();
            })
            .catch(function() {
              def.reject();
            });
        } else {
          def.reject();
        }
      });

      return def.promise;
    };

    Goal.prototype.reloadProgress = function() {
      this.completedTargets = this.getCompletedTargets();
      this.totalLinkedEvents = this.getTotalLinkedEvents();
    };

    Goal.prototype.reloadState = function() {
      this.state = this.getState();
    };

    Goal.prototype.getBorderClass = function() {
      // hack: this is actually use to show the border for the event oppening wrapper

      var style;
      if (this.isSetClosed()) {
        style = 'complete';
      } else {
        style = 'pending';
      }

      return 'progress-border-' + style;
    };

    function hasCustomsSettingsPerm(perm, sections, sectionId, user) {
      return EventType.hasGoalCustomsSettingsRoles(
        perm,
        sections,
        sectionId,
        user
      );
    }

    // permissions
    function canViewOwn() {
      if (_.isUndefined(this.eventState) || !this.isSetClosed()) {
        return $q.reject({ status: 403 });
      }

      if (this.isMine()) {
        return this.hasPerm('work');
      }

      return $q.reject({ status: 403 });
    }

    function canWork() {
      if (_.isUndefined(this.eventState)) {
        return $q.reject({ status: 403 });
      }

      var _this = this;
      if (this.isSetClosed()) {
        if (this.isMine()) {
          return $q.reject({ status: 403 });
        }
        return this.hasPerm('manage');
      }
      return this.hasPerm('work')
        .catch(function(error) {
          if (error.status === 403 && !_this.isMine()) {
            return _this.hasPerm('manage');
          }

          return $q.reject(error);
        });
    }

    function canWorkAndUnlink() {
      var _this = this;
      return canWork.apply(this)
        .then(function() {
          return _this.isMine();
        });
    }

    function canWorkAndCreateEvent(eventTypeId, user, eventOwner) {
      return canWork.apply(this)
        .then(function() {
          var eventSection;
          if (_.isUndefined(eventTypeId)) {
            eventSection = new EventSection();
            return eventSection.hasPerm('events.create');
          }

          var eventSectionDoc = { eventType: eventTypeId, user: user, eventOwner: eventOwner };
          eventSection = new EventSection(eventSectionDoc);
          return eventSection.checkPermission('canCreate');
        });
    }

    function canMark() {
      if (this.goalSetSummary) {
        return $q.when(this.goalSetSummary.canMark);
      }

      var _this = this;
      return canWork.apply(this)
        .then(function() {
          return EventType.canMark(
            _this.event.eventType.sections,
            _this.doc.eventSectionId,
            _this.isMine()
          );
        })
        .then(function(has) {
          if (!has) {
            return $q.reject({ status: 403 });
          }
        })
        .catch(function(error) {
          if (error.status === 403 && !_this.isMine()) {
            return _this.hasPerm('manage');
          }

          return $q.reject(error);
        });
    }

    function canAdd() {
      if (_.isUndefined(this.event.eventType)) {
        return $q.reject({ status: 403 });
      }

      var _this = this;
      return this.hasPerm('manage')
        .catch(function() {
          return hasCustomsSettingsPerm(
            'canAddCustomsInProgress',
            _this.event.eventType.sections,
            _this.doc.eventSectionId,
            _this.doc.user
          )
            .then(function(has) {
              if (_this.isSetClosed() || !has) {
                return $q.reject({ status: 403 });
              } else if (_this.isPredefined()) {
                return $q.reject({ status: 403 });
              }
            });
        });
    }

    function canEdit() {
      if (_.isUndefined(this.event.eventType)) {
        return $q.reject({ status: 403 });
      }

      var _this = this;
      return this.hasPerm('manage')
        .catch(function() {
          return hasCustomsSettingsPerm(
            'canEditCustomsInProgress',
            _this.event.eventType.sections,
            _this.doc.eventSectionId,
            _this.doc.user
          )
            .then(function(has) {
              if (_this.isSetClosed() || !has) {
                return $q.reject({ status: 403 });
              } else if (_this.isPredefined()) {
                return $q.reject({ status: 403 });
              }
            });
        });
    }

    function canUpdateDueDate() {
      if (_.isEmpty(this.event) || this.isSetClosed()) {
        return false;
      }

      return this.hasPerm('manage');
    }

    function canRemove() {
      var _this = this;

      // in case the goal has lost its event, let the owner remove it without permission
      if (_.isUndefined(this.event.eventType) && _this.isMine()) {
        return $q.when(true);
      }

      return this.hasPerm('manage')
        .catch(function() {
          if (_.isUndefined(this.event.eventType)) {
            return $q.reject({ status: 403 });
          }

          return hasCustomsSettingsPerm(
            'canDeleteCustomsInProgress',
            _this.event.eventType.sections,
            _this.doc.eventSectionId,
            _this.doc.user
          )
            .then(function(has) {
              if (_this.isSetClosed() || !has) {
                return $q.reject({ status: 403 });
              } else if (_this.isPredefined()) {
                return $q.reject({ status: 403 });
              }
              return has;
            })
            .then(function(has) {
              if (!has && _this.isMine()) {
                return Events.find(_this.doc.eventId)
                  .then(function() {
                    return $q.when(true);
                  })
                  .catch(function(error) {
                    if (error.status !== 404) {
                      return $q.when(false);
                    }
                  })
                  .then(function() {
                    return _this.hasPerm('work');
                  });
              }
            });
        });
    }

    function canViewEvent() {
      return this.hasPerm('events.view');
    }

    Goal.prototype.checkPermission = function(perm, options) {
      var opts = options || {};
      if (perm === 'canViewAny') {
        return this.isMine() ? this.hasPerm('work') : this.hasPerm('view');
      } else if (perm === 'canView') {
        return !this.isMine() ? this.hasPerm('view') : $q.reject({ status: 403 });
      } else if (perm === 'canViewOwn') {
        return canViewOwn.apply(this);
      } else if (perm === 'canWork') {
        return canWork.apply(this);
      } else if (perm === 'canWorkAndUnlink') {
        return canWorkAndUnlink.apply(this);
      } else if (perm === 'canWorkAndCreateEvent') {
        return canWorkAndCreateEvent.apply(this, [opts.eventTypeId, opts.user, opts.eventOwner]);
      } else if (perm === 'canAdd') {
        return canAdd.apply(this);
      } else if (perm === 'canEdit') {
        return canEdit.apply(this);
      } else if (perm === 'canUpdateDueDate') {
        return canUpdateDueDate.apply(this);
      } else if (perm === 'canRemove') {
        return canRemove.apply(this);
      } else if (perm === 'canMark') {
        return canMark.apply(this);
      } else if (perm === 'canViewEvent') {
        return canViewEvent.apply(this);
      }

      return $q.reject({ status: 403 });
    };

    Goal.prototype.hasPerm = function(action) {
      var auth;
      var permission;

      if (action === 'view') {
        permission = 'goals.view';
      } else if (action === 'work') {
        permission = 'goals.work';
      } else if (action === 'manage') {
        permission = 'goals.manage';
      } else if (action === 'viewEvent') {
        permission = 'events.view';
      }

      if (!permission) {
        return $q.reject({ message: ' no permission found for that action: ' + action });
      }

      if (Auth.currentUser() === this.doc.user) {
        auth = Security.hasPermission(permission + '.own');
      } else {
        auth = Security.hasPermissionFor(permission, this.doc.user);
      }

      return auth;
    };

    // method that depends on permission
    Goal.prototype.getViewLink = function() {
      var _this = this;

      return canWork.apply(this)
        .then(function() { return true; })
        .catch(function() { return false; })
        .then(function(canWork) {
          var sref,
              srefOpts = { id: _this.doc._id };

          if (_this.isMine()) {
            sref = 'epf.goals.view';

            if (canWork) {
              sref = 'epf.goals.work';
            }
          } else {
            sref = 'epf.users.goals-view';
            srefOpts.user = _this.doc.user;

            if (canWork) {
              sref = 'epf.users.goals-work';
            }
          }

          return $state.href(sref, srefOpts);
        });
    };

    return Goal;
  }

  GoalFactory.$inject = [
    '$q',
    '$uibModal',
    '$state',
    '$log',
    'GoalsService',
    'AuthService',
    'EventFactory',
    'EventsService',
    'EventSectionFactory',
    'EventLightFactory',
    'EventTypeFactory',
    'FormsService',
    'GoalSearch',
    'ListFactory',
    'TargetFactory',
    'UtilsService',
    'SecurityService',
    'kzSessionStorage',
    'GOALS_STATES',
    'EVENT_STATES'
  ];

  angular.module('component.goals')
    .factory('GoalFactory', GoalFactory);
})();
