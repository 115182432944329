(function() {
  'use strict';

  function getColor(color) {
    if (typeof color !== 'string') {
      color = color.r + ', ' + color.g + ', ' + color.b;
    }
    return {
      border: 'rgba(' + color + ', 1)',
      bg: 'rgba(' + color + ', 0.4)'
    };
  }

  function SQLReportComponent(Auth, Events, Event, Notify, CHART_COLORS) {
    var ctrl = this;
    ctrl.$onInit = function() {
      ctrl.setup();
    };

    ctrl.$onChanges = function() {
      ctrl.showReport();
    };

    ctrl.setup = function() {
      console.log('Setting up');
    };

    ctrl.showCsvDownload = function(res) {
      return (res.options || {}).showCsvDownload;
    };

    ctrl.processChart = function(rep, outputs) {
      if (rep.type !== 'chart') {
        throw new Error('This is not a chart');
      }

      var horDef = rep.horizontal;
      var resultData = outputs[horDef.output];
      if (resultData === undefined) {
        console.log('Undefined output');
        return;
      }
      var labelIdx = _.findIndex(resultData.headers, function(itm) {
        return itm.id === horDef.column;
      });

      var labels = _.map(resultData.data, function(row) {
        return row[labelIdx];
      });

      var verDefs = rep.vertical;

      var shuffledColors = _.shuffle(CHART_COLORS);
      var multipleDatasets = verDefs.length > 1;

      var datasets = _.map(verDefs, function(verDef, idx) {
        var cnt = Math.max(labels.length, 1);
        var start = idx + labels.length;
        var end = start + cnt;
        var rangeOfColors = shuffledColors.slice(start, end);
        var colors;

        // If multipl datasets we want one color per set otherwise we rainbow
        if (multipleDatasets) {
          var colString = verDef.color || 'random';
          if (colString === 'random') {
            colString = rangeOfColors[0];
          }
          var col = getColor(colString);
          colors = {
            border: col.border,
            bg: col.bg
          };
        } else {
          colors = { border: [], bg: [] };
          _.forEach(rangeOfColors, function(color) {
            var args = color.r + ', ' + color.g + ', ' + color.b;
            colors.border.push('rgba(' + args + ', 1)');
            colors.bg.push('rgba(' + args + ', 0.4)');
          });
        }

        var verOutput = outputs[verDef.output];
        var labelIdx = _.findIndex(verOutput.headers, function(itm) {
          return itm.id === verDef.column;
        });
        var verData = _.map(verOutput.data, function(row) {
          return row[labelIdx];
        });
        return {
          label: verDef.label || '',
          data: verData,
          borderWidth: 1,
          borderColor: colors.border,
          backgroundColor: colors.bg
        };
      });


      var chartType = rep.chartType || 'bar';
      var data = { labels: labels, datasets: datasets };
      var options = {
        title: {
          display: true,
          text: rep.title || ''
        },
        tooltips: {
          enabled: true,
          mode: 'nearest',
          callbacks: {
            title: function(tooltipItems, data) {
              var idx = tooltipItems[0].index;
              return data.labels[idx];
            }
          }
        }
      };

      if (_.indexOf(['polar', 'radar'], chartType) !== -1) {
        options.scale = {
          ticks: {
            beginAtZero: true
          }
        };
      } else {
        options.legend = { display: multipleDatasets };
        options.scales = {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: horDef.label || ''
            },
            ticks: {
              stepSize: 1,
              min: 0,
              autoSkip: false,
              callback: function(value) {
                if (_.isUndefined(value)) {
                  return '';
                }

                if (value.length > 40) {
                  var truncated = value.substr(0, 40);
                  return truncated + '...';
                }

                return value;
              }
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        };
      }
      return {
        type: 'chart',
        chart: {
          type: chartType,
          description: rep.description || '',
          data: data,
          options: options
        }
      };
    };

    ctrl.processSingle = function(rep, outputs) {
      if (rep.type === 'text') {
        return rep;
      } else if (rep.type === 'table') {
        return rep;
      } else if (rep.type === 'chart') {
        return ctrl.processChart(rep, outputs);
      }
    };

    ctrl.processData = function(data) {
      ctrl.outputs = {};
      _.forEach(data.result, function(item) {
        ctrl.outputs[item.name] = item;
      });

      var display = ctrl.report.getDisplay();
      ctrl.display = _.filter(_.map(display, function(rep) {
        return ctrl.processSingle(rep, ctrl.outputs);
      }), Boolean);
    };

    ctrl.showReport = function() {
      if (ctrl.storedResult) {
        ctrl.processData(ctrl.storedResult);
      } else {
        ctrl.run();
      }
    };

    ctrl.run = function() {
      ctrl.display = undefined;
      var options = {
        default: {
          size: 50,
          start: 0
        }
      };
      return ctrl.report.run(ctrl.model, (ctrl.extra || {}).user, options)
        .then(function(data) {
          ctrl.processData(data);
          // ctrl.reportData = data;
          if (ctrl.resultLoaded) {
            if (ctrl.report.elementId) {
              ctrl.resultLoaded(
                { result: { report: ctrl.report.elementId, data: data } }
              );
            } else {
              ctrl.resultLoaded({ result: data });
            }
          }
        })
        .catch(function(err) {
          if (err.message) {
            Notify.error(err.message);
          } else {
            Notify.error(err);
          }
        });
    };

    ctrl.loadPage = function(name, start, size) {
      var options = {
        limit_to: [name],
        output_options: {}
      };
      options.output_options[name] = {
        size: size,
        start: start
      };
      ctrl.report.run(ctrl.model, (ctrl.extra || {}).user, options)
        .then(function(data) {
          if (data.result.length === 1 && data.result[0].name === name) {
            ctrl.outputs[name] = data.result[0];
          }
        });
    };

    ctrl.showPreview = function(col) {
      Events.findFor(col.value)
        .then(function(data) {
          var isLocal = data.doc.user === Auth.currentUser();
          var event = new Event(data.doc, { isLocal: isLocal });
          return event.init(data.doc);
        })
        .then(function(event) {
          event.openEventPreview();
        });
    };
  }

  SQLReportComponent.$inject = [
    'AuthService',
    'EventsService',
    'EventFactory',
    'NotifyService',
    'CHART_COLORS'
  ];
  angular.module('component.reports')
    .component('kzSqlReportView', {
      templateUrl: 'app/components/reports/directives/sql-report-view.html',
      controller: SQLReportComponent,
      bindings: {
        model: '<',
        report: '<',
        generated: '<',
        extra: '<',
        storedResult: '<',
        resultLoaded: '&'
      }
    });
})();
