(function() {
  'use strict';

  function VersionActionsController(
    $q,
    $scope,
    $rootScope,
    $state,
    $uibModal,
    $log,
    Auth,
    Notify,
    Utils,
    Security,
    VERSION_STATES
  ) {
    var ctrl = this;

    function load() {
      if (!$scope.version || $scope.version.doc._rev === undefined) {
        return $q.when();
      }

      // todo: how to move that from this?
      var perm = 'reports.edit';

      // if ($scope.version.doc.location === 'personal') {
      //   perm = 'reports.edit.own';
      // }

      var editPerms = {
        eventType: 'eventTypes.edit',
        report: perm,
        reportTemplate: perm
      };

      var editPerm = editPerms[$scope.version.doc.type];

      function buildLinks() {
        var actionLinks = [];
        if (
          !_.isUndefined($scope.version.linkedVersions) && $scope.version.linkedVersions.length > 1
        ) {
          actionLinks.push({
            title: 'Show all versions',
            style: 'info',
            action: 'showAllVersions',
            icon: 'history'
          });
        }

        if ($scope.version.hasState(VERSION_STATES.published) && (!$scope.version.isSystem())) {
          actionLinks.push({
            title: 'Archive',
            style: 'danger',
            action: 'archive',
            icon: 'archive'
          });
        }

        if (!$scope.version.isSystem()) {
          actionLinks.push({
            title: 'Duplicate',
            icon: 'duplicate',
            style: 'info',
            action: 'cloneVersion'
          });
        }

        if ($scope.version.doc.nextVersion) {
          // actionLinks.push({
          //   title: 'Go to most recent version',
          //   style: 'info',
          //   action: 'goLastVersion',
          //   icon: 'up-open-big'
          // });
        } else if (!$scope.version.hasState(VERSION_STATES.draft)) {
          if (!$scope.version.isSystem()) {
            actionLinks.push({
              title: 'Create a new version',
              style: 'success',
              action: 'createNewVersion',
              icon: 'plus'
            });
          }
        } else {
          actionLinks.push({
            title: 'Publish',
            style: 'success',
            action: 'publish',
            disabled: function() {
              if ($scope.form === undefined) {
                return false;
              }

              return $scope.form.$invalid;
            },
            icon: 'check'
          });

          actionLinks.push({
            title: 'Delete',
            style: 'danger',
            action: 'delete',
            icon: 'trash'
          });
        }

        return actionLinks;
      }

      return Security.hasPermission(editPerm)
        .then(function(hasPermResult) {
          if (hasPermResult) {
            return buildLinks();
          }

          return [];
        })
        .then(function(actionLinks) {
          ctrl.actionLinks = actionLinks;
        });
    }

    $scope.$watchCollection('version.linkedVersions', function() {
      load();
    });

    $scope.$watchCollection('version.doc.state', function() {
      load();
    });

    ctrl.doAction = function(action) {
      var baseRoute = $state.current.name.split('.').splice(0, 2).join('.'); // hack?

      function validate(action) {
        if (action === 'publish') {
          var def = $q.defer();
          ctrl.version.validate()
            .then(function(errors) {
              if (_.isUndefined(errors) || errors.length === 0) {
                def.resolve();
                return;
              }

              console.log(errors);
              var msg = 'Please review following errors:';
              _.forEach(errors, function(error) {
                msg += '\n' + error;
              });
              def.reject({ status: 500, message: msg });
            });
          return def.promise;
        }

        return $q.when();
      }

      function preAction(action) {
        ctrl.loading = true;
        if (['publish', 'archive', 'delete'].indexOf(action) > -1) {
          var deffered = $q.defer();

          var text = '',
              title = 'Are you sure you wish to ' + action + ' this ' + $scope.version.doc.state +
              ' version?';
          if (action === 'publish') {
            var previousVersion = ctrl.version.getPreviousVersion(),
                previousState;
            if (previousVersion) {
              previousState = previousVersion.state;
            }

            if (previousState === 'published') {
              text = 'This version has a previously published version. ' +
                'Publishing this new version will archive the previous version';
            } else {
              text = 'Are you sure you wish to publish this draft version? ' +
                'This will publish this version so that it is available to users. ' +
                'Would you like to continue?';
            }
          }

          Utils.swal({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK'
          }, function(isConfirm) {
            if (isConfirm) {
              deffered.resolve();
            } else {
              deffered.reject({ status: 410 });
            }
          });

          return deffered.promise;
        }

        return $q.when();
      }

      function act() {
        var editTo = baseRoute + '.edit';
        var newTo = baseRoute + '.new';
        var actor = Auth.currentUser();
        switch (action) {
          case 'showAllVersions':
            $uibModal.open({
              animation: true,
              templateUrl: './versions-list.html',
              controller: ['$scope', '$uibModalInstance', 'version', 'versionStates', function(
                $scope,
                $uibModalInstance,
                version, versionStates
              ) {
                $scope.version = version;
                $scope.versionStates = versionStates;

                $scope.versions = _(version.linkedVersions).slice().reverse().value();
                $scope.goTo = function(to, id) {
                  $scope.dismiss();

                  var baseRoute = $state.current.name.split('.').splice(0, 2).join('.'); // hack?
                  $state.go(baseRoute + '.' + to, { id: id });
                };

                $scope.dismiss = function() {
                  $uibModalInstance.dismiss('cancel');
                };
              }],
              size: 'lg',
              resolve: {
                version: function() {
                  return ctrl.version;
                },
                versionStates: function() {
                  return VERSION_STATES;
                }
              }
            });
            break;
          case 'goLastVersion':
            var lastVersion = ctrl.version.getHeadVersion();

            $state.go(editTo, { id: lastVersion._id });
            break;
          case 'createNewVersion':
            return ctrl.version.createNewVersion()
              .then(function(newVersion) {
                $state.go(editTo, { id: newVersion.id });
              });
          case 'cloneVersion':
            return ctrl.version.cloneVersion(actor)
              .then(function(newVersion) {
                $state.go(newTo, { id: newVersion._id, doc: newVersion });
              });
          case 'publish':
            return ctrl.version.publish(actor);
          case 'archive':
            return ctrl.version.archive(actor);
          case 'delete':
            return ctrl.version.delete(actor);

          default:
            $log.warn(action + ' is not allowed!');
            break;
        }
      }

      function postAction(type, action) {
        var pastAction = {
          publish: 'published',
          archive: 'archived',
          delete: 'deleted'
        };

        var to = baseRoute + '.index';
        $rootScope.$broadcast('KZApplySearch');
        $state.go(to)
          .then(function() {
            var message = 'This ' + Utils.separateByUppercase(type).toLowerCase() + ' has been ' +
            '' + pastAction[action] + '!';
            Notify.success(message, 'Successfully ' + pastAction[action] + '!');
          });
      }

      var prom = $q.when($scope.version);

      // Preserve doc if we have it
      var newDoc;
      if ($scope.version.type === 'full') {
        newDoc = $scope.version.doc;
      }

      if ($scope.version.type !== 'versioned') {
        prom = $scope.version.getFull();
      }

      prom
        .then(function(version) {
          ctrl.version = version;
          if (newDoc) {
            ctrl.version.doc = newDoc;
          }
        })
        .then(function() {
          return validate(action);
        })
        .then(function() {
          return preAction(action);
        })
        .then(act)
        .then(function() {
          ctrl.loading = false;

          // todo: should be in postAction
          if (['publish', 'archive', 'delete'].indexOf(action) > -1) {
            postAction(ctrl.version.doc.type, action);
            $scope.postAction();
          }
        })
        .catch(function(error) {
          console.log(error);
          ctrl.loading = false;
          if (error.status === 403) {
            Utils.showError({ message: 'Form not valid: ' + error.reason });
          } else if (error.status === 410) {
            // Cancel was pressed, not doing anything
            console.log('Action cancelled');
          } else {
            Utils.showError(error);
          }
        });
    };
  }

  // Actions
  function VersionActionsDirective() {
    return {
      scope: {
        version: '=',
        form: '=?',
        postAction: '&'
      },
      restrict: 'E',
      templateUrl: 'app/blocks/versions/actions.html',
      replace: true,
      controller: VersionActionsController,
      controllerAs: 'ctrl'
    };
  }

  VersionActionsController.$inject = [
    '$q',
    '$scope',
    '$rootScope',
    '$state',
    '$uibModal',
    '$log',
    'AuthService',
    'NotifyService',
    'UtilsService',
    'SecurityService',
    'VERSION_STATES'
  ];

  // Widget
  function VersionWidgetDirective() {
    return {
      scope: {
        version: '=',
        form: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/blocks/versions/widget.html',
      replace: true
    };
  }

  function VersionStateController(VERSION_STATES) {
    var ctrl = this;
    ctrl.versionStates = VERSION_STATES;
  }

  // State
  function VersionStateDirective() {
    return {
      scope: {
        version: '='
      },
      restrict: 'E',
      template: '<span class="label" style="padding-left: 3px;" ' +
      'data-ng-class="{\'label-success\': version.state === ctrl.versionStates.published, ' +
      '\'label-warning\': version.state === ctrl.versionStates.draft, ' +
      '\'label-danger\': version.state === ctrl.versionStates.archived}" ' +
      'data-ng-bind="version.state"></span>',
      replace: true,
      controller: VersionStateController,
      controllerAs: 'ctrl'
    };
  }

  VersionStateController.$inject = ['VERSION_STATES'];

  function VersionLastChangeController(scope, VERSION_STATES,
    $filter, LocalizationService) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('datetime');

    ctrl.dates = _.chain(scope.dates)
      .filter(function(date) {
        return (scope.state !== VERSION_STATES.draft && date.action !== 'created') ||
          (scope.state === VERSION_STATES.draft);
      })
      .map(function(date) {
        return {
          action: date.action,
          actor: date.actor,
          date: $filter('date')(date.date, ctrl.dateFormat)
        };
      })
      .value();
  }

  // Last Change
  function VersionLastChangeDirective() {
    return {
      scope: {
        state: '=versionState',
        dates: '=versionDates'
      },
      restrict: 'E',
      templateUrl: 'app/blocks/versions/last-change.html',
      replace: true,
      controller: VersionLastChangeController,
      controllerAs: 'versionlastChangeCtrl'
    };
  }

  VersionLastChangeController.$inject = ['$scope', 'VERSION_STATES',
    '$filter', 'LocalizationService'];

  // Last Change
  function VersionTitleDirective(Version) {
    return {
      scope: {
        versionGroupIds: '='
      },
      restrict: 'EA',
      template: '<span data-ng-repeat="version in versions">' +
      '{{version.doc.name}}<span ng-if="!$last">, </span></span>',
      replace: true,
      link: function(scope) {
        scope.$watchCollection('versionGroupIds',
          function(versionGroupIds) {
            var options = { versionGroupIds: versionGroupIds };
            Version.findLatestAvailableVersions(options)
              .then(function(versions) {
                scope.versions = versions;
              })
              .catch(function(error) {
                console.log(error);
              });
          });
      }
    };
  }

  VersionTitleDirective.$inject = ['VersionFactory'];

  angular.module('blocks.versions')
    .directive('versionActions', VersionActionsDirective)
    .controller('VersionActionsController', VersionActionsController);

  angular.module('blocks.versions')
    .directive('versionWidget', VersionWidgetDirective);

  angular.module('blocks.versions')
    .directive('versionState', VersionStateDirective)
    .controller('VersionStateController', VersionStateController);

  angular.module('blocks.versions')
    .directive('versionLastChange', VersionLastChangeDirective);

  angular.module('blocks.versions')
    .directive('versionTitle', VersionTitleDirective);
})();
