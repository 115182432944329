(function() {
  'use strict';

  function RevisionsToggle() {
    return {
      scope: {
        info: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/drafts/revisions-toggle.html',
      replace: true,
      link: function($scope) {
        $scope.toggleRevisions = function() {
          $scope.info.showRevisions = !$scope.info.showRevisions;
        };

        $scope.$watch('info.revisionCount', function(value) {
          $scope.showToggle = value > 0;
        });
      }
    };
  }

  RevisionsToggle.$inject = [];

  angular.module('component.drafts')
    .directive('kzAutoSaveRevisionsToggle', RevisionsToggle);
})();
