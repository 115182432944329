(function() {
  'use strict';

  function KaizenHQController() {
  }

  function config(dashboardProvider) {
    dashboardProvider.widget('kaizenhq', {
      title: 'KaizenHQ',
      description: 'See Kaizen releases on the KaizenHQ website',
      controller: 'KaizenHQController',
      controllerAs: 'kaizenHQCtrl',
      templateUrl: 'app/core/widgets/kaizenhq/view.html',
      edit: {},
      category: 'General'
    });
  }

  config.$inject = ['dashboardProvider'];

  angular.module('epf.core')
    .config(config)
    .controller('KaizenHQController', KaizenHQController);
})();
